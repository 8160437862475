import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  ComparisonReportConfiguration,
  ComparisonReportFactorToWidgetId,
  ComparisonReportObjects,
  ReportRuleConfiguration,
  ReportRuleContentWidgetId,
  ReportRuleCreationType,
  ReportRuleScope,
  SecurityReportConfiguration,
  SecurityReportSelectedWidget,
} from 'shared/models/data/report-rule.model';
import { useSearchParams } from 'react-router-dom';
import ReportOpenFindingsWidget from './components/ReportOpenFindingsWidget';
import DashboardReportsRisk from '../DashboardReportsRisk';
import { AutocompleteOption } from 'FindingDetails/store/api';
import _ from 'lodash';
import { MinimalOrganizationNode } from 'shared/models/data/organization-node.model';
import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { UniqueBusinessUnitId } from 'Dashboard/components/BusinessUnitDashboardSection/BusinessUnitDashboardSection';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { CircularProgress } from '@mui/material';

interface DashboardReportsWidgetsProps extends BaseComponentProps {}

export const DashboardReportsWidgets: FunctionComponent<
  DashboardReportsWidgetsProps
> = () => {
  const [searchParams] = useSearchParams();

  const [preparingScopeData, setPreparingScopeData] = useState<boolean>(true);

  const [
    getUserAvailableNodesOfType,
    { data: nodeData, isLoading: nodeDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  const reportScope = useMemo<ReportRuleScope>(() => {
    if (searchParams.get('reportScope')) {
      try {
        return JSON.parse(searchParams.get('reportScope') as string);
      } catch (err) {
        return [];
      }
    }

    return [];
  }, [searchParams]);

  const reportCreationType = useMemo<ReportRuleCreationType>(() => {
    return searchParams.get('type') as ReportRuleCreationType;
  }, [searchParams.get('type')]);

  const selectedBusinessUnitIds = useMemo<Array<string>>(() => {
    return reportScope.serviceIds || [];
  }, [reportScope]);

  const selectedFindingTypes = useMemo<Array<string>>(() => {
    return reportScope.domains || [];
  }, [searchParams]);

  const scopeIds = useMemo<Array<string>>(() => {
    return reportScope.scopeIds || [];
  }, [searchParams]);

  const groupIds = useMemo<Array<string>>(() => {
    return reportScope.groupIds || [];
  }, [searchParams]);

  const isAllGroupSelected =
    groupIds.length === 0 || groupIds[0] === DashboardFilterType.ALL;
  const isAllScopeSelected =
    scopeIds.length === 0 || scopeIds[0] === DashboardFilterType.ALL;

  const businessUnitIds = useMemo<Array<string>>(() => {
    if (
      selectedBusinessUnitIds.length > 0 &&
      !selectedBusinessUnitIds.includes(DashboardFilterType.ALL)
    ) {
      return selectedBusinessUnitIds;
    }

    if (nodeData) {
      const nodeIds =
        nodeData?.map((node: MinimalOrganizationNode) => node?.id) || [];

      if (isAllGroupSelected && isAllScopeSelected)
        return [...nodeIds, UniqueBusinessUnitId.NO_BUSINESS_UNIT];

      return nodeIds;
    }
    return [];
  }, [
    nodeData,
    isAllGroupSelected,
    isAllScopeSelected,
    selectedBusinessUnitIds,
  ]);

  useEffect(() => {
    if (reportCreationType === ReportRuleCreationType.SECURITY) {
      if (!isAllGroupSelected) {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
          startNodeId: groupIds[0],
        });
      } else if (!isAllScopeSelected) {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
          startNodeId: scopeIds[0],
        });
      } else {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
        });
      }
    }
    setPreparingScopeData(false);
  }, [
    scopeIds,
    groupIds,
    isAllGroupSelected,
    isAllScopeSelected,
    reportCreationType,
  ]);

  const scopeFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.scopeIds?.length) {
      return _.compact(
        reportScope?.scopeIds?.map((scopeId: string) => ({ value: scopeId }))
      );
    }

    return [];
  }, [reportScope.scopeIds]);

  const groupFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.groupIds?.length) {
      return _.compact(
        reportScope?.groupIds?.map((groupId: string) => ({ value: groupId }))
      );
    }

    return [];
  }, [reportScope.groupIds]);

  const serviceFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.serviceIds?.length) {
      return _.compact(
        reportScope?.serviceIds?.map((serviceId: string) => ({
          value: serviceId,
        }))
      );
    }

    return [];
  }, [reportScope.serviceIds]);

  const domainFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.domains?.length) {
      return _.compact(
        reportScope?.domains?.map((domain: string) => ({ value: domain }))
      );
    }

    return [];
  }, [reportScope.domains]);

  const filters = useMemo<Array<any>>(() => {
    return [
      {
        type: 'Scope',
        values: scopeFilter,
      },
      {
        type: 'Group',
        values: groupFilter,
      },
      {
        type: 'Service',
        values: serviceFilter,
      },
      {
        type: 'Domain',
        values: domainFilter,
      },
    ];
  }, [scopeFilter, groupFilter, serviceFilter, domainFilter]);

  const configuration = useMemo<ReportRuleConfiguration | null>(() => {
    try {
      const configuration = JSON.parse(
        searchParams.get('configuration') || ''
      ) as ReportRuleConfiguration;

      return configuration;
    } catch (err) {
      return null;
    }
  }, [searchParams.get('configuration')]);

  const comparisonWidgets = useMemo<Array<ReportRuleContentWidgetId>>(() => {
    if (reportCreationType === ReportRuleCreationType.COMPARATIVE) {
      const comparisonFactors = (configuration as ComparisonReportConfiguration)
        .comparisonFactors;

      return comparisonFactors.map(
        (comparisonFactor) => ComparisonReportFactorToWidgetId[comparisonFactor]
      );
    }

    return [];
  }, [configuration, reportCreationType]);

  const securityWidgets = useMemo<Array<SecurityReportSelectedWidget>>(() => {
    if (reportCreationType === ReportRuleCreationType.SECURITY) {
      return (configuration as SecurityReportConfiguration).selectedWidgets;
    }

    return [];
  }, [configuration, reportCreationType]);

  const comparisonType = useMemo<'group' | 'service' | 'scope'>(() => {
    if (reportCreationType === ReportRuleCreationType.COMPARATIVE) {
      const comparisonConfiguration =
        configuration as ComparisonReportConfiguration;

      switch (comparisonConfiguration.comparisonObject) {
        case ComparisonReportObjects.GROUPS:
        case ComparisonReportObjects.SUB_GROUPS:
          return 'group';
        case ComparisonReportObjects.SCOPES:
          return 'scope';
        case ComparisonReportObjects.SERVICES:
          return 'service';
      }
    }

    return 'group';
  }, [configuration, reportCreationType]);

  const renderOperationalContent = () => {
    return (
      <DashboardReportsRisk
        widgets={securityWidgets}
        searchParams={filters}
        businessUnitIds={businessUnitIds}
        findingTypes={selectedFindingTypes}
      />
    );
  };

  const renderOpenFindingsWidgetComponentsByIds = () => {
    if (comparisonWidgets.length) {
      return comparisonWidgets.map((widgetId: ReportRuleContentWidgetId) => {
        return (
          <ReportOpenFindingsWidget
            type={comparisonType}
            searchParams={filters}
            widgetId={widgetId}
          />
        );
      });
    }

    return <></>;
  };

  const renderWidgetComponentsByContentGroupType = () => {
    switch (reportCreationType) {
      case ReportRuleCreationType.COMPARATIVE:
        return renderOpenFindingsWidgetComponentsByIds();
      case ReportRuleCreationType.SECURITY:
        return renderOperationalContent();
      default:
        return <></>;
    }
  };

  return (
    <div className="dashboard-reports-widgets">
      {nodeDataLoading || preparingScopeData ? (
        <div className="dashboard-reports-widgets-loading">
          <CircularProgress size={40} />
        </div>
      ) : (
        renderWidgetComponentsByContentGroupType()
      )}
    </div>
  );
};
