import numeral from 'numeral';

export class NumberFormatHandler {
  formatNumberToString(number: number) {
    if (!number) return 0;
    if (number >= 999) {
      return numeral(number).format('0.0a').toUpperCase();
    } else {
      return number.toFixed
        ? number % 1 === 0
          ? number
          : number.toFixed(2)
        : number;
    }
  }
}
