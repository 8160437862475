import { Button, Tooltip } from '@mui/material';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonCheckbox from 'shared/components/CommonCheckbox';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OperationalMetricType } from 'shared/models/data/operational-widget-data.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface OperationalMetricsModalProps extends BaseComponentProps {
  modalOpen: boolean;
  handleModalClose: () => void;
  saveSelectedMetrics: (metrics: Array<OperationalMetricType>) => void;
  selectedMetrics: Array<OperationalMetricType>;
}

interface OperationalMetricCategory {
  type: OperationalMetricType;
  label: string;
  tooltipLabel: string;
}

type OperationalMetricCategories = Record<
  string,
  Array<OperationalMetricCategory>
>;

const operationalMetricsLimit: number = 16;

const operationalMetricCategories: OperationalMetricCategories = {
  'New / Trending Metrics': [
    {
      type: OperationalMetricType.NEW_FINDINGS,
      label: 'New Findings',
      tooltipLabel:
        'Number of new findings that were added during the specified period',
    },
    {
      type: OperationalMetricType.NEW_RESOLVED_FINDINGS,
      label: 'New Resolved Findings',
      tooltipLabel:
        'Number of findings that were resolved during the specified period',
    },
    {
      type: OperationalMetricType.NEW_RESOLVED_WITHIN_SLA,
      label: 'New Resolved Within SLA',
      tooltipLabel:
        'Number of findings that were resolved within their SLA during the specified period',
    },
    {
      type: OperationalMetricType.NEW_RESOLVED_OUTSIDE_SLA,
      label: 'New Resolved Outside SLA',
      tooltipLabel:
        'Number of findings that were resolved outside their SLA during the specified period',
    },
    {
      type: OperationalMetricType.NEW_EXCEPTION_APPROVED,
      label: 'New Exception Approved',
      tooltipLabel:
        'Number of new exceptions that were approved during the specified period',
    },
    {
      type: OperationalMetricType.SLA_ATTAINMENT_RATE,
      label: 'SLA Attainment Rate',
      tooltipLabel: 'Percentage of active findings that are within SLA',
    },
    {
      type: OperationalMetricType.TRENDING_MTTR,
      label: 'Trending MTTR',
      tooltipLabel: 'The MTTR change within the specified period',
    },
    {
      type: OperationalMetricType.NEW_FINDINGS_BREACHED_SLA,
      label: 'New Findings Breached SLA ',
      tooltipLabel:
        'New findings that breached SLA during the specified period',
    },
    {
      type: OperationalMetricType.PRIORITIZED_CHURN,
      label: 'Prioritized Churn ',
      tooltipLabel: `Compares resolved Prioritized (Risk Score >9) findings to new ones. A ratio above 1.0 indicates you're resolving high-risk vulnerabilities faster than discovering them.`,
    },
    {
      type: OperationalMetricType.PRIORITIZED_RATIO,
      label: 'Prioritized Ratio',
      tooltipLabel: `Shows what percentage of new findings are classified as Prioritized (Risk Score >9). Helps track the proportion of prioritized vulnerabilities among all discoveries.`,
    },
  ],

  'Total Metrics': [
    {
      type: OperationalMetricType.TOTAL_ACTIVE_FINDINGS,
      label: 'Total Active Findings',
      tooltipLabel: 'Number of total active findings',
    },
    {
      type: OperationalMetricType.TOTAL_CRITICAL_FINDINGS,
      label: 'Total Critical Score Findings',
      tooltipLabel:
        'Number of total active findings with critical risk score (9-10)',
    },
    {
      type: OperationalMetricType.TOTAL_EXPLOITABLE_FINDINGS,
      label: 'Total Exploitable Findings',
      tooltipLabel:
        'Number of total active findings with “Has Exploit” attribute by Opus intelligence',
    },
    {
      type: OperationalMetricType.TOTAL_ACTIVE_WITHIN_SLA,
      label: 'Total Active Findings Within SLA',
      tooltipLabel: 'Number of total active findings within SLA',
    },
    {
      type: OperationalMetricType.TOTAL_ACTIVE_OUTSIDE_SLA,
      label: 'Total Active Findings Outside SLA',
      tooltipLabel: 'Number of total active findings outside SLA',
    },
    {
      type: OperationalMetricType.TOTAL_MTTR,
      label: 'Total MTTR',
      tooltipLabel: 'The total MTTR',
    },
  ],
};

export const OperationalMetricsModal: FunctionComponent<
  OperationalMetricsModalProps
> = ({ modalOpen, handleModalClose, saveSelectedMetrics, selectedMetrics }) => {
  const { t: translation } = useTranslation();

  const [currentSelectedMetrics, setCurrentSelectedMetrics] = useState<
    Array<OperationalMetricType>
  >([]);

  useEffect(() => {
    setCurrentSelectedMetrics(selectedMetrics);
  }, [selectedMetrics]);

  const renderCategories = () => {
    return Object.keys(operationalMetricCategories).map((categoryTitle) => {
      const categoryItems = operationalMetricCategories[categoryTitle];

      return (
        <div className="operational-metrics-modal-category">
          <div className="operational-metrics-modal-category-title">{`${categoryTitle} (${categoryItems.length})`}</div>
          <div className="operational-metrics-modal-category-items">
            {categoryItems.map((categoryItem: OperationalMetricCategory) => {
              const isItemSelected: boolean = currentSelectedMetrics.includes(
                categoryItem.type
              );
              return (
                <div
                  className={`operational-metrics-modal-category-item ${
                    isItemSelected
                      ? 'operational-metrics-modal-category-item-selected'
                      : ''
                  }`}
                  onClick={() => {
                    setCurrentSelectedMetrics((previousSelectedMetrics) => {
                      if (previousSelectedMetrics.includes(categoryItem.type)) {
                        return previousSelectedMetrics.filter(
                          (selectedMetric) =>
                            selectedMetric !== categoryItem.type
                        );
                      } else {
                        if (
                          previousSelectedMetrics.length <
                          operationalMetricsLimit
                        ) {
                          return [
                            ...previousSelectedMetrics,
                            categoryItem.type,
                          ];
                        } else {
                          return previousSelectedMetrics;
                        }
                      }
                    });
                  }}
                >
                  <CommonCheckbox
                    checked={isItemSelected}
                    disabled={
                      currentSelectedMetrics.length >= operationalMetricsLimit
                    }
                  />
                  <span>{categoryItem.label}</span>{' '}
                  <CommonTooltip
                    title={
                      <span className="campaign-live-tooltip-text">
                        {categoryItem.tooltipLabel}
                      </span>
                    }
                    placement="top"
                  >
                    <span>
                      <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                    </span>
                  </CommonTooltip>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <CommonModalContainer
      isOpen={modalOpen}
      handleClose={handleModalClose}
      title="Customize Metrics"
      components={{
        CancelButton: (
          <Button
            onClick={handleModalClose}
            className="operational-metrics-cancel-button"
          >
            Cancel
          </Button>
        ),
        SubmitButton: (
          <Button className="operational-metrics-save-button">Save</Button>
        ),
        TitleIcon: (
          <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />
        ),
      }}
      rootClassName="operational-metrics-modal-container"
    >
      <div className="operational-metrics-modal-body">
        <div className="operational-metrics-modal-description">
          {translation(`dashboards.widgets.addOrRemoveMetricsDescription`)}
        </div>
        <div className="operational-metrics-modal-categories">
          {renderCategories()}
        </div>

        <div className="operational-metrics-modal-buttons">
          <Button onClick={handleModalClose} className="action-close">
            Cancel
          </Button>
          <Button
            className="action-save opus-primary-button"
            onClick={() => {
              saveSelectedMetrics(currentSelectedMetrics);
              handleModalClose();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </CommonModalContainer>
  );
};
