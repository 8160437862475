import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDashboardPostureAnalyticsMutation } from 'Dashboard/store/api';
import useCommonSelector from 'Common/utils/use-selector';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
} from 'Dashboard/store';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { insightsData } from 'Dashboard/interfaces/InsightTypes.enum';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GridType } from 'Risk/store';
import { NavigationHandler } from 'shared/handlers/navigation.handler';

export interface WidgetBlockProps {
  type: PostureWidgetAnalyticsType;
}
const navigationHandler = new NavigationHandler();

export const WidgetBlock: FunctionComponent<WidgetBlockProps> = ({ type }) => {
  const { t: translation } = useTranslation();

  const dashboardFilterInitialLoad = useCommonSelector(getInitialFilterLoad);
  const filterState = useCommonSelector(getFilterState);
  const filter = useCommonSelector(selectdashboardFilter);

  const findingTypes = useMemo(
    () => getFindingTypes(filterState),
    [filterState.findingType]
  );

  const [getWidgetData, { isLoading, data: widgetDataSet }] =
    useGetDashboardPostureAnalyticsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      getWidgetData({
        businessUnitIds: filter.businessUnitId,
        findingTypes,
        type,
      });
    }
  }, [dashboardFilterInitialLoad, filter.businessUnitId, findingTypes]);

  const insightData = useMemo(
    () => insightsData.find((item) => item.type === type),
    [type]
  );

  const criticality = insightData?.criticality?.toLowerCase() || 'unknown';
  const title = insightData?.title || '';
  const description = insightData?.description || '';
  const findingsCount = isLoading ? '' : widgetDataSet?.value || 0;

  if (!isLoading && findingsCount === 0) return <></>;
  return (
    <div
      className="insight-block"
      onClick={() => {
        navigationHandler.handleRiskNavigation(
          GridType.None,
          widgetDataSet.filter,
          true
        );
      }}
    >
      <div className={`icon ${criticality}`}>
        <OpusSvgIcon type={SVG_ICON_TYPES.LIGHTBULB_ICON} />
      </div>
      <div className="insight-details">
        <div className="title">
          <p className="label-5">{title}</p>
          <div className="finding-count">
            {isLoading ? (
              'Loading...'
            ) : (
              <>
                {findingsCount} {translation('common.findings', 'Findings')}
              </>
            )}
          </div>
          <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_RIGHT_ICON} />
        </div>
        <div className="description">
          <p className="body-1">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default WidgetBlock;
