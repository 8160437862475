import { MenuConfigType } from './types';
import { AdminPortal } from '@frontegg/react';
import commonConfig from '../../../Config';
import authConstants from '../../../Auth/constants';
import { UserDetailsService } from 'shared/services/users/user-details.service';
import { ReactComponent as Dashboard } from '../../../shared/icons/chart-simple-icon.svg';
import { ReactComponent as Risk } from '../../../shared/icons/triangle-exclamation.svg';
import { ReactComponent as Assets } from '../../../shared/icons/shapes.svg';
import { ReactComponent as CampaignsIcon } from '../../../shared/icons/clipboard-list.svg';
import { ReactComponent as SettingsWheelsIcon } from '../../../shared/icons/wand-sparkles.svg';
import { ReactComponent as SettingsIcon } from '../../../shared/icons/cog.svg';
import { ReactComponent as LifeRingIcon } from '../../../shared/icons/life-ring.svg';
import { ReactComponent as PuzzlePieceIcon } from '../../../shared/icons/puzzle-piece-icon.svg';
import { Avatar, IconButton } from '@mui/material';
import { routePermissions } from 'shared/fixtures/data/route-permission-list.data';
import { ApplicationRoute } from 'shared/enums/route.enum';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';

export enum MenuItemsIds {
  Dashboards = 1,
  Risks,
  Campaigns,
  AutomationManagement,
  Integrations,
  Settings,
  Help,
  UserManagement,
  Organization,
  Assets,
}

export interface UserMenuItemDetails {
  name: string;
  email: string;
  profilePictureUrl: string;
  className?: string;
}

export const getMenuConfig = (translation: any): MenuConfigType[] => [
  {
    id: MenuItemsIds.Dashboards,
    to: '/dashboards',
    icon: '',
    title: translation(`global.sidebar.titles.dashboards`),
    iconSvg: Dashboard,
    permissions: routePermissions[ApplicationRoute.DASHBOARDS],
    submenu: [
      {
        id: MenuItemsIds.AutomationManagement,
        to: '/dashboard/risk',
        icon: '',
        title: translation(`global.sidebar.titles.risk`),
        permissions: routePermissions[ApplicationRoute.DASHBOARDS],
      },
      {
        id: MenuItemsIds.AutomationManagement,
        to: '/dashboard/comparative',
        icon: '',
        title: translation(`global.sidebar.titles.comparative`),
        permissions: routePermissions[ApplicationRoute.DASHBOARDS],
      },
    ],
  },
  {
    id: MenuItemsIds.Risks,
    to: '/risks',
    icon: '',
    title: translation(`global.sidebar.titles.risks`),
    iconSvg: Risk,
    permissions: routePermissions[ApplicationRoute.RISKS],
  },
  {
    id: MenuItemsIds.Assets,
    to: '/assets',
    icon: '',
    title: translation(`global.sidebar.titles.resources`),
    iconSvg: Assets,
    featureFlag: SystemFeatureFlag.ASSETS_VIEW,
  },
  {
    id: MenuItemsIds.Campaigns,
    to: '/campaigns',
    icon: '',
    title: translation(`global.sidebar.titles.campaigns`),
    iconSvg: CampaignsIcon,
    permissions: routePermissions[ApplicationRoute.CAMPAIGNS],
  },
  {
    id: MenuItemsIds.AutomationManagement,
    to: '',
    icon: '',
    title: translation(`global.sidebar.titles.automationManagement`),
    iconSvg: SettingsWheelsIcon,
    submenu: [
      {
        id: MenuItemsIds.AutomationManagement,
        to: '/automation-management',
        icon: '',
        title: translation(`global.sidebar.titles.guardrails`),
        permissions: routePermissions[ApplicationRoute.AUTOMATION_MANAGEMENT],
        featureFlag: SystemFeatureFlag.UI_REMEDIATION_GUARDRAILS_DEPRECATED,
      },
      {
        id: MenuItemsIds.AutomationManagement,
        to: '/flows',
        icon: '',
        title: translation(`global.sidebar.titles.flows`),
        permissions: routePermissions[ApplicationRoute.WORKFLOWS],
      },
    ],
  },
  {
    id: MenuItemsIds.Integrations,
    to: '/integrations',
    icon: '',
    title: translation(`global.sidebar.titles.integrations`),
    iconSvg: PuzzlePieceIcon,
    permissions: routePermissions[ApplicationRoute.INTEGRATIONS],
  },
  {
    id: MenuItemsIds.Settings,
    to: '/settings',
    icon: '',
    title: translation(`global.sidebar.titles.settings`),
    iconSvg: SettingsIcon,
    permissions: routePermissions[ApplicationRoute.SETTINGS],
  },
];

export const getAvailableMenuItems = (
  menuItems: Array<MenuConfigType>,
  userPermissions: Array<ApplicationPermission>
) => {
  return menuItems
    .filter((menuItem: MenuConfigType) => {
      if (menuItem.submenu?.length) {
        const hasMenuPermission = menuItem?.submenu.reduce(
          (accumulator, currentValue) => {
            return (
              accumulator ||
              Boolean(
                currentValue.permissions?.every((permission) =>
                  userPermissions.includes(permission)
                )
              )
            );
          },
          false as boolean
        );

        return hasMenuPermission;
      }

      if (menuItem.permissions?.length) {
        const hasPermission = menuItem.permissions.every((permission) =>
          userPermissions.includes(permission)
        );

        return hasPermission;
      }
      return true;
    })
    ?.map((menuItem: MenuConfigType) => {
      if (menuItem.submenu?.length) {
        return {
          ...menuItem,
          submenu: menuItem.submenu.filter((subMenuItem: MenuConfigType) => {
            const hasPermission = subMenuItem?.permissions?.every(
              (permission) => userPermissions.includes(permission)
            );

            return hasPermission;
          }),
        };
      }

      return menuItem;
    });
};

export const showAdminPortal = () => {
  AdminPortal.show();
};

export const logoutUrl = new URL(
  `oauth/logout?post_logout_redirect_uri=${window.location.origin}`,
  commonConfig.fronteggBaseUrl
).toString();

const logOut = () => {
  console.log('logout.......');
  localStorage.removeItem(authConstants.authAccessToken);
  window.location.replace(logoutUrl);
};

const buildMailtoSubject = (translation: any): string => {
  const userDetailsService = new UserDetailsService();
  let mailtoWithPlaceHolders = translation(`global.sidebar.email.subject`);

  mailtoWithPlaceHolders = mailtoWithPlaceHolders.replace(
    '<<user>>',
    userDetailsService.getFullName()
  );
  mailtoWithPlaceHolders = mailtoWithPlaceHolders.replace(
    '<<company>>',
    userDetailsService.getOrganization()
  );

  return mailtoWithPlaceHolders;
};

const contactUs = (translation: any) => {
  const email = commonConfig.contactUsEmail;
  const subject = buildMailtoSubject(translation);
  const body = translation(`global.sidebar.email.body`);
  const mailToUrl = `mailto:${email}?subject=${subject}&body=${body}`;

  window.open(mailToUrl, '_blank');
};

const documentation = () => {
  window.open('https://docs.opus.security/docs', '_blank');
};

const userManagementIcon = (userManagementDetails: UserMenuItemDetails) => {
  return (
    <IconButton title={userManagementDetails.name} className="user-menu-item">
      <Avatar
        alt={userManagementDetails.email}
        src={userManagementDetails.profilePictureUrl}
        className={userManagementDetails.className}
      />
    </IconButton>
  );
};

export const getBottomMenuConfig = (
  translation: any,
  userManagementDetails: UserMenuItemDetails,
  logoutCallback: () => void
): MenuConfigType[] => [
  {
    id: MenuItemsIds.Help,
    to: '',
    icon: '',
    title: translation(`global.sidebar.titles.help`),
    iconSvg: LifeRingIcon,
    submenu: [
      {
        id: MenuItemsIds.Help,
        to: '',
        icon: '',
        callBackFunction: () => {
          documentation();
        },
        title: translation(`global.sidebar.titles.documentation`),
      },
      {
        id: MenuItemsIds.Help,
        to: '',
        icon: '',
        callBackFunction: () => {
          contactUs(translation);
        },
        title: translation(`global.sidebar.titles.contactUs`),
      },
    ],
  },
  {
    id: MenuItemsIds.UserManagement,
    to: '',
    icon: '',
    title: translation(`global.sidebar.titles.help`),
    iconAvatar: userManagementIcon(userManagementDetails),
    submenu: [
      {
        id: MenuItemsIds.UserManagement,
        to: '',
        icon: '',
        callBackFunction: () => {
          showAdminPortal();
        },
        title: translation(`global.sidebar.titles.userManagement`),
      },
      {
        id: MenuItemsIds.UserManagement,
        to: '',
        icon: '',
        callBackFunction: () => {
          logoutCallback();
        },
        title: translation(`global.sidebar.titles.logout`),
      },
    ],
  },
];
