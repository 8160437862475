import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { useEffect, useMemo } from 'react';
import { reportRuleFilterAllOption } from 'shared/fixtures/data/report-rule.data';
import { OrganizationNodeDataHandler } from 'shared/handlers/organization-node-data.handler';
import { useFetchUsersMutation } from 'Auth/store/api';
import { UserDataHandler } from 'shared/handlers/user-data.handler';

type OrganizationOptionList = {
  scopesOptions?: Array<AutocompleteOption>;
  groupsOptions?: Array<AutocompleteOption>;
  servicesOptions?: Array<AutocompleteOption>;
  emailOptionList: Array<AutocompleteOption>;
  scopesOptionsLoading: boolean;
  groupsOptionsLoading: boolean;
  servicesOptionsLoading: boolean;
};

const userDataHandler = new UserDataHandler();

const organizationNodeDataHandler = new OrganizationNodeDataHandler();

export function useGetOrganizationOptions(
  preventUserFetch?: boolean
): OrganizationOptionList {
  const [fetchUsers, { data: userList }] = useFetchUsersMutation();

  const [fetchScopeList, { data: scopeList, isLoading: scopeListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [fetchGroupList, { data: groupList, isLoading: groupListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [fetchServiceList, { data: services, isLoading: areServicesLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
    });
    fetchServiceList({
      nodesType: OrganizationNodeType.DATA,
    });
    if (!Boolean(preventUserFetch)) fetchUsers();
  }, [preventUserFetch]);

  const scopesOptionList = useMemo(() => {
    if (scopeList) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeList
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return undefined;
  }, [scopeList]);

  const groupsOptionList = useMemo(() => {
    if (groupList) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          groupList
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return undefined;
  }, [groupList]);

  const servicesOptionList = useMemo(() => {
    if (services) {
      const options =
        organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          services
        );

      return [reportRuleFilterAllOption, ...options];
    }

    return undefined;
  }, [services]);

  const userEmailList = useMemo(() => {
    if (userList) {
      return userDataHandler.transformUserListToAutocompleteEmailList(userList);
    }

    return [];
  }, [userList]);

  return {
    scopesOptions: scopesOptionList,
    groupsOptions: groupsOptionList,
    servicesOptions: servicesOptionList,
    scopesOptionsLoading: scopeListLoading,
    groupsOptionsLoading: groupListLoading,
    servicesOptionsLoading: areServicesLoading,
    emailOptionList: userEmailList,
  };
}
