import { allOption } from 'Common/components/Autocomplete/Autocomplete';
import { AutocompleteOption } from 'FindingDetails/store/api';
import _ from 'lodash';
import {
  operationalDashboardFilterOptions,
  operationalDashboardTrendMetricsTypeOptions,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { reportRuleFrequencyOptions } from 'shared/fixtures/data/report-rule.data';
import {
  ComparativeReportRuleFormData,
  ComparisonReportConfiguration,
  ComparisonReportFactor,
  ComparisonReportObjects,
  reportRuleCompareByOptions,
  reportRuleConfigurationTypeOptions,
  ReportRuleCreationType,
  ReportRuleFrequencyType,
  ReportRuleMode,
  ReportRuleModel,
  reportRuleModeOptions,
  reportRuleRecipientOptions,
  ReportRuleSecurityWidget,
  reportRuleSecurityWidgetOptions,
  ReportRuleStatus,
  SecurityReportConfiguration,
  SecurityReportRuleFormData,
  SecurityReportSelectedWidget,
} from 'shared/models/data/report-rule.model';

export class ReportRuleDataHandler {
  transformComparativeReportRuleFormDataToPostData(
    formData: ComparativeReportRuleFormData
  ): ReportRuleModel {
    return {
      name: formData.name,
      type: ReportRuleCreationType.COMPARATIVE,
      description: formData.description,
      scheduleSettings: {
        frequencyType: formData.frequency.value as ReportRuleFrequencyType,
        startTime: new Date(formData.startingOn?.value),
        frequencyUnit: 1,
      },
      recipients:
        formData.mode.value === ReportRuleMode.ROLE_BASED_REPORT
          ? [
              {
                type: 'role',
                value: formData.recipients[0]?.value,
              },
            ]
          : formData.recipients.map((recipient) => ({
              type: 'email',
              value: recipient.value,
            })),
      configuration: {
        comparisonObject: formData.type.value as ComparisonReportObjects,
        comparisonFactors: formData.compareBy.map(
          (comparyByOption) => comparyByOption.value
        ) as Array<ComparisonReportFactor>,
      } as ComparisonReportConfiguration,
      reportScope: {
        domains: formData.domains?.map((domain) => domain.value),
        scopeIds: formData?.scopes ? [formData?.scopes?.value] : [],
        groupIds: formData?.groups
          ? formData?.groups?.map((groupOption) => groupOption.value)
          : [],
      },
      status: ReportRuleStatus.ENABLED,
    };
  }

  transformSecurityReportRuleFormDataToPostData(
    formData: SecurityReportRuleFormData
  ): ReportRuleModel {
    let selectedWidgets = (
      formData.widgets?.length
        ? [
            ...formData.widgets.map((widget) => ({
              name: widget.value,
            })),
          ]
        : []
    ) as Array<SecurityReportSelectedWidget>;

    if (formData.metrics?.length > 0) {
      selectedWidgets = [
        ...selectedWidgets,
        {
          name: ReportRuleSecurityWidget.TRENDS_METRICS,
          configuration: {
            metrics: formData.metrics.map((metric) => metric.value),
            timeFrame: formData.timeFrame?.value,
          },
        },
      ];
    }

    if (formData.trendingChartMetrics?.length > 0) {
      selectedWidgets = [
        ...selectedWidgets,
        {
          name: ReportRuleSecurityWidget.TRENDS_GRAPH,
          configuration: {
            trendingChartMetrics: formData.trendingChartMetrics.map(
              (trendingChartMetric) => trendingChartMetric.value
            ),
            timeFrame: formData.timeFrame?.value,
          },
        },
      ];
    }

    return {
      name: formData.name,
      type: ReportRuleCreationType.SECURITY,
      description: formData.description,
      scheduleSettings: {
        frequencyType: formData.frequency.value as ReportRuleFrequencyType,
        startTime: new Date(formData.startingOn?.value),
        frequencyUnit: 1,
      },
      recipients:
        formData.mode.value === ReportRuleMode.ROLE_BASED_REPORT
          ? [
              {
                type: 'role',
                value: formData.recipients[0]?.value,
              },
            ]
          : formData.recipients.map((recipient) => ({
              type: 'email',
              value: recipient.value,
            })),
      configuration: {
        selectedWidgets,
      } as SecurityReportConfiguration,
      reportScope: {
        domains: formData.domains?.map((domain) => domain.value),
        scopeIds: formData?.scopes ? [formData?.scopes?.value] : [],
        groupIds: formData?.groups ? [formData?.groups?.value] : [],
        serviceIds: formData.services?.map((service) => service.value),
      },
      status: ReportRuleStatus.ENABLED,
    };
  }

  transformReportRuleToComparativeReportRuleFormData(
    reportRule: ReportRuleModel,
    emailOptions?: Array<any>,
    organizationOptions?: {
      scopeOptions?: Array<AutocompleteOption>;
      groupOptions?: Array<AutocompleteOption>;
    }
  ): ComparativeReportRuleFormData {
    const { scheduleSettings, recipients, configuration, reportScope } =
      reportRule;

    return {
      name: reportRule.name,
      description: reportRule.description,
      type: reportRuleConfigurationTypeOptions.find(
        (option) =>
          option.value ===
          (configuration as ComparisonReportConfiguration).comparisonObject
      )!,
      compareBy: reportRuleCompareByOptions.filter((option) =>
        (
          configuration as ComparisonReportConfiguration
        ).comparisonFactors?.includes(option.value as ComparisonReportFactor)
      )!,
      domains:
        _.compact(
          reportScope.domains?.map((domain) => ({
            value: domain,
            label: domain,
          }))
        ) || [],
      scopes: reportScope.scopeIds?.length
        ? organizationOptions?.scopeOptions?.find(
            (scopeOption) =>
              reportScope.scopeIds &&
              scopeOption.value === reportScope.scopeIds[0]
          )
        : undefined,
      groups: reportScope.groupIds?.includes(allOption.value)
        ? organizationOptions?.groupOptions?.filter(
            (groupOption) => groupOption.value !== allOption.value
          ) || []
        : _.compact(
            reportScope.groupIds?.map((groupId) =>
              organizationOptions?.groupOptions?.find(
                (groupOption) => groupOption.value === groupId
              )
            )
          ) || [],
      mode: reportRuleModeOptions.find(
        (option) =>
          option.value ===
          (recipients[0]?.type === 'role'
            ? ReportRuleMode.ROLE_BASED_REPORT
            : ReportRuleMode.STATIC_REPORT)
      )!,
      recipients: _.compact(
        recipients.map((recipient) =>
          recipient.type === 'role'
            ? reportRuleRecipientOptions.find(
                (option) => option.value === recipient.value
              )!
            : emailOptions?.find(
                (option) => option.value === recipient.value
              ) || {
                value: recipient.value,
                label: recipient.value,
              }
        )
      ),
      startingOn: {
        value: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toISOString()
          : '',
        label: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toLocaleDateString()
          : '',
      },
      frequency: reportRuleFrequencyOptions.find(
        (option) => option.value === scheduleSettings.frequencyType
      )!,
    };
  }

  transformReportRuleToSecurityReportRuleFormData(
    reportRule: ReportRuleModel,
    emailOptions?: Array<any>,
    organizationOptions?: {
      scopeOptions?: Array<AutocompleteOption>;
      groupOptions?: Array<AutocompleteOption>;
      serviceOptions?: Array<AutocompleteOption>;
    }
  ): SecurityReportRuleFormData {
    const { scheduleSettings, recipients, configuration, reportScope } =
      reportRule;

    const securityConfiguration = configuration as SecurityReportConfiguration;

    const metrics =
      securityConfiguration.selectedWidgets
        .find(
          (selectedWidget) =>
            selectedWidget.name === ReportRuleSecurityWidget.TRENDS_METRICS
        )
        ?.configuration.metrics.map((metric: string) =>
          operationalDashboardTrendMetricsTypeOptions.find(
            (filterOption) => filterOption.value === metric
          )
        ) || [];

    const trendingChartMetrics =
      securityConfiguration.selectedWidgets
        .find(
          (selectedWidget) =>
            selectedWidget.name === ReportRuleSecurityWidget.TRENDS_GRAPH
        )
        ?.configuration.trendingChartMetrics.map((metric: string) =>
          operationalDashboardTrendMetricsTypeOptions.find(
            (filterOption) => filterOption.value === metric
          )
        ) || [];

    const timeFrame = operationalDashboardFilterOptions.find(
      (filterOption) =>
        filterOption.value ===
        securityConfiguration.selectedWidgets.find(
          (selectedWidget) =>
            selectedWidget.name === ReportRuleSecurityWidget.TRENDS_METRICS
        )?.configuration.timeFrame
    );

    return {
      name: reportRule.name,
      description: reportRule.description,
      widgets: _.compact(
        (configuration as SecurityReportConfiguration).selectedWidgets.map(
          (widget) =>
            reportRuleSecurityWidgetOptions.find(
              (option) => option.value === widget.name
            )!
        )
      ),
      metrics,
      trendingChartMetrics,
      timeFrame: timeFrame,
      domains:
        _.compact(
          reportScope.domains?.map((domain) => ({
            value: domain,
            label: domain,
          }))
        ) || [],
      scopes: reportScope.scopeIds?.length
        ? organizationOptions?.scopeOptions?.find(
            (scopeOption) =>
              reportScope.scopeIds &&
              scopeOption.value === reportScope.scopeIds[0]
          )
        : undefined,
      groups: reportScope.groupIds?.length
        ? organizationOptions?.groupOptions?.find(
            (groupOption) =>
              reportScope.groupIds &&
              groupOption.value === reportScope.groupIds[0]
          )
        : undefined,
      services: reportScope.serviceIds?.includes(allOption.value)
        ? organizationOptions?.serviceOptions?.filter(
            (serviceOption) => serviceOption.value !== allOption.value
          ) || []
        : _.compact(
            reportScope.serviceIds?.map((serviceId) =>
              organizationOptions?.serviceOptions?.find(
                (serviceOption) => serviceOption.value === serviceId
              )
            )
          ) || [],
      mode: reportRuleModeOptions.find(
        (option) =>
          option.value ===
          (recipients[0]?.type === 'role'
            ? ReportRuleMode.ROLE_BASED_REPORT
            : ReportRuleMode.STATIC_REPORT)
      )!,
      recipients: _.compact(
        recipients.map((recipient) =>
          recipient.type === 'role'
            ? reportRuleRecipientOptions.find(
                (option) => option.value === recipient.value
              )!
            : emailOptions?.find(
                (option) => option.value === recipient.value
              ) || {
                value: recipient.value,
                label: recipient.value,
              }
        )
      ),
      startingOn: {
        value: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toISOString()
          : '',
        label: scheduleSettings.startTime
          ? new Date(scheduleSettings.startTime).toLocaleDateString()
          : '',
      },
      frequency: reportRuleFrequencyOptions.find(
        (option) => option.value === scheduleSettings.frequencyType
      )!,
    };
  }

  generateReportRulePreviewUrl(reportRule: ReportRuleModel) {
    return `/internal/dashboards/reports?name=${reportRule.name}&description=${
      reportRule.description
    }&type=${reportRule.type}&scheduleSettings=${JSON.stringify(
      reportRule.scheduleSettings
    )}&configuration=${JSON.stringify(
      reportRule.configuration
    )}&reportScope=${JSON.stringify(reportRule.reportScope)}`;
  }
}
