import { AuthorizedContent } from '@frontegg/react';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { FunctionComponent, useEffect, useState } from 'react';
import MoreActionsMenu from 'shared/components/MoreActionsMenu';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export const ReportRuleActionCellRenderer: FunctionComponent<any> = ({
  params,
  requiredPermissions,
  onEditReport,
  onSendReport,
  setShouldIgnoreRowClick,
  useDeleteMutation,
  title,
  messageKey,
  onDelete,
  t,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  return (
    <AuthorizedContent requiredPermissions={requiredPermissions}>
      <>
        <MoreActionsMenu
          actions={[
            {
              name: 'Edit',
              icon: SVG_ICON_TYPES.EDIT_ICON,
              onClick: () => {
                setShouldIgnoreRowClick(false);
                onEditReport && onEditReport(params.data);
              },
            },
            {
              name: 'Send',
              icon: SVG_ICON_TYPES.PAPER_PLANE_TOP_ICON,
              onClick: () => {
                setShouldIgnoreRowClick(false);
                onSendReport && onSendReport(params.data);
              },
            },
            {
              name: 'Delete',
              icon: SVG_ICON_TYPES.TRASH_ICON_OUTLINED,
              onClick: () => {
                setShouldIgnoreRowClick(false);
                setDeleteDialogOpen(true);
              },
            },
          ]}
          onButtonClick={() => {
            setShouldIgnoreRowClick(true);
          }}
          onClose={() => {
            setShouldIgnoreRowClick(false);
          }}
        />
        <DeleteWithConfirm
          id={params?.data?.id}
          setShouldIgnoreRowClick={setShouldIgnoreRowClick}
          useMutation={useDeleteMutation}
          title={title}
          message={t('settings.details.areYouSureYouWantToDeleteApplication', {
            param1:
              messageKey === 'severity'
                ? FindingSeverity[params?.data?.severity]
                : params?.data[messageKey],
          })}
          callback={(decision: boolean, response: any) => {
            setDeleteDialogOpen(false);
            onDelete(decision, response);
          }}
          open={deleteDialogOpen}
        >
          <></>
        </DeleteWithConfirm>
      </>
    </AuthorizedContent>
  );
};
