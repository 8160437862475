import { UserInfo, UserRole } from 'Auth/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';

export class UserDataHandler {
  transformUserListToAutocompleteEmailList(
    users: Array<UserInfo>
  ): Array<AutocompleteOption> {
    return users.map((user: UserInfo) => ({
      value: user.email,
      label: user.name,
    }));
  }

  transformUserRoleListToAutocompleteRoleList(
    roles: Array<UserRole>
  ): Array<AutocompleteOption> {
    return roles.map((role: UserRole) => ({
      value: role.idpId || role.id,
      label: role.name,
    }));
  }
}
