import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OperationalMetricType,
  OperationalWidgetAnalyticsType,
} from 'shared/models/data/operational-widget-data.model';

export class DashboardWidgetHandler {
  getOperationalWidgetTitle(translation: any, type: OperationalMetricType) {
    return translation(`dashboards.widgets.${type}`);
  }

  getOperationalWidgets(translation: any) {
    return [
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.NEW_FINDINGS
        ),
        type: OperationalMetricType.NEW_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.NEW_RESOLVED_FINDINGS
        ),
        type: OperationalMetricType.NEW_RESOLVED_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.NEW_RESOLVED_WITHIN_SLA
        ),
        type: OperationalMetricType.NEW_RESOLVED_WITHIN_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.NEW_RESOLVED_OUTSIDE_SLA
        ),
        type: OperationalMetricType.NEW_RESOLVED_OUTSIDE_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.NEW_EXCEPTION_APPROVED
        ),
        type: OperationalMetricType.NEW_EXCEPTION_APPROVED,
      },

      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.SLA_ATTAINMENT_RATE
        ),
        type: OperationalMetricType.SLA_ATTAINMENT_RATE,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TRENDING_MTTR
        ),
        type: OperationalMetricType.TRENDING_MTTR,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.NEW_FINDINGS_BREACHED_SLA
        ),
        type: OperationalMetricType.NEW_FINDINGS_BREACHED_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.PRIORITIZED_CHURN
        ),
        type: OperationalMetricType.PRIORITIZED_CHURN,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.PRIORITIZED_RATIO
        ),
        type: OperationalMetricType.PRIORITIZED_RATIO,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TOTAL_ACTIVE_FINDINGS
        ),
        type: OperationalMetricType.TOTAL_ACTIVE_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TOTAL_CRITICAL_FINDINGS
        ),
        type: OperationalMetricType.TOTAL_CRITICAL_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TOTAL_EXPLOITABLE_FINDINGS
        ),
        type: OperationalMetricType.TOTAL_EXPLOITABLE_FINDINGS,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TOTAL_ACTIVE_WITHIN_SLA
        ),
        type: OperationalMetricType.TOTAL_ACTIVE_WITHIN_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TOTAL_ACTIVE_OUTSIDE_SLA
        ),
        type: OperationalMetricType.TOTAL_ACTIVE_OUTSIDE_SLA,
      },
      {
        title: this.getOperationalWidgetTitle(
          translation,
          OperationalMetricType.TOTAL_MTTR
        ),
        type: OperationalMetricType.TOTAL_MTTR,
      },
    ];
  }
}
