import { DashboardReportsRiskWidgetProps } from 'Analytics/DashboardReports/components/DashboardReportsRisk/DashboardReportsRisk';
import DashboardWidgetCard from 'Dashboard/components/DashboardWidgetCard';
import { PostureWidgetCardProps } from 'Dashboard/components/PostureWidgetCard/PostureWidgetCard';
import { useGetDashboardPostureAnalyticsMutation } from 'Dashboard/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { NumberFormatHandler } from 'shared/handlers/number-format.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardReportsRiskTotalMetricsCardProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps,
    PostureWidgetCardProps {}

const numberFormatHandler = new NumberFormatHandler();

export const DashboardReportsRiskTotalMetricsCard: FunctionComponent<
  DashboardReportsRiskTotalMetricsCardProps
> = ({ title, icon, type, businessUnitIds, findingTypes }) => {
  const [
    getDashboardAnalytics,
    { data: dashboardAnalytics, isLoading: dashboardAnalyticsLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  const transformedDashboardAnalytics = useMemo(() => {
    if (dashboardAnalytics) {
      if (Number.isNaN(dashboardAnalytics.value)) return dashboardAnalytics;

      return {
        ...dashboardAnalytics,
        value: numberFormatHandler.formatNumberToString(
          dashboardAnalytics.value
        ),
      };
    }

    return undefined;
  }, [dashboardAnalytics]);

  useEffect(() => {
    if (businessUnitIds)
      getDashboardAnalytics({
        findingTypes: findingTypes,
        businessUnitIds,
        type,
      });
  }, [businessUnitIds, findingTypes]);

  return (
    <DashboardWidgetCard
      icon={icon}
      title={title}
      data={dashboardAnalytics}
      isLoading={dashboardAnalyticsLoading}
    />
  );
};
