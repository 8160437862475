import { Chip } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface ImpactScoreChipProps extends BaseComponentProps {
  value: number;
}

export const ImpactScoreChip: FunctionComponent<ImpactScoreChipProps> = ({
  value,
}) => {
  return (
    <Chip
      className="impact-score-chip"
      label={Math.round(value)}
      variant="outlined"
      classes={{
        label: 'impact-score-label',
      }}
    />
  );
};
