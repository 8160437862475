import * as yup from 'yup';
import { RuleCategory } from 'Settings/interfaces/RiskCustomizationConfig';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import { ExtendedFilterCategory } from 'shared/models/data/data-filter.model';
import { notificationRuleConditionOptions } from './notification-rule.data';

const dataFilterHandler = new DataFitlerHandler();

export const riskCustomizationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  properties: yup.array().optional(),
  riskCustomization: yup.array().optional(),
});

export const riskCustomizationConditionOptions: Array<ExtendedFilterCategory> =
  [
    ...notificationRuleConditionOptions.reduce<ExtendedFilterCategory[]>(
      (acc, item) => {
        if (item.id === RuleCategory.INTELLIGENCE) {
          const modifiedItem = {
            ...item,
            categories: item.categories?.filter(
              (category) =>
                category.id !== 'ssvcDecision' &&
                category.id !== 'ssvcWellBeingImpact'
            ),
          };

          acc.push(modifiedItem);
        } else {
          acc.push(item);
        }

        return acc;
      },
      []
    ),
  ];

export const riskCustomizationFormRequiredFields = ['name'];
