export enum SSVCFlowTypes {
  EXPLOITATION = 'exploitationStatus',
  AUTOMATABLE = 'automatable',
  TECHNICAL_IMPACT = 'technicalImpact',
  MISSION_AND_WELL_BEING = 'missionAndWellBeingImpactValue',
  DECISION = 'decision',
}

export enum SsvcDecision {
  ACT = 'Act',
  ATTEND = 'Attend',
  TRACK_ASTRIX = 'Track*',
  TRACK = 'Track',
}

export enum SsvcPublicWellbeingImpact {
  MINIMAL = 'minimal',
  MATERIAL = 'material',
  IRREVERSIBLE = 'irreversible',
}

export enum SsvcExploitationStatusReason {
  NONE = 'none',
  IS_DISCUSSED = 'isDisscuseddiscussionsCount',
  POC = 'pocCount',
  IN_THE_WILD = 'inTheWildCount',
}
export enum SsvcAutomatableReason {
  PentesterFrameworkCount = 'pentesterFrameworkCount',
  CvssVector = 'cvssVector',
  CvssVectorNoUI = 'cvssVectorNoUi',
  CvssVectorNoAv = 'cvssVectorNoAv',
  NONE = 'none',
}
export enum SsvcTechnicalImpactReason {
  CvssVector = 'cvssVector',
  CvssVectorNoConfideciality = 'NoConfideciality',
  CvssVectorNoIntegrity = 'NoIntegrity',
  NONE = 'none',
}
