import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { SSVCFlowTypes } from './SSVCTypes.enum';

export type SsvcItem = {
  type: SSVCFlowTypes;
  label: string;
  icon: SVG_ICON_TYPES;
  descriptionKey?: string;
};

export const SsvcItemsList: SsvcItem[] = [
  {
    type: SSVCFlowTypes.EXPLOITATION,
    label: 'Exploitation',
    icon: SVG_ICON_TYPES.SKULL_ICON,
    descriptionKey: '',
  },
  {
    type: SSVCFlowTypes.AUTOMATABLE,
    label: 'Automatable',
    icon: SVG_ICON_TYPES.ROBOT_ICON,
  },
  {
    type: SSVCFlowTypes.TECHNICAL_IMPACT,
    label: 'Technical Impact',
    icon: SVG_ICON_TYPES.BURST_ICON,
  },
  {
    type: SSVCFlowTypes.MISSION_AND_WELL_BEING,
    label: 'Mission & Well-Being',
    icon: SVG_ICON_TYPES.SHIELD_CHECK_ICON,
  },
  {
    type: SSVCFlowTypes.DECISION,
    label: 'Decision',
    icon: SVG_ICON_TYPES.GAVEL_ICON,
  },
];
