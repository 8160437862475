import { CircularProgress, TextField } from '@mui/material';
import ResourceAssetDetailsDrawer from 'Assets/components/ResourceAssetDetailsDrawer';
import { OrderState } from 'Common/utils/sort';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import FilterActions from 'Risk/components/FilterActions';
import RiskGrid from 'Risk/components/RiskGrid';
import { GridType } from 'Risk/store';
import {
  AgGridEvent,
  ColumnMovedEvent,
  ColumnVisibleEvent,
  GetRowIdParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { debounce } from 'lodash';
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import FindingCodeEventDrawerComplex from 'shared/components/FindingCodeEventDrawerComplex/FindingCodeEventDrawerComplex';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import {
  campaignFindingGridColumns,
  defaultHiddenCampaignFindingsGridColumns,
} from 'shared/fixtures/data/campaigns-grid.data';
import {
  FindingState,
  excludedRiskFilters,
  getRiskSearchParamsRequestDependencies,
  riskViewFilterCategories,
  vulnerabilityRiskFilters,
} from 'shared/fixtures/data/risk-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  ApiStaticOptionSourceProperties,
  CategoryState,
  ExtendedFilterCategory,
  KeyValueState,
  LabelFilterState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CampaignFindingsGridProps extends BaseComponentProps {}

const advanceFilterDataHandler = new AdvanceFilterHandler();

export const CampaignFindingsGrid: FunctionComponent<
  CampaignFindingsGridProps
> = () => {
  const { t: translation } = useTranslation();

  const { id: campaignId } = useParams();

  const [orderParams, setOrderParams] = useState<OrderState | null>(null);

  const [fetchFindingsWithResource, { isLoading, data: findingsWithResource }] =
    useFetchFindingsWithResourceMutation();

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();
  const [, , getUrlSearchParams] = useQueryParams();

  const [selectedFindingIds, setSelectedFindingIds] = useState<Array<string>>(
    []
  );

  const [filterState, setFilterState] = useState<Record<string, CategoryState>>(
    {
      state: {
        selectedOptions: [
          {
            value: FindingState.ACTIVE,
            label: FindingState.ACTIVE,
          },
        ],
      },
    }
  );

  const handleFindingDetailsModalOpen = (findingId: string) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openFindingId', findingId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const searchParams = useMemo(() => {
    const updatedFilterState = Object.keys(filterState).reduce(
      (accumulator, filterStateKey: string) => {
        if (!excludedRiskFilters.includes(filterStateKey)) {
          accumulator[filterStateKey] = filterState[filterStateKey];
        }
        return accumulator;
      },
      {} as any
    );

    return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
      updatedFilterState,
      riskViewFilterCategories
    );
  }, [filterState]);

  const vulnerabilitySearchParams = useMemo(() => {
    const updatedFilterState = Object.keys(filterState).reduce(
      (accumulator, filterStateKey: string) => {
        if (vulnerabilityRiskFilters.includes(filterStateKey)) {
          accumulator[filterStateKey] = filterState[filterStateKey];
        }
        return accumulator;
      },
      {} as any
    );

    return advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
      updatedFilterState,
      riskViewFilterCategories
    );
  }, [filterState]);

  const tagsSearchParams = useMemo(() => {
    if (filterState.tags) {
      const tagsState = filterState.tags as KeyValueState;

      const tagsFilterPayload =
        advanceFilterDataHandler.translateFilterStateSelectionsToApiFilters(
          {
            tags: {
              ...tagsState,
              selections: tagsState.selections?.filter(
                (selection) => selection.key.value.length > 0
              ),
            },
          },
          riskViewFilterCategories
        );

      return tagsFilterPayload.tags;
    }

    return {};
  }, [filterState]);

  const labelSearchParams = useMemo<LabelFilterState>(() => {
    if (filterState.labels) {
      const labelState = filterState.labels as LabelFilterState;

      return labelState;
    }

    return {};
  }, [filterState]);

  const onCampaignFilterChange = (categoryId: string, state: CategoryState) => {
    setFilterState((prevCampaignFilterState) => {
      if (Object.keys(state).length > 0) {
        return {
          ...prevCampaignFilterState,
          [categoryId]: state,
        };
      }

      return { ...prevCampaignFilterState };
    });
  };

  const onCampaignFilterRemove = (categoryId: string) => {
    setFilterState((prevCampaignFilterState) => {
      const updatedFilterState = { ...prevCampaignFilterState };

      if (updatedFilterState[categoryId]) delete updatedFilterState[categoryId];

      return updatedFilterState;
    });
  };

  const gridRef = useRef<AgGridEvent | null>();

  useEffect(() => {
    fetchFindingsWithResource({
      filter: { title: { value: searchKeyword }, ...searchParams },
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
      vulnerabilityFilter: { ...vulnerabilitySearchParams },
      tagFilter: {
        ...tagsSearchParams,
      },
      campaignFilter: {
        id: campaignId,
      },
      order: orderParams || undefined,
      labelFilter: labelSearchParams,
    });
  }, [
    pageSize,
    pageNumber,
    campaignId,
    ...getRiskSearchParamsRequestDependencies(searchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    ...getRiskSearchParamsRequestDependencies({ labels: labelSearchParams }),
    searchKeyword,
    orderParams,
  ]);

  const onSearchKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchKeyword(value);
  };

  const enrichCategoryGroupItem = (
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> => {
    const selectedScopeOption = filterState['scopeId'] as SingleSelectState;

    const selectedScopeId = selectedScopeOption?.selectedOptions
      ? selectedScopeOption?.selectedOptions[0]?.value
      : undefined;

    if (selectedScopeId) {
      return categories.map((category: ExtendedFilterCategory) => {
        if (category.id === 'organization') {
          return {
            ...category,
            categories: category.categories?.map(
              (subCategory: ExtendedFilterCategory) => {
                if (subCategory.id === 'groupId') {
                  return {
                    ...subCategory,
                    state: {
                      ...subCategory.state,
                      source: {
                        ...(subCategory.state as SingleSelectState).source,
                        body: {
                          ...((
                            (subCategory.state as SingleSelectState)
                              .source as ApiStaticOptionSourceProperties
                          ).body || {}),
                          startNodeId: selectedScopeId,
                        },
                      },
                    },
                  };
                }
                return subCategory;
              }
            ),
          };
        }

        return category;
      });
    }

    return categories;
  };

  return (
    <div className="campaign-findings-grid-container">
      <div className="campaign-findings-grid-header">
        <div className="campaign-findings-grid-header-text">
          Campaign Findings
        </div>
        <div className="campaigns-findings-grid-filters">
          <TextField
            className={'filter-input filter-main-input'}
            placeholder={translation(`common.searchPlaceholder`)}
            onChange={debounce(onSearchKeywordChange, 400)}
            InputProps={{
              className: 'search-filter-input',
              startAdornment: (
                <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
              ),
            }}
          />
          <FilterChipGroup
            categories={enrichCategoryGroupItem(riskViewFilterCategories)}
            categoryState={filterState}
            onChange={onCampaignFilterChange}
            onRemoveChip={onCampaignFilterRemove}
          />
        </div>
      </div>

      {selectedFindingIds.length ? (
        <div className="risk-page-actions-container">
          <FilterActions
            onCancel={() => {
              if (gridRef?.current) {
                gridRef?.current?.api?.deselectAll();
              }
              setSelectedFindingIds([]);
            }}
            itemCount={selectedFindingIds.length}
            selectionProps={{
              selectedChildren: selectedFindingIds,
              availableChildren: [],
              rows: [],
              totalAvailableChildren: 0,
              totalSelectedChildren: selectedFindingIds.length,
              gridType: GridType.None,
            }}
            getSelectionList={() => selectedFindingIds}
            tagFilter={tagsSearchParams}
            labelFilter={labelSearchParams}
            vulnerabilityFilter={vulnerabilitySearchParams}
            genericFilter={searchParams}
          />
        </div>
      ) : (
        <></>
      )}

      <div className="campaign-findings-grid">
        <CommonSimpleDataGrid
          rowData={findingsWithResource?.data as Array<FindingsWithResource>}
          columnDefs={campaignFindingGridColumns}
          rowSelection="multiple"
          defaultColDef={{
            resizable: true,
            sortable: true,
          }}
          suppressRowClickSelection
          visibilityControlProps={{
            enableVisibilityControls: true,
            columns: defaultHiddenCampaignFindingsGridColumns,
          }}
          onRowClicked={(event: RowClickedEvent) => {
            handleFindingDetailsModalOpen(event.data.findingId);
          }}
          paginationProps={{
            totalItems: findingsWithResource?.totalItems || 0,
            pageSize,
            currentPage: pageNumber,
            onPageChange: (selectedPage) => {
              setPageNumber(selectedPage);
            },
            onPageSizeChange: (selectedPageSize) => {
              setPageSize(selectedPageSize);
            },
          }}
          isLoading={isLoading}
          onSelect={(selectedIds) => {
            setSelectedFindingIds([...selectedIds]);
          }}
          selectionModel={selectedFindingIds}
          onSort={(sortedColumns: Array<OrderState>) => {
            if (sortedColumns.length) {
              setOrderParams(sortedColumns[0]);
            } else {
              setOrderParams(null);
            }
          }}
          keepCurrentSelections
          otherComponents={{
            NoDataBackdropComponent: (
              <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
            ),
          }}
          loadingOverlayComponent={() => <CircularProgress />}
          gridRef={gridRef}
          getRowId={(row: GetRowIdParams) => {
            return row.data.findingId;
          }}
          isRowNavigable
        />
      </div>
      <FindingCodeEventDrawerComplex />
      <ResourceAssetDetailsDrawer />
    </div>
  );
};
