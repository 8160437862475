import { BusinesUnitCriticality } from 'Settings/components/BusinessUnitList/enums/business-unit-criticality.enum';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

export interface InsightDataItem {
  type: PostureWidgetAnalyticsType;
  title: string;
  description: string;
  criticality: BusinesUnitCriticality;
}
export const insightsData: InsightDataItem[] = [
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_PRIORITIZED_EXTERNAL_FACING_RISKS,
    title: 'Prioritized External Facing Risks',
    description:
      'Prioritized vulnerabilities detected on internet-facing resources requiring immediate action.',
    criticality: BusinesUnitCriticality.Medium,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_RECENTLY_SEEN_PRIORITIZED_VULNERABILITIES,
    title: 'Recently Seen Prioritized Vulnerabilities',
    description:
      'Recently disclosed prioritized vulnerabilities with active exploitation in the wild.',
    criticality: BusinesUnitCriticality.Medium,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_SSVC_ACTION_REQUIRED,
    title: 'Critical SSVC Action Required',
    description:
      'Vulnerabilities requiring immediate action with material risk level.',
    criticality: BusinesUnitCriticality.Critical,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_SENSITIVE_DATA_SLA_BREACH,
    title: 'Sensitive Data SLA Breach',
    description:
      'Highly prioritized vulnerabilities on sensitive resources exceeding remediation SLA.',
    criticality: BusinesUnitCriticality.High,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_PATCHABLE_ACTIVE_RANSOMWARE,
    title: 'Patchable Active Ransomware',
    description:
      'Highly prioritized patchable vulnerabilities actively targeted by ransomware groups.',
    criticality: BusinesUnitCriticality.Critical,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_TRENDING_EXPLOIT_ALERT,
    title: 'Trending Exploit Alert',
    description:
      'Highly prioritized vulnerabilities with active exploit code in circulation.',
    criticality: BusinesUnitCriticality.High,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_SIMPLE_ATTACK_PATHS,
    title: 'Simple Attack Paths',
    description:
      'Highly prioritized vulnerabilities under active exploitation with simple attack paths.',
    criticality: BusinesUnitCriticality.Critical,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_RECURRING_CRITICAL_ISSUES,
    title: 'Recurring Critical Issues',
    description:
      'Reopened prioritized vulnerabilities on sensitive external-facing resources.',
    criticality: BusinesUnitCriticality.Medium,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_SENSITIVE_DATA_MALWARE_RISK,
    title: 'Sensitive Data Malware Risk',
    description:
      'Vulnerabilities on sensitive resources flagged with ransomware/malware indicators.',
    criticality: BusinesUnitCriticality.Critical,
  },
  {
    type: PostureWidgetAnalyticsType.ACCUMULATIVE_EXTERNAL_ATTACK_SURFACE_ALERT,
    title: 'External Attack Surface Alert',
    description:
      'External-facing resources with active ransomware and malware indicators.',
    criticality: BusinesUnitCriticality.Critical,
  },
];
