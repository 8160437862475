import { OrderType } from '../Common/utils/sort';

export const defaultFilter = {
  status: [{ value: 'In Progress' }, { value: 'Open' }],
};
export const defaultOrder = {
  field: 'externalCreatedAt',
  type: OrderType.DESC,
};

export const defaultPage = 1;
export const defaultPageSize = 25;

export const CisaSsvcLink = 'https://docs.opus.security/docs/opus-cisa-ssvc';
