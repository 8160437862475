import { Box, CircularProgress } from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import AdministrationHeader from '../AdministrationHeader';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { AdministrationHeaderProps } from '../AdministrationHeader/AdministrationHeader';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import CommonPagination from 'Common/components/Pagination';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { GetRowIdParams, RowClickedEvent } from 'ag-grid-community';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import { AgGridReactProps } from 'ag-grid-react';
import { AgGridEvent } from 'ag-grid-community';

interface TableProps extends AgGridReactProps {
  isLoading?: boolean;
  deleteMutation: any;
  onItemClick: (item: any) => void;
  ignoreRowClick?: boolean;
}
interface SettingsLayoutProps<T> {
  headerProps: AdministrationHeaderProps<T>;
  tableProps: TableProps;
  fetchData: () => void;
  components?: {
    TableComponent?: ReactNode;
    ButtonComponent?: ReactNode;
  };
  classes?: {
    header?: string;
    body?: string;
  };
}

export const SettingsLayout = <T extends {}>({
  headerProps,
  tableProps,
  fetchData,
  components,
  classes = {},
}: SettingsLayoutProps<T>) => {
  const [pageSize, setPageSize] = useState<number>(25);
  const [page, setPage] = useState<number>(1);
  const [paginatedRows, setPaginatedRows] = useState<any[]>([]);

  const gridRef = useRef<AgGridEvent | null>(null);

  const onPageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const calculatePaginatedRows = (size: number, numb: number) => {
    const startRowIndex = (numb - 1) * size;
    const endRowIndex = startRowIndex + size;
    const paginatedRows =
      tableProps.rowData && tableProps.rowData.length
        ? tableProps.rowData.slice(startRowIndex, endRowIndex)
        : [];
    setPaginatedRows(paginatedRows);
  };

  useEffect(() => {
    if (tableProps.rowData) calculatePaginatedRows(pageSize, page);
  }, [pageSize, page, tableProps]);

  const onPageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setPage(1);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Box m={4} gap={2} display="grid">
        <CardWrapper
          padding={2}
          className={`${classes?.header || ''} settings-header`}
          height={'64px'}
        >
          <AdministrationHeader<T>
            {...headerProps}
            ButtonComponent={components?.ButtonComponent}
            setPage={setPage}
          />
        </CardWrapper>
        <Box height={'58vh'} className={classes?.body || ''}>
          {components?.TableComponent || (
            <CommonSimpleDataGrid
              rowData={paginatedRows}
              columnDefs={tableProps.columnDefs}
              rowSelection="multiple"
              defaultColDef={{
                resizable: true,
              }}
              gridRef={gridRef}
              containerClassName="settings-data-grid"
              suppressRowClickSelection
              onRowClicked={(event: RowClickedEvent) => {
                const shouldIgnoreElement = Boolean(
                  event.eventPath?.find((value: EventTarget) =>
                    (value as Element)?.classList?.contains('ignore-row-click')
                  )
                );

                if (shouldIgnoreElement || tableProps.ignoreRowClick) {
                  return;
                }

                tableProps.onItemClick(event.data);
              }}
              visibilityControlProps={{
                enableVisibilityControls: false,
                columns: [],
              }}
              isLoading={tableProps.isLoading}
              keepCurrentSelections
              otherComponents={{
                NoDataBackdropComponent: <NoDataBackdrop />,
              }}
              loadingOverlayComponent={() => <CircularProgress />}
              getRowId={(row: GetRowIdParams) => {
                return row.data.id;
              }}
            />
          )}
        </Box>
        <Box mt={2} className="settings-pagination" mx={2}>
          <CommonPagination
            totalItems={tableProps.rowData?.length || 0}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
};
