import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SsvcItemsList } from 'FindingDetails/interfaces/SsvcTypes';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SSVCFlowTypes } from 'FindingDetails/interfaces/SSVCTypes.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface SsvcInfoModalProps {
  handleModalClose: () => void;
  isOpen: boolean;
}

export const SsvcInfoModal: FunctionComponent<SsvcInfoModalProps> = ({
  handleModalClose,
  isOpen,
}) => {
  const { t: translation } = useTranslation();

  return (
    <CommonDialog
      classes="common-dialog-ssvc"
      open={isOpen}
      onClose={handleModalClose}
      title={translation(`findings.ssvc.modal.title`)}
      closeButtonText={translation('common.gotIt')}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.GAVEL_ICON} />}
    >
      <div className="thread-list-container">
        {SsvcItemsList.map((item, index) => {
          if (item.type !== SSVCFlowTypes.DECISION)
            return (
              <div key={index} className="thread-item">
                <div className="thread-icon">
                  <OpusSvgIcon type={item.icon} />
                </div>
                <div className="thread-content">
                  <p
                    className="thread-description"
                    dangerouslySetInnerHTML={{
                      __html: translation(`findings.ssvc.modal.${item.type}`),
                    }}
                  ></p>
                </div>
              </div>
            );
          else return <></>;
        })}
      </div>
    </CommonDialog>
  );
};
export default SsvcInfoModal;
