import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  useBulkAddLabelsMutation,
  useGetLabelsMutation,
} from 'shared/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import { AutocompleteOption } from 'FindingDetails/store/api';
import BulkActionDropdown from 'FindingDetails/components/FindingContent/components/BulkActionDropdown';
import {
  SystemLabel,
  SystemLabelType,
} from '../LabelManagementDropdown/LabelManagementDropdown';
import useCommonSelector from 'Common/utils/use-selector';
import { getVulnerabilitySearchParams } from 'Risk/store';
import { LabelFilterState } from 'shared/models/data/data-filter.model';

interface LabelAddModalProps extends BaseComponentProps {
  open: boolean;
  onClose: () => void;
  selection: any;
  tagFilter: any;
  labelFilter: LabelFilterState;
  vulnerabilityFilter: any;
  genericFilter: any;
}

export const LabelAddModal: FunctionComponent<LabelAddModalProps> = ({
  open,
  onClose,
  selection,
  genericFilter,
  vulnerabilityFilter,
  tagFilter,
  labelFilter,
}) => {
  const { t: translation } = useTranslation();

  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const [selectedLabels, setSelectedLabels] = useState<
    Array<AutocompleteOption>
  >([]);

  const [labelAnchorEl, setLabelAnchorEl] = useState<HTMLDivElement | null>(
    null
  );

  const [
    getLabelItems,
    { data: labelItems, isLoading: labelItemsLoading, error: labelItemsError },
  ] = useGetLabelsMutation();

  const [
    bulkAddLabels,
    { isLoading: bulkAddLabelsLoading, isSuccess: bulkAddLabelsSuccess },
  ] = useBulkAddLabelsMutation();

  useEffect(() => {
    if (open) {
      getLabelItems({
        queryString: 'orderBy=label&orderType=ASC&skip=0&take=10000',
      });
    }
  }, [open]);

  useEffect(() => {
    if (bulkAddLabelsSuccess) {
      onClose();
      setSelectedLabels([]);
    }
  }, [bulkAddLabelsSuccess]);

  return (
    <CommonDialog
      classes="common-dialog-actions label-add-modal"
      open={open}
      onClose={() => {
        onClose();
        setSelectedLabels([]);
      }}
      loadingButtonText={translation('common.saving')}
      title={translation(`labels.addLabels`)}
      isOnSubmitDisabled={selectedLabels.length === 0}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />}
      isLoading={bulkAddLabelsLoading}
      saveButtonText={translation('common.save')}
      buttonRequiredPermissions={[]}
      onSave={() => {
        bulkAddLabels({
          labels: selectedLabels,
          findings: [],
          selectionProps: selection,
          filter: {
            filter: genericFilter,
            vulnerabilityFilter: vulnerabilityFilter,
            tagFilter: tagFilter,
            labelFilter: labelFilter,
          },
        });
      }}
      closeButtonText={translation('common.cancel')}
    >
      <div
        className="label-add-modal-body"
        onClick={(event) => {
          setLabelAnchorEl(event.currentTarget);
        }}
      >
        <Autocomplete
          model="value"
          optionList={labelItems || []}
          loading={labelItemsLoading}
          onChangeCallBack={(model, value) => {
            const values = value as Array<AutocompleteOption>;

            setSelectedLabels(values);
          }}
          areOptionsLoaded
          classes={{
            root: 'multi-select-field-1',
            paper: 'multi-select-field-paper-1',
          }}
          enableCheckbox
          placeholder={translation(`labels.pleaseSelectLabels`)}
          values={selectedLabels}
          limitTags={4}
          disableDropdown
        />
      </div>

      <BulkActionDropdown
        open={Boolean(labelAnchorEl)}
        handleClose={() => {
          setLabelAnchorEl(null);
        }}
        anchorEl={labelAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        itemType={SystemLabelType.FINDING}
        onSelectLabels={(labels: Array<SystemLabel>) => {
          setSelectedLabels(
            labels.map((labelItem) => ({
              value: labelItem.label,
              label: labelItem.label,
            }))
          );
        }}
        selectedLabels={selectedLabels.map((selectedLabel) => ({
          label: selectedLabel.value,
        }))}
      />
    </CommonDialog>
  );
};
