import { AutocompleteOption } from 'FindingDetails/store/api';
import _ from 'lodash';
import { FunctionComponent, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { reportRuleFrequencyOptions } from 'shared/fixtures/data/report-rule.data';
import { useGetOrganizationOptions } from 'shared/hooks/useGetOrganizationData';
import {
  ReportRuleCreationType,
  ReportRuleProperty,
  ReportRuleScheduleSettings,
  ReportRuleScope,
} from 'shared/models/data/report-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardReportsDescriptionProps extends BaseComponentProps {}

export const DashboardReportsDescription: FunctionComponent<
  DashboardReportsDescriptionProps
> = () => {
  const [params] = useSearchParams();

  const { scopesOptions, groupsOptions, servicesOptions } =
    useGetOrganizationOptions(true);

  const contentGroupType = useMemo<ReportRuleCreationType>(() => {
    return params.get('type') as ReportRuleCreationType;
  }, [params.get('type')]);

  const timeframe = useMemo(() => {
    if (params.has('scheduleSettings')) {
      const scheduleSettingsQueryParam = params.get(
        'scheduleSettings'
      ) as string;

      try {
        const scheduleSettings = JSON.parse(
          scheduleSettingsQueryParam
        ) as ReportRuleScheduleSettings;

        return (
          reportRuleFrequencyOptions.find(
            (reportRuleFrequencyOption) =>
              reportRuleFrequencyOption.value === scheduleSettings.frequencyType
          )?.label || ''
        );
      } catch (err) {
        return '';
      }
    } else {
      return '';
    }
  }, [params]);

  const reportScope = useMemo<ReportRuleScope>(() => {
    if (params.get('reportScope')) {
      try {
        return JSON.parse(params.get('reportScope') as string);
      } catch (err) {
        return [];
      }
    }

    return [];
  }, [params]);

  const scopeFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.scopeIds?.length && scopesOptions?.length) {
      return _.compact(
        reportScope?.scopeIds?.map((scopeId: string) =>
          scopesOptions?.find((scopeOption) => scopeOption.value === scopeId)
        )
      );
    }

    return [];
  }, [reportScope.scopeIds, scopesOptions]);

  const groupFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.groupIds?.length && groupsOptions?.length) {
      return _.compact(
        reportScope?.groupIds?.map((groupId: string) =>
          groupsOptions?.find((groupOption) => groupOption.value === groupId)
        )
      );
    }

    return [];
  }, [reportScope.groupIds, groupsOptions]);

  const serviceFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.serviceIds?.length && servicesOptions?.length) {
      return _.compact(
        reportScope?.serviceIds?.map((serviceId: string) =>
          servicesOptions?.find(
            (serviceOption) => serviceOption.value === serviceId
          )
        )
      );
    }

    return [];
  }, [reportScope.serviceIds, servicesOptions]);

  const domainFilter = useMemo<Array<AutocompleteOption>>(() => {
    if (reportScope.domains?.length) {
      return _.compact(
        reportScope?.domains?.map((domain: string) => ({
          value: domain,
          label: domain,
        }))
      );
    }

    return [];
  }, [reportScope.domains]);

  const filters = useMemo<Array<any>>(() => {
    return [
      {
        type: 'Scope',
        values:
          scopeFilter?.length === 0
            ? [
                {
                  value: 'All',
                  label: 'All',
                },
              ]
            : scopeFilter,
      },
      {
        type: 'Group',
        values:
          groupFilter?.length === 0
            ? [
                {
                  value: 'All',
                  label: 'All',
                },
              ]
            : groupFilter,
      },
      {
        type: 'Service',
        values:
          contentGroupType === ReportRuleCreationType.SECURITY
            ? serviceFilter?.length === 0
              ? [
                  {
                    value: 'All',
                    label: 'All',
                  },
                ]
              : serviceFilter
            : [],
      },
      {
        type: 'Domain',
        values: domainFilter,
      },
    ];
  }, [scopeFilter, groupFilter, serviceFilter, domainFilter, contentGroupType]);

  const renderConfiguration = () => {
    return (
      <ul>
        {filters.map((filter: ReportRuleProperty) => {
          if (filter.values?.length > 0) {
            return (
              <li>
                {filter.type}:{' '}
                <b>{filter.values?.map((value) => value.label).join(',')}</b>
              </li>
            );
          } else {
            return <></>;
          }
        })}
        <li>
          Timeframe: <b>{timeframe}</b>
        </li>
      </ul>
    );
  };

  return (
    <div
      className={`dashboard-reports-description ${
        contentGroupType === ReportRuleCreationType.SECURITY
          ? 'dashboard-reports-risk-description'
          : ''
      }`}
    >
      <div className="dashboard-reports-description-title">
        {params.get('name')}
      </div>
      <div className="dashboard-reports-description-body">
        {params.get('description')}
      </div>
      <div className="dashboard-reports-description-configuration">
        {renderConfiguration()}
      </div>
    </div>
  );
};
