import { ReactNode, useMemo, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/material_blue.css';
import { Box, Link, Typography } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import _ from 'lodash';

type DatePickerProps = {
  label?: string;
  model: string;
  onChangeCallBack: (model: string, value: string[]) => void;
  initialSelectedValues?: string[];
  classes?: {
    label?: string;
    root?: string;
  };
  range?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  TitleAdnornment?: ReactNode;
};

export default function DatePicker({
  label,
  model,
  onChangeCallBack,
  initialSelectedValues = [],
  classes = {},
  range = true,
  minDate,
  maxDate,
  placeholder = '',
  TitleAdnornment,
}: DatePickerProps) {
  const onHandleChange = (dates: any) => {
    if (range && dates?.length === 2) {
      const dt1 = new Date(dates[0]).toISOString();
      const dt2 = new Date(dates[1]).toISOString();
      onChangeCallBack(model, [dt1, dt2]);
    } else if (!range && dates?.length === 1) {
      const date = new Date(dates[0]).toISOString();
      onChangeCallBack(model, [date]);
    }
  };
  const initialDates: [Date, Date] | [Date] | undefined = useMemo(() => {
    const compactSelectedValues = _.compact(initialSelectedValues);

    if (range && compactSelectedValues?.length === 2) {
      return [
        new Date(compactSelectedValues[0]),
        new Date(compactSelectedValues[1]),
      ];
    } else if (!range && compactSelectedValues?.length === 1) {
      return [new Date(compactSelectedValues[0])];
    }
  }, [initialSelectedValues]);

  const pickerRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onClickClear() {
    if (!pickerRef?.current?.flatpickr) return;
    pickerRef.current.flatpickr.clear();
    onChangeCallBack(model, []);
  }

  return (
    <Box className={`date-picker-container ${classes.root}`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        {label ? (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography
              className={classes.label}
              fontWeight={700}
              fontSize={16}
              color="#0A0B50"
            >
              {label}
            </Typography>

            {TitleAdnornment ? TitleAdnornment : <></>}
          </Box>
        ) : (
          <></>
        )}

        <Link onClick={onClickClear} className="date-picker-clear-text">
          <FormattedMessage
            capitalize
            id="common.clear"
            defaultMessage="Clear"
          />
        </Link>
      </Box>
      <div className="date-picker-input-container">
        <Flatpickr
          options={{
            minDate: minDate,
            maxDate: maxDate,
            defaultDate: initialDates,
            mode: range ? 'range' : 'single',
            dateFormat: 'D d M Y',
          }}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          ref={pickerRef}
          onChange={(dd: any) => onHandleChange(dd)}
          className="opus-date-field-1 date-picker-input"
          placeholder={placeholder}
        />
        <div
          className="date-picker-input-outline"
          onClick={() => {
            if (!isOpen) {
              pickerRef?.current?.flatpickr?.element?.click();
              pickerRef?.current?.flatpickr?.element?.focus();
            }
          }}
        ></div>
        <div className="date-picker-input-icon">
          <OpusSvgIcon
            type={
              isOpen
                ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
            }
          />
        </div>
      </div>
    </Box>
  );
}
DatePicker.defaultProps = {
  pickTime: () => {},
};
DatePicker.propTypes = {
  pickTime: PropTypes.func,
};
