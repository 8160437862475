import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import WidgetContainer from 'Common/components/Widgets/components/WidgetContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';

import WidgetBlock from './WidgetBlock';
import { insightsData } from 'Dashboard/interfaces/InsightTypes.enum';
import NoDataToDisplayCard from '../NoDataCard';
import useCommonSelector from 'Common/utils/use-selector';
import { selectdashboardFilter } from 'Dashboard/store';

interface TopInsightsWidgetProps
  extends BaseComponentProps,
    OrganizationalDataProps {}

export const TopInsightsWidget: FunctionComponent<
  TopInsightsWidgetProps
> = () => {
  const { t: translation } = useTranslation();
  const [hasChildren, setHasChildren] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const filter = useCommonSelector(selectdashboardFilter);

  useEffect(() => {
    const currentRef = containerRef.current;
    setHasChildren(true);

    if (!currentRef) return;

    const observer = new MutationObserver(() => {
      const children = currentRef.querySelectorAll('.insight-block');
      setHasChildren(children.length > 0);
    });

    observer.observe(currentRef, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [filter.businessUnitId]);

  const renderedWidgets = insightsData.map((insight, index) => (
    <WidgetBlock type={insight.type} key={index} />
  ));

  return (
    <Grid className="top-insights-widget dashboard-table-widget">
      <WidgetContainer
        title={translation(`dashboards.widgets.topInsights.title`)}
      >
        {hasChildren ? (
          <div className="insights-widget-container" ref={containerRef}>
            {renderedWidgets}
          </div>
        ) : (
          <div className="empty-widget-container">
            <NoDataToDisplayCard displayIcon displayDescription />
          </div>
        )}
      </WidgetContainer>
    </Grid>
  );
};

export default TopInsightsWidget;
