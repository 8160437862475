import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  OperationalMetricType,
  OperationalWidgetAnalyticsType,
} from 'shared/models/data/operational-widget-data.model';

export enum TimelineFilter {
  '7_DAYS' = 'Last 7 Days',
  '14_DAYS' = 'Last 14 Days',
  '30_DAYS' = 'Last 30 Days',
  '60_DAYS' = 'Last 60 Days',
}

export const operationalDashboardFilterOptions: Array<AutocompleteOption> = [
  {
    value: TimelineFilter['7_DAYS'],
    label: TimelineFilter['7_DAYS'],
  },
  {
    value: TimelineFilter['14_DAYS'],
    label: TimelineFilter['14_DAYS'],
  },
  {
    value: TimelineFilter['30_DAYS'],
    label: TimelineFilter['30_DAYS'],
  },
  {
    value: TimelineFilter['60_DAYS'],
    label: TimelineFilter['60_DAYS'],
  },
];

export const operationalDashboardTrendMetricsTypeOptions: Array<AutocompleteOption> =
  [
    {
      value: OperationalMetricType.NEW_FINDINGS,
      label: 'New Findings',
    },
    {
      value: OperationalMetricType.NEW_RESOLVED_FINDINGS,
      label: 'New Resolved Findings',
    },
    {
      value: OperationalMetricType.NEW_RESOLVED_WITHIN_SLA,
      label: 'New Resolved Within SLA',
    },
    {
      value: OperationalMetricType.NEW_RESOLVED_OUTSIDE_SLA,
      label: 'New Resolved Outside SLA',
    },
    {
      value: OperationalMetricType.NEW_EXCEPTION_APPROVED,
      label: 'New Exception Approved',
    },
    {
      value: OperationalMetricType.SLA_ATTAINMENT_RATE,
      label: 'SLA Attainment Rate',
    },
    {
      value: OperationalMetricType.TRENDING_MTTR,
      label: 'Trending MTTR',
    },
    {
      value: OperationalMetricType.NEW_FINDINGS_BREACHED_SLA,
      label: 'New Findings Breached SLA',
    },
    {
      value: OperationalMetricType.PRIORITIZED_CHURN,
      label: 'Prioritized Churn',
    },
    {
      value: OperationalMetricType.PRIORITIZED_RATIO,
      label: 'Prioritized Ratio',
    },
    {
      value: OperationalMetricType.TOTAL_ACTIVE_FINDINGS,
      label: 'Total Active Findings',
    },
    {
      value: OperationalMetricType.TOTAL_CRITICAL_FINDINGS,
      label: 'Total Critical Score Findings',
    },
    {
      value: OperationalMetricType.TOTAL_EXPLOITABLE_FINDINGS,
      label: 'Total Exploitable Findings',
    },
    {
      value: OperationalMetricType.TOTAL_ACTIVE_WITHIN_SLA,
      label: 'Total Active Findings Within SLA',
    },
    {
      value: OperationalMetricType.TOTAL_ACTIVE_OUTSIDE_SLA,
      label: 'Total Active Findings Outside SLA',
    },
    {
      value: OperationalMetricType.TOTAL_MTTR,
      label: 'Total MTTR',
    },
  ];
