import {
  GridCellCheckboxRenderer,
  GridColDef,
  GridHeaderCheckbox,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import ItemWithIcon from 'Common/components/ItemWithIcon';
import Status from 'Common/components/Status';
import RiskChip from 'Risk/components/RiskChip';
import { GridRowSelectionProps, RiskFilterItem, RiskFilters } from 'Risk/store';
import CommonIconCell from 'shared/components/CommonIconCell';
import CommonChip from 'shared/components/CommonChip';
import { ResourceCell } from 'shared/components/ResourceCell/ResourceCell';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import SlaChip from 'shared/components/SlaChip';
import moment from 'moment';
import AvatarChip from 'shared/components/AvatarChip';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CommonDataGridUtilityCell } from 'shared/components/CommonSimpleDataGrid/enums/common-data-grid.enum';
import { RiskLifecycleCell } from 'Risk/components/RiskLifecycleCell/RiskLifecycleCell';
import { VisibilityControlColumn } from 'shared/components/CommonSimpleDataGrid/CommonSimpleDataGrid';
import { CommonAdvancedDataGridRowCheckbox } from 'shared/components/CommonAdvancedDataGridRowCheckbox/CommonAdvancedDataGridRowCheckbox';
import { MenuItems } from 'DashboardFilter/store';
import { FavouritesFilterItem } from 'shared/components/CommonFavouritesFilter/CommonFavouritesFilter';
import { FindingImmutableStatus } from 'FindingDetails/interfaces/status';
import ImpactScoreChip from 'shared/components/ImpactScoreChip';
import { Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { OrderType } from 'Common/utils/sort';
import { AdvanceFilterGetter } from 'shared/hooks/useGetAdvanceFilterGetter';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import {
  FilterOption as DataFilterOption,
  FilterOperator,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { RiskFindingDetailsHandler } from 'shared/handlers/risk-finding-details.handler';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { useSearchParams } from 'react-router-dom';
import {
  ExtendedFilterCategory,
  FilterType,
} from 'shared/models/data/data-filter.model';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import { capitalize } from 'lodash';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import LabelCell from 'shared/components/LabelCell';
import { useSearchAssetsMutation } from 'Assets/store/api';
import { dateTimeFormat } from 'shared/services/date.service';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import SeverityBreakdown from 'shared/components/SeverityBreakdown/SeverityBreakdown';
import {
  SsvcDecision,
  SsvcPublicWellbeingImpact,
} from 'FindingDetails/interfaces/SSVCTypes.enum';

const riskFindingDetailsHandler = new RiskFindingDetailsHandler();

const dataFilterHandler = new DataFitlerHandler();

const advancedFilterHandler = new AdvanceFilterHandler();

export const labelGridColumn: ColDef | ColGroupDef = {
  field: 'labels',
  headerName: 'Labels',
  headerClass: 'column-disable-sort',
  initialWidth: 250,
  cellRenderer: (params: any) => {
    const labels = params.data?.labels || [];
    return <LabelCell labels={labels} />;
  },
  sortable: false,
};

export const labelFilterCategory: ExtendedFilterCategory = {
  id: 'labels',
  label: 'Labels',
  type: FilterType.MULTI_SELECT,
  state: {
    sourceType: OptionSourceType.API_STATIC,
    source: {
      url: 'labels/filters/options?fieldName=label&orderBy=label&orderType=ASC&skip=0&take=10000',
      method: 'GET',
    },
  },
  operator: FilterOperator.OVERLAPS,
};

export const getRiskSearchParamsRequestDependencies = (
  searchParams: RiskFilters
): Array<string> => {
  const categories = advancedFilterHandler.extractAllCategories(
    riskViewFilterCategories
  );

  return Object.keys(searchParams)?.length
    ? Object.keys(searchParams).map((searchParamKey) => {
        const category = categories.find(
          (categoryItem: ExtendedFilterCategory) =>
            categoryItem.id === searchParamKey
        );

        if (category?.operator === FilterOperator.OVERLAPS) {
          const categoryState = searchParams[searchParamKey] as any;

          if (categoryState && Object.keys(categoryState).length) {
            return (
              categoryState.values?.join('') || categoryState.value?.join('')
            );
          }
          return '';
        }

        if (category?.type === FilterType.KEY_VALUE) {
          const categoryState = searchParams[searchParamKey] as any;

          if (categoryState && Object.keys(categoryState).length) {
            return categoryState.value
              ?.map(
                (selection: any) =>
                  `${selection.key}${selection.values?.join('')}`
              )
              .join('');
          }
          return '';
        }

        if (Array.isArray(searchParams[searchParamKey])) {
          if (category?.type === FilterType.DATE_RANGE) {
            const searchParamList = searchParams[
              searchParamKey
            ] as any as Array<Date>;

            return (
              searchParamList[0]?.toString() + searchParamList[1]?.toString()
            );
          }
          const searchParamList = searchParams[
            searchParamKey
          ] as Array<RiskFilterItem>;

          return (
            searchParamList[searchParamList.length - 1].value +
            searchParamList.length
          );
        }

        const searchParamItem = searchParams[searchParamKey] as any;

        if (category?.type === FilterType.NUMBER_RANGE) {
          return String(
            searchParamItem.value?.start + '_' + searchParamItem.value?.end
          );
        }

        return searchParamItem?.value;
      })
    : [];
};

export enum SlaStatus {
  WITHIN = 'within',
  WARN = 'warn',
  BREACHED = 'breached',
  NO_SLA = 'no-sla',
}

export const riskGroupNoneOption: FilterOption = {
  id: StaticFilterOptions.NONE,
  name: StaticFilterOptions.NONE,
};

export const unassignedFilterOption: FilterOption = {
  id: 'Unassigned',
  name: 'Unassigned',
};

export enum FindingState {
  ACTIVE = 'Active',
  IN_ACTIVE = 'Inactive',
}

export const riskScoreItems = [
  {
    id: '0-3',
    name: '0-3',
  },
  {
    id: '3-6',
    name: '3-6',
  },
  {
    id: '6-10',
    name: '6-10',
  },
];

export const intelligenceScoreItems = [
  {
    id: '0-0.1',
    name: '0-0.1',
  },
  {
    id: '0.1-0.2',
    name: '0.1-0.2',
  },
  {
    id: '0.2-0.3',
    name: '0.2-0.3',
  },
  {
    id: '0.3-0.4',
    name: '0.3-0.4',
  },
  {
    id: '0.4-0.5',
    name: '0.4-0.5',
  },
  {
    id: '0.5-0.6',
    name: '0.5-0.6',
  },
  {
    id: '0.6-0.7',
    name: '0.6-0.7',
  },
  {
    id: '0.7-0.8',
    name: '0.7-0.8',
  },
  {
    id: '0.8-0.9',
    name: '0.8-0.9',
  },
  {
    id: '0.9-1.0',
    name: '0.9-1.0',
  },
];

export const booleanRiskFilterItems = [
  {
    id: true,
    name: 'Yes',
  },
  {
    id: false,
    name: 'No',
  },
];

export const riskScoreOptions: Array<DataFilterOption> = [
  {
    value: '0-3',
    label: '0-3',
  },
  {
    value: '3-6',
    label: '3-6',
  },
  {
    value: '6-10',
    label: '6-10',
  },
];

export const intelligenceScoreOptions: Array<DataFilterOption> =
  riskScoreOptions;

export const epssScoreOptions: Array<DataFilterOption> = [
  {
    value: '0-0.1',
    label: '0-0.1',
  },
  {
    value: '0.1-0.2',
    label: '0.1-0.2',
  },
  {
    value: '0.2-0.3',
    label: '0.2-0.3',
  },
  {
    value: '0.3-0.4',
    label: '0.3-0.4',
  },
  {
    value: '0.4-0.5',
    label: '0.4-0.5',
  },
  {
    value: '0.5-0.6',
    label: '0.5-0.6',
  },
  {
    value: '0.6-0.7',
    label: '0.6-0.7',
  },
  {
    value: '0.7-0.8',
    label: '0.7-0.8',
  },
  {
    value: '0.8-0.9',
    label: '0.8-0.9',
  },
  {
    value: '0.9-1.0',
    label: '0.9-1.0',
  },
];

export const epssPercentileOptions: Array<DataFilterOption> = epssScoreOptions;

export const booleanRiskFilterOptions: Array<DataFilterOption> = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const ResourceGroupGridActionsCell = ({
  resourceId,
}: {
  resourceId: string;
}) => {
  const externalResourceId = resourceId.replace(':parent', '');

  const [
    searchAssets,
    { data: assetData, isLoading: assetDataLoading, reset: resetAssetData },
  ] = useSearchAssetsMutation();

  const [, setReactiveSearchParams] = useSearchParams();
  const [, , getUrlSearchParams] = useQueryParams();

  useEffect(() => {
    if (assetData) {
      const uniqueAssetId = assetData.data[0]?.assetUniqueId;
      uniqueAssetId && navigateToResourceDrawer(uniqueAssetId);
    }
  }, [assetData]);

  const navigateToResourceDrawer = (uniqueResourceId: string) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );
    updatedReactiveSearchParams.set('openResourceId', uniqueResourceId);
    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const onSearchAssets = () => {
    searchAssets({
      filter: {
        externalResourceId: { value: externalResourceId },
      },
    });
  };

  return (
    <div className="resource-group-grid-actions-cell ignore-row-click">
      <Button onClick={onSearchAssets}>
        {assetDataLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            Open
            <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_RIGHT_ICON} />
          </>
        )}
      </Button>
    </div>
  );
};

export const lastTimelineEventColumn: ColDef = {
  field: 'lastTimelineEvent',
  headerName: 'Last Activity',
  headerClass: 'column-disable-sort',
  width: 250,
  sortable: false,
  hide: true,
  cellRenderer: (params: any) => {
    return params.data?.lastTimelineEvent ? (
      <CommonIconCell
        maxWidth={140}
        iconUrl={
          params?.data?.lastTimelineEvent?.actorType === 1
            ? params?.data?.lastTimelineEvent?.actorLogo
            : params?.data?.lastTimelineEvent?.appLogo
        }
        label={params?.data?.lastTimelineEvent?.message}
        iconClass={
          params?.data?.lastTimelineEvent?.actorType === 1
            ? 'risk-grid-last-activity-avatar-icon'
            : 'risk-grid-last-activity-app-icon'
        }
      />
    ) : (
      <div>-</div>
    );
  },
};

export const lastTimelineEventVisibilityConfiguration: VisibilityControlColumn =
  {
    value: 'lastTimelineEvent',
    label: 'Last Activity',
  };

export const getAllColumnDefs = (
  previousColumnDefs: Array<ColDef>,
  currentColumnDefs: Array<ColDef>
) => {
  const missingColumnDefs = previousColumnDefs.filter((columnDef) => {
    return !Boolean(
      currentColumnDefs.find(
        (currentColumnDef) => currentColumnDef.field === columnDef.field
      )
    );
  });

  const updatedCurrentColumnDefs = currentColumnDefs.map((currentColumnDef) => {
    const previousColumnDef = previousColumnDefs.find(
      (colDef) => colDef.field === currentColumnDef.field
    );

    return {
      ...previousColumnDef,
      hide: currentColumnDef.hide,
    };
  });

  return [...updatedCurrentColumnDefs, ...missingColumnDefs];
};

export const vulnerabilityRiskFilters: Array<string> = [
  'isDiscussed',
  'hasPOC',
  'hasExploit',
  'isExploitedInTheWild',
  'hasPenTesterFramework',
  'cisaKev',
  'hasRansomware',
  'hasThreatActor',
  'isTrending',
  'hasMalware',
  'hasPatch',
  'isPrioritized',
  'intelligenceScore',
  'epssScore',
  'epssPercentile',
  'attackComplexity',
];

export const excludedRiskFilters: Array<string> = [
  ...vulnerabilityRiskFilters,
  'tags',
  'labels',
];

export const riskGroupFilterItems: MenuItems = [
  {
    id: 'organization',
    name: 'Organization',
    nested: true,
    items: [],
    filterItems: [
      {
        id: 'scopeId',
        name: 'Scope',
        items: [],
        type: TypeFilter.SINGLE_SELECT,
        bodyParameters: {
          nodesType: 'scope',
        },
        optionGetterId: AdvanceFilterGetter.SCOPE,
        resetFields: ['groupId'],
      },
      {
        id: 'groupId',
        name: 'Group',
        items: [],
        type: TypeFilter.MULTI_SELECT,
        bodyParameters: {
          nodesType: 'logical',
        },
        optionGetterId: AdvanceFilterGetter.GROUP,
        getAdditionalBodyParameters: (selection: any) => ({
          startNodeId: selection.id,
        }),
      },
    ],
  },
  {
    id: 'vulnerability',
    name: 'Vulnerability',
    nested: true,
    items: [],
    filterItems: [
      {
        id: 'isDiscussed',
        name: 'Is Discussed',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasPOC',
        name: 'POC',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasExploit',
        name: 'Has Exploit',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'isExploitedInTheWild',
        name: 'In the Wild',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasPenTesterFramework',
        name: 'Pentest Framework',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'cisaKev',
        name: 'Cisa Kev',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasRansomware',
        name: 'Ransomware',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasThreatActor',
        name: 'Threat Actors',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'isTrending',
        name: 'Trending',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasMalware',
        name: 'Malware',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'hasPatch',
        name: 'Has Patch',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'isPrioritized',
        name: 'Prioritized',
        type: TypeFilter.SINGLE_SELECT,
        items: booleanRiskFilterItems,
        loaded: true,
      },
      {
        id: 'intelligenceScore',
        name: 'Intelligence Score',
        type: TypeFilter.MULTI_SELECT,
        items: riskScoreItems,
        loaded: true,
      },
      {
        id: 'epssScore',
        name: 'EPSS Score',
        type: TypeFilter.MULTI_SELECT,
        items: intelligenceScoreItems,
        loaded: true,
      },
      {
        id: 'epssPercentile',
        name: 'EPSS Percentile',
        type: TypeFilter.MULTI_SELECT,
        items: intelligenceScoreItems,
        loaded: true,
      },
    ],
  },
  {
    id: 'findingType',
    name: 'Domain',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'resourceType',
    name: 'Resource Type',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'operatingSystem',
    name: 'Operating System',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'riskScore',
    name: 'Risk Score',
    type: TypeFilter.MULTI_SELECT,
    items: riskScoreItems,
    loaded: true,
  },
  {
    id: 'category',
    name: 'Risk Category',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'businessUnitId',
    name: 'Service',
    type: TypeFilter.MULTI_SELECT,
    items: [riskGroupNoneOption],
    keepExistingOptions: true,
  },
  {
    id: `workspace`,
    name: 'Workspace',
    type: TypeFilter.MULTI_SELECT,
    items: [riskGroupNoneOption],
    keepExistingOptions: true,
  },
  {
    id: 'findingSource',
    name: 'Event Source',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'severity',
    name: 'Severity',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'status',
    name: 'Status',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'externalCreatedAt',
    name: 'First Seen',
    type: TypeFilter.DATE_PICKER_RANGE,
    items: [],
  },
  {
    id: 'itemRelationMetadata',
    name: 'Ticket',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'isExternalFacing',
    name: 'External Facing Resource',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'hasSensitiveData',
    name: 'Resource with Sensitive Data',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'reopened',
    name: 'Reopened',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'ownerUserId',
    name: 'Resource Owner',
    type: TypeFilter.MULTI_SELECT,
    items: [riskGroupNoneOption],
    keepExistingOptions: true,
  },
  {
    id: 'assigneeUserId',
    type: TypeFilter.MULTI_SELECT,
    name: 'Assignee',
    items: [unassignedFilterOption],
    keepExistingOptions: true,
  },
  {
    id: 'slaStatus',
    type: TypeFilter.SINGLE_SELECT,
    name: 'SLA Status',
    items: [],
  },
  {
    id: 'state',
    type: TypeFilter.SINGLE_SELECT,
    name: 'State',
    loaded: true,
    items: [
      {
        id: FindingState.ACTIVE,
        name: FindingState.ACTIVE,
      },
      {
        id: FindingState.IN_ACTIVE,
        name: FindingState.IN_ACTIVE,
      },
    ],
  },
  {
    id: 'duplicationState',
    name: 'Duplication State',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: DuplicationState.PRIMARY,
        name: 'Duplicated',
      },
      {
        id: DuplicationState.SINGLE,
        name: 'Single',
      },
    ],
    loaded: true,
  },
];

export const riskGridQuickFilters: Array<FavouritesFilterItem> = [
  {
    id: 'approved_exceptions',
    name: 'Findings with Approved Exceptions',
    options: [
      {
        type: 'status',
        values: [
          {
            id: FindingImmutableStatus.EXCEPTION,
            name: FindingImmutableStatus.EXCEPTION,
          },
        ],
      },
    ],
  },
  {
    id: 'pending_exceptions',
    name: 'Findings with Pending Exceptions',
    options: [
      {
        type: 'status',
        values: [
          {
            id: FindingImmutableStatus.PENDING_EXCEPTION,
            name: FindingImmutableStatus.PENDING_EXCEPTION,
          },
        ],
      },
    ],
  },
];

export function createRiskGroupGridColumns(props: any): Array<GridColDef> {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        const activeRowItem: GridRowSelectionProps =
          props.selectionProps.rows?.find(
            (rowItem: GridRowSelectionProps) => rowItem.value === params.id
          );

        const activateIndeterminate =
          activeRowItem?.totalSelectedChildren > 0 &&
          activeRowItem?.totalSelectedChildren !==
            activeRowItem?.totalAvailableChildren;

        return (
          <GridCellCheckboxRenderer
            {...params}
            // @ts-ignore
            indeterminate={activateIndeterminate}
          />
        );
      },
      renderHeader: (params) => {
        return <GridHeaderCheckbox {...params} />;
      },
    },
    {
      field: 'sub-table-toggle',
      width: 50,
      headerName: '',
      sortable: false,
    },
    {
      field: 'findingTitle',
      headerName: 'Risk Name',
      width: 700,
      renderCell: (params) => {
        return (
          <TextOverflow
            direction={OverflowDirection.WIDTH}
            type={OverflowTextDisplay.ELLIPSIS}
          >
            <>
              {params.row?.findingTitle}
              <span className="risk-group-grid-risk-name-cell-counter">{`(${params.row?.totalCountForAggregatedGroup})`}</span>
            </>
          </TextOverflow>
        );
      },
    },
    {
      field: 'category',
      headerName: 'Risk Category',
      width: 230,
      renderCell: (params) => {
        return (
          params?.row?.category && (
            <CommonIconCell
              iconUrl=""
              label={params.row?.category}
              maxWidth={130}
            />
          )
        );
      },
    },
    {
      field: 'maxRiskScore',
      headerName: 'Risk Score',
      width: 120,
      renderCell: (params) => {
        return (
          <div>
            <RiskChip riskScore={params.row?.maxRiskScore} />
          </div>
        );
      },
    },
    {
      field: 'totalCountForAggregatedGroup',
      headerName: 'Findings',
      width: 110,
      renderCell: (params) => {
        return (
          <div className="risk-group-grid-cloud-resources-cell">
            {params.row?.totalCountForAggregatedGroup}
          </div>
        );
      },
    },
    {
      field: 'rootCauseCount',
      headerName: 'Root Causes',
      width: 120,
      renderCell: (params) => {
        return (
          <div className="risk-group-grid-root-causes-cell">
            {params.row?.rootCauseCount}
          </div>
        );
      },
    },
  ];
}

export const riskGroupHiddenColumns: Array<GridColDef> = [
  {
    field: 'assignee',
    headerName: 'Assignee',
    width: 120,
  },
  {
    field: 'eventSource',
    headerName: 'Event Source',
    width: 120,
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 120,
  },
  {
    field: 'slaStatus',
    headerName: 'SLA Status',
    width: 120,
  },
];

export const riskGroupColumnFields: Array<string> = [
  'findingTitle',
  'category',
  'maxRiskScore',
  'totalCountForAggregatedGroup',
  'rootCauseCount',
];

export function createRiskGridColumns(props: any): Array<GridColDef> {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        return (
          <GridCellCheckboxRenderer
            {...params}
            // @ts-ignore
            onClick={() => {
              props.onHandleRowSelection(
                params.row?.findingId,
                params.row?.type,
                props.selectionProps
              );
            }}
          />
        );
      },
      renderHeader: (params) => {
        return <GridHeaderCheckbox {...params} />;
      },
    },
    {
      field: 'resourceName',
      headerName: 'Resource Name',
      width: 280,
      renderCell: (params) => {
        return (
          <ResourceCell
            maxWidth={220}
            type={params.row?.resourceType}
            name={params?.row?.resourceName}
            iconUrl={params?.row?.resourceTypeLogoUrl}
          />
        );
      },
    },
    {
      field: 'riskScore',
      headerName: 'Risk Score',
      width: 120,
      renderCell: (params) => {
        return (
          <div>
            <RiskChip riskScore={params.row?.riskScore} />
          </div>
        );
      },
    },
    {
      field: 'findingSource',
      headerName: 'Event Source',
      width: 150,
      renderCell: (params) => {
        return (
          <CommonIconCell
            maxWidth={140}
            iconUrl={params?.row?.eventSourceLogoUrl}
            label={params?.row?.findingSource}
          />
        );
      },
    },
    {
      field: 'businessUnitName',
      headerName: 'Service',
      width: 150,
      renderCell: (params) => {
        return params?.row.businessUnitName ? (
          <CommonChip label={params?.row?.businessUnitName} />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'ticketId',
      headerName: 'Ticket',
      width: 150,
      renderCell: (params) => {
        return params?.row.itemRelationMetadata ? (
          <CommonIconCell
            iconUrl={params?.row.itemRelationMetadata?.appLogo}
            label={params?.row?.itemRelationMetadata?.itemId || '-'}
            linkUrl={params?.row?.itemRelationMetadata?.link}
            maxWidth={120}
          />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'rootCauseFilePath',
      headerName: 'Root Cause',
      width: 230,
      renderCell: (params) => {
        return params?.row?.rootCauseMetadata ? (
          <ItemWithIcon
            maxWidth={250}
            icon={
              <img src={params?.row?.rootCauseMetadata?.appLogo} height={16} />
            }
            description={params?.row?.rootCauseMetadata?.repoLink}
            title={params?.row?.rootCauseMetadata?.filePath}
            missingTitleKey={''}
          />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => {
        return <Status label={params.row?.status} readonly />;
      },
    },
    {
      field: 'externalCreatedAt',
      headerName: 'First Seen',
      width: 200,
      renderCell: (params) => {
        return moment(params.row?.externalCreatedAt).format(dateTimeFormat);
      },
    },
  ];
}

export const riskHiddenColumns: Array<GridColDef> = [
  {
    field: 'assigneeName',
    headerName: 'Assignee',
    width: 150,
    renderCell: (params) => {
      return (
        <AvatarChip
          avatarUrl={params.row?.assigneeProfilePicture}
          label={params.row?.assigneeName}
        />
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 120,
    renderCell: (params) => {
      return <CommonSeverityChip severityNumber={params.row?.severity} />;
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    width: 100,
    renderCell: (params) => {
      return <SlaChip isUtc slaDate={params.row?.slaBreachDate} />;
    },
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    width: 230,
    renderCell: (params) => {
      return params?.row?.cloudWorkspace ? (
        <ItemWithIcon
          maxWidth={220}
          icon={
            <img
              src={params?.row?.cloudWorkspace?.cloudProviderLogoUrl}
              height={16}
            />
          }
          description={params?.row?.cloudWorkspace?.externalId}
          title={params?.row?.cloudWorkspace?.workspaceName}
          missingTitleKey={''}
          displayIconOutline={false}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    width: 250,
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    width: 200,
    renderCell: (params) => {
      return moment(params.row?.updatedDate).format(dateTimeFormat);
    },
  },
];

export function createFindingRiskGridColumns(props: any): Array<GridColDef> {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        return <GridCellCheckboxRenderer {...params} />;
      },
      renderHeader: (params) => {
        return <GridHeaderCheckbox {...params} />;
      },
    },
    {
      field: 'title',
      headerName: 'Risk Name',
      width: 300,
      renderCell: (params) => {
        return (
          <TextOverflow
            direction={OverflowDirection.WIDTH}
            type={OverflowTextDisplay.ELLIPSIS}
          >
            {params?.row?.title}
          </TextOverflow>
        );
      },
    },
    {
      field: 'riskScore',
      headerName: 'Risk Score',
      width: 120,
      renderCell: (params) => {
        return <RiskChip riskScore={params?.row?.riskScore} />;
      },
    },
    {
      field: 'findingSource',
      headerName: 'Event Source',
      width: 150,
      renderCell: (params) => {
        return (
          <CommonIconCell
            maxWidth={140}
            iconUrl={params?.row?.sourceAppLogoUrl}
            label={params?.row?.findingSource}
          />
        );
      },
    },
    {
      field: 'resourceName',
      headerName: 'Resource Name',
      width: 230,
      renderCell: (params) => {
        return (
          params.row?.resourceType && (
            <ResourceCell
              type={params.row?.resourceType}
              name={params?.row?.resourceName}
              iconUrl={params?.row?.resourceTypeLogo}
            />
          )
        );
      },
    },
    {
      field: 'businessUnitName',
      headerName: 'Service',
      width: 150,
      renderCell: (params) => {
        return params?.row?.businessUnitName ? (
          <CommonChip label={params?.row?.businessUnitName} />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'rootCauseFilePath',
      headerName: 'Root Cause',
      width: 230,
      renderCell: (params) => {
        return params?.row?.rootCauseMetadata ? (
          <ItemWithIcon
            maxWidth={250}
            icon={
              <img src={params?.row?.rootCauseMetadata?.appLogo} height={16} />
            }
            description={params?.row?.rootCauseMetadata?.repoLink}
            title={params?.row?.rootCauseMetadata?.filePath}
            missingTitleKey={''}
          />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => {
        return (
          <div>
            <Status label={params.row?.status} readonly />
          </div>
        );
      },
    },
    {
      field: 'externalCreatedAt',
      headerName: 'First Seen',
      width: 200,
      renderCell: (params) => {
        return moment(params.row?.externalCreatedAt).format(dateTimeFormat);
      },
    },
    {
      field: 'cloudWorkspaceName',
      headerName: 'Workspace',
      width: 230,
      renderCell: (params) => {
        return (
          params?.row?.cloudWorkspace && (
            <ItemWithIcon
              maxWidth={220}
              icon={
                <img
                  src={params?.row?.cloudWorkspace?.cloudProviderLogoUrl}
                  height={16}
                />
              }
              description={params?.row?.cloudWorkspace?.externalId}
              title={params?.row?.cloudWorkspace?.workspaceName}
              missingTitleKey={''}
              displayIconOutline={false}
            />
          )
        );
      },
    },
  ];
}

export const findingRiskGridHiddenColumns: Array<GridColDef> = [
  {
    field: 'category',
    headerName: 'Risk Category',
    width: 200,
    renderCell: (params) => {
      return <CommonIconCell label={params?.row?.category} maxWidth={140} />;
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 100,
    renderCell: (params) => {
      return <CommonSeverityChip severityNumber={params.row?.severity} />;
    },
  },
  {
    field: 'itemRelationMetadataItemId',
    headerName: 'Ticket',
    width: 150,
    renderCell: (params) => {
      return params?.row.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.row.itemRelationMetadata?.appLogo}
          label={params?.row?.itemRelationMetadata?.itemId}
          linkUrl={params?.row?.itemRelationMetadata?.link}
          maxWidth={120}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    width: 250,
    renderCell: (params) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.row?.ownerUserId || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'assigneeUserId',
    headerName: 'Assignee',
    width: 150,
    renderCell: (params) => {
      return (
        <AvatarChip
          avatarUrl={params.row?.assigneeProfilePicture}
          label={params.row?.assigneeUserName}
        />
      );
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    width: 100,
    renderCell: (params) => {
      return <SlaChip isUtc slaDate={params.row?.slaBreachDate} />;
    },
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    width: 200,
    renderCell: (params) => {
      return moment(params.row?.updatedDate).format(dateTimeFormat);
    },
  },
];

export const findingRiskGridColumnFields: Array<string> = [
  'title',
  'riskScore',
  'resourceName',
  'businessUnitName',
  'rootCause',
  'status',
  'externalCreatedAt',
  'category',
  'findingSource',
  'severity',
  'itemRelationMetadata',
  'ownerUserId',
  'assigneeUserId',
];
export function createRiskGroupByResourceNameGridColumns(
  props: any
): Array<GridColDef> {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        const activeRowItem: GridRowSelectionProps =
          props.selectionProps.rows?.find(
            (rowItem: GridRowSelectionProps) => rowItem.value === params.id
          );

        const activateIndeterminate =
          activeRowItem?.totalSelectedChildren > 0 &&
          activeRowItem?.totalSelectedChildren !==
            activeRowItem?.totalAvailableChildren;

        return (
          <GridCellCheckboxRenderer
            {...params}
            // @ts-ignore
            indeterminate={activateIndeterminate}
          />
        );
      },
      renderHeader: (params) => {
        return <GridHeaderCheckbox {...params} />;
      },
    },
    {
      field: 'sub-table-toggle',
      width: 50,
      headerName: '',
      sortable: false,
    },
    {
      field: 'resourceName',
      headerName: 'Resource Name',
      width: 430,
      renderCell: (params) => {
        return (
          <ResourceCell
            type={params.row?.resourceType}
            name={params?.row?.aggregatedValue}
            iconUrl={params?.row?.resourceTypeLogoUrl}
          />
        );
      },
    },
    {
      field: 'maxRiskScore',
      headerName: 'Risk Score',
      width: 120,
      renderCell: (params) => {
        return (
          <div>
            <RiskChip riskScore={params.row?.maxRiskScore} />
          </div>
        );
      },
    },
    {
      field: 'businessUnitName',
      headerName: 'Service',
      width: 150,
      renderCell: (params) => {
        return params?.row.businessUnitName ? (
          <CommonChip label={params?.row?.businessUnitName} />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'totalCountForAggregatedGroup',
      headerName: '# Findings',
      width: 210,
      renderCell: (params) => {
        return (
          <div className="risk-group-grid-cloud-resources-cell">
            {params?.value}
          </div>
        );
      },
    },
    {
      field: 'rootCauseCount',
      headerName: 'Root Causes',
      width: 120,
      renderCell: (params) => {
        return (
          <div className="risk-group-grid-root-causes-cell">
            {params.row?.rootCauseCount}
          </div>
        );
      },
    },
  ];
}

export function createFindingRiskByResourceNameGridColumns(
  props: any
): Array<GridColDef> {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        return (
          <GridCellCheckboxRenderer
            {...params}
            // @ts-ignore
            onClick={() => {
              props.onHandleRowSelection(
                params.row?.findingId,
                params.row?.resourceName,
                props.selectionProps
              );
            }}
          />
        );
      },
      renderHeader: (params) => {
        return <GridHeaderCheckbox {...params} />;
      },
    },
    {
      field: 'title',
      headerName: 'Risk Name',
      width: 400,
      renderCell: (params) => {
        return (
          <TextOverflow
            direction={OverflowDirection.WIDTH}
            type={OverflowTextDisplay.ELLIPSIS}
          >
            {params?.value}
          </TextOverflow>
        );
      },
    },
    {
      field: 'riskScore',
      headerName: 'Score',
      width: 120,
      renderCell: (params) => {
        return <RiskChip riskScore={params?.value} />;
      },
    },
    {
      field: 'category',
      headerName: 'Risk Category',
      width: 240,
      renderCell: (params) => {
        return (
          params?.row?.category && (
            <CommonIconCell
              iconUrl=""
              label={params.row?.category}
              maxWidth={230}
            />
          )
        );
      },
    },
    {
      field: 'ticketId',
      headerName: 'Ticket',
      width: 140,
      renderCell: (params) => {
        return params?.row.itemRelationMetadata ? (
          <CommonIconCell
            iconUrl={params?.row.itemRelationMetadata?.appLogo}
            label={params?.row?.itemRelationMetadata?.itemId || '-'}
            linkUrl={params?.row?.itemRelationMetadata?.link}
            maxWidth={130}
          />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'rootCauseFilePath',
      headerName: 'Root Cause',
      width: 250,
      renderCell: (params) => {
        return params?.row?.rootCauseMetadata ? (
          <ItemWithIcon
            maxWidth={240}
            icon={
              <img src={params?.row?.rootCauseMetadata?.appLogo} height={16} />
            }
            description={params?.row?.rootCauseMetadata?.repoLink}
            title={params?.row?.rootCauseMetadata?.filePath}
            missingTitleKey={''}
          />
        ) : (
          <div>-</div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return <Status label={params.row?.status} readonly />;
      },
    },
    {
      field: 'externalCreatedAt',
      headerName: 'First Seen',
      width: 220,
      renderCell: (params) => {
        return moment(params.row?.externalCreatedAt).format(dateTimeFormat);
      },
    },
  ];
}
export const hiddenFindingRiskByResourceNameGridColumns: Array<GridColDef> = [
  {
    field: 'assigneeName',
    headerName: 'Assignee',
    width: 150,
    renderCell: (params) => {
      return (
        <AvatarChip
          avatarUrl={params.row?.assigneeProfilePicture}
          label={params.row?.assigneeName}
        />
      );
    },
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    width: 150,
    renderCell: (params) => {
      return (
        <CommonIconCell
          iconUrl={params?.row?.eventSourceLogoUrl}
          label={params?.row?.findingSource}
          maxWidth={140}
        />
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 100,
    renderCell: (params) => {
      return <CommonSeverityChip severityNumber={params.row?.severity} />;
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    width: 100,
    renderCell: (params) => {
      return <SlaChip isUtc slaDate={params.row?.slaBreachDate} />;
    },
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    width: 230,
    renderCell: (params) => {
      return (
        params?.row?.cloudWorkspace && (
          <ItemWithIcon
            maxWidth={220}
            icon={
              <img
                src={params?.row?.cloudWorkspace?.cloudProviderLogoUrl}
                height={16}
              />
            }
            description={params?.row?.cloudWorkspace?.externalId}
            title={params?.row?.cloudWorkspace?.workspaceName}
            missingTitleKey={''}
            displayIconOutline={false}
          />
        )
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    width: 150,
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    width: 200,
    renderCell: (params) => {
      return moment(params.row?.updatedDate).format(dateTimeFormat);
    },
  },
];

export const resourceGroupGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
    cellRenderer: CommonAdvancedDataGridRowCheckbox,
  },
  {
    field: CommonDataGridUtilityCell.DATA_GRID_EXPAND_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    width: 10,
    cellRenderer: (params: any) => {
      const isExpanded = params.api.getRowNode(
        `${params.data.originalId}:nested`
      )?.expanded;

      return (
        <div className="advanced-data-grid-sub-table-toggle">
          <OpusSvgIcon
            type={
              isExpanded
                ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
            }
          />
        </div>
      );
    },
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    width: 330,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogoUrl}
        />
      );
    },
  },
  {
    field: 'aggregatedValue',
    headerName: 'Resource ID',
    width: 430,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {params?.data?.originalId}
        </TextOverflow>
      );
    },
  },
  {
    field: 'maxRiskScore',
    headerName: 'Risk Score',
    width: 120,
    cellRenderer: (params: any) => {
      return (
        <div>
          <RiskChip riskScore={params.data?.maxRiskScore} />
        </div>
      );
    },
  },
  {
    field: 'impactScore',
    headerName: 'Impact Score',
    cellRenderer: (params: any) => {
      return (
        <div>
          <ImpactScoreChip
            value={+Number(params.data?.impactScore).toFixed(2)}
          />
        </div>
      );
    },
    width: 140,
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    width: 150,
    cellRenderer: (params: any) => {
      return params?.data.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'totalCountForAggregatedGroup',
    headerName: '# Findings',
    width: 210,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-cloud-resources-cell">
          {params?.data?.totalCountForAggregatedGroup}
        </div>
      );
    },
  },
  {
    field: 'rootCauseCount',
    headerName: 'Root Causes',
    width: 200,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-root-causes-cell">
          {params.data?.rootCauseCount}
        </div>
      );
    },
  },
  {
    field: 'assetView',
    headerName: 'Resource View',
    width: 150,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'column-disable-sort resource-asset-view-column',
    cellRenderer: (params: any) => {
      return (
        <ResourceGroupGridActionsCell
          resourceId={params.data.aggregatedValue}
        />
      );
    },
  },
];

export const resourceGroupNestedColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },
  {
    field: 'title',
    headerName: 'Risk Name',
    width: 400,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Score',
    width: 120,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
  },
  {
    field: 'category',
    headerName: 'Risk Category',
    width: 240,
    cellRenderer: (params: any) => {
      return (
        params?.data?.category && (
          <CommonIconCell
            iconUrl=""
            label={params.data?.category}
            maxWidth={230}
          />
        )
      );
    },
  },
  {
    field: 'itemRelationMetadataItemId',
    headerName: 'Ticket',
    width: 150,
    cellRenderer: (params: any) => {
      return params?.data.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.data.itemRelationMetadata?.appLogo}
          label={params?.data?.itemRelationMetadata?.itemId}
          linkUrl={params?.data?.itemRelationMetadata?.link}
          maxWidth={130}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    width: 250,
    cellRenderer: (params: any) => {
      return params?.data?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={240}
          icon={
            <img src={params?.data?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.data?.rootCauseMetadata?.repoLink}
          title={params?.data?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    width: 220,
    cellRenderer: (params: any) => {
      return moment(params.data?.externalCreatedAt).format(dateTimeFormat);
    },
  },
  {
    field: 'assigneeUserName',
    headerName: 'Assignee',
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <AvatarChip
          avatarUrl={params.data?.assigneeProfilePicture}
          label={params.data?.assigneeUserName}
        />
      );
    },
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 110,
    hide: true,
    cellRenderer: (params: any) => {
      return <CommonSeverityChip severityNumber={params.data?.severity} />;
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    width: 100,
    hide: true,
    cellRenderer: (params: any) => {
      return <SlaChip isUtc slaDate={params.data?.slaBreachDate} />;
    },
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    width: 230,
    cellRenderer: (params: any) => {
      return (
        params?.data?.cloudWorkspace && (
          <ItemWithIcon
            maxWidth={220}
            icon={
              <img
                src={params?.data?.cloudWorkspace?.cloudProviderLogoUrl}
                height={16}
              />
            }
            description={params?.data?.cloudWorkspace?.externalId}
            title={params?.data?.cloudWorkspace?.workspaceName}
            missingTitleKey={''}
            displayIconOutline={false}
          />
        )
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    width: 170,
    hide: true,
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    width: 200,
    hide: true,
    cellRenderer: (params: any) => {
      return moment(params.data?.updatedDate).format(dateTimeFormat);
    },
  },
  lastTimelineEventColumn,
  labelGridColumn,
];

export const codeOriginGroupGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
    cellRenderer: CommonAdvancedDataGridRowCheckbox,
  },
  {
    field: CommonDataGridUtilityCell.DATA_GRID_EXPAND_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    width: 10,
    cellRenderer: (params: any) => {
      const isExpanded = params.api.getRowNode(
        `${params.data.originalId}:nested`
      )?.expanded;

      return (
        <div className="advanced-data-grid-sub-table-toggle">
          <OpusSvgIcon
            type={
              isExpanded
                ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
            }
          />
        </div>
      );
    },
  },
  {
    field: 'aggregatedValue',
    headerName: 'Code Origin',
    headerClass: 'column-disable-sort',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.filePath}
          name={params?.data?.fileName}
          iconUrl={params?.data?.providerLogoUrl}
        />
      );
    },
    sortable: false,
  },
  {
    field: 'maxRiskScore',
    headerName: 'Risk Score',
    initialFlex: 1,
    cellRenderer: (params: any) => {
      return (
        <div>
          <RiskChip riskScore={params.data?.maxRiskScore} />
        </div>
      );
    },
  },
  {
    field: 'totalCountForAggregatedGroup',
    headerName: '# Findings',
    initialFlex: 1,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-cloud-resources-cell">
          {params?.data?.totalCountForAggregatedGroup}
        </div>
      );
    },
  },
  {
    field: 'rootCauseCount',
    headerName: '# Root Causes',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-root-causes-cell">
          {params.data?.rootCauseCount}
        </div>
      );
    },
  },
];

export const codeOriginGroupNestedColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },
  {
    field: 'title',
    headerName: 'Risk Name',
    width: 400,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'resourceName',
    headerName: 'Resource',
    width: 280,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          maxWidth={220}
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    width: 120,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    width: 150,
    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    width: 230,
    cellRenderer: (params: any) => {
      return (
        params?.data?.cloudWorkspace && (
          <ItemWithIcon
            maxWidth={220}
            icon={
              <img
                src={params?.data?.cloudWorkspace?.cloudProviderLogoUrl}
                height={16}
              />
            }
            description={params?.data?.cloudWorkspace?.externalId}
            title={params?.data?.cloudWorkspace?.workspaceName}
            missingTitleKey={''}
            displayIconOutline={false}
          />
        )
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
  },
  {
    field: 'itemRelationMetadataItemId',
    headerName: 'Ticket',
    hide: true,
    width: 150,
    cellRenderer: (params: any) => {
      return params?.data.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.data.itemRelationMetadata?.appLogo}
          label={params?.data?.itemRelationMetadata?.itemId}
          linkUrl={params?.data?.itemRelationMetadata?.link}
          maxWidth={130}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    width: 220,
    cellRenderer: (params: any) => {
      return moment(params.data?.externalCreatedAt).format(dateTimeFormat);
    },
  },
  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    width: 250,
    hide: true,
    cellRenderer: (params: any) => {
      return params?.data?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={240}
          icon={
            <img src={params?.data?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.data?.rootCauseMetadata?.repoLink}
          title={params?.data?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'assigneeUserName',
    headerName: 'Assignee',
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <AvatarChip
          avatarUrl={params.data?.assigneeProfilePicture}
          label={params.data?.assigneeUserName}
        />
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    width: 110,
    hide: true,
    cellRenderer: (params: any) => {
      return <CommonSeverityChip severityNumber={params.data?.severity} />;
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    width: 100,
    hide: true,
    cellRenderer: (params: any) => {
      return <SlaChip isUtc slaDate={params.data?.slaBreachDate} />;
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    width: 170,
    hide: true,
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    width: 200,
    hide: true,
    cellRenderer: (params: any) => {
      return moment(params.data?.updatedDate).format(dateTimeFormat);
    },
  },
  {
    field: 'category',
    headerName: 'Risk Category',
    width: 240,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        params?.data?.category && (
          <CommonIconCell
            iconUrl=""
            label={params.data?.category}
            maxWidth={230}
          />
        )
      );
    },
  },
  lastTimelineEventColumn,
  labelGridColumn,
];

export const riskGroupGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
    cellRenderer: CommonAdvancedDataGridRowCheckbox,
  },
  {
    field: CommonDataGridUtilityCell.DATA_GRID_EXPAND_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    width: 10,
    cellRenderer: (params: any) => {
      const isExpanded = params.api.getRowNode(
        `${params.data.originalId}:nested`
      )?.expanded;

      return (
        <div className="advanced-data-grid-sub-table-toggle">
          <OpusSvgIcon
            type={
              isExpanded
                ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
            }
          />
        </div>
      );
    },
  },
  {
    field: 'findingTitle',
    headerName: 'Risk Name',
    sortable: true,
    width: 700,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          <>
            {params.data?.findingTitle}
            <span className="risk-group-grid-risk-name-cell-counter">{`(${params.data?.totalCountForAggregatedGroup})`}</span>
          </>
        </TextOverflow>
      );
    },
  },
  {
    field: 'category',
    headerName: 'Risk Category',
    sortable: true,
    width: 230,
    cellRenderer: (params: any) => {
      return (
        params?.data?.category && (
          <CommonIconCell
            iconUrl=""
            label={params.data?.category}
            maxWidth={130}
          />
        )
      );
    },
  },
  {
    field: 'maxRiskScore',
    headerName: 'Risk Score',
    sortable: true,
    width: 120,
    cellRenderer: (params: any) => {
      return (
        <div>
          <RiskChip riskScore={params.data?.maxRiskScore} />
        </div>
      );
    },
  },
  {
    field: 'totalCountForAggregatedGroup',
    headerName: 'Findings',
    sortable: true,
    width: 110,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-cloud-resources-cell">
          {params.data?.totalCountForAggregatedGroup}
        </div>
      );
    },
  },
  {
    field: 'rootCauseCount',
    headerName: 'Root Causes',
    sortable: true,
    initialFlex: 1,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-root-causes-cell">
          {params.data?.rootCauseCount}
        </div>
      );
    },
  },
];

export const riskGroupNestedColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    width: 280,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          maxWidth={220}
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    width: 120,
    cellRenderer: (params: any) => {
      return (
        <div>
          <RiskChip riskScore={params.data?.riskScore} />
        </div>
      );
    },
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    width: 170,
    cellRenderer: (params: any) => {
      return params?.data.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'itemRelationMetadataItemId',
    headerName: 'Ticket',
    width: 150,
    cellRenderer: (params: any) => {
      return params?.data.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.data.itemRelationMetadata?.appLogo}
          label={params?.data?.itemRelationMetadata?.itemId}
          linkUrl={params?.data?.itemRelationMetadata?.link}
          maxWidth={130}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    width: 230,
    cellRenderer: (params: any) => {
      return params?.data?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={250}
          icon={
            <img src={params?.data?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.data?.rootCauseMetadata?.repoLink}
          title={params?.data?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    width: 200,
    cellRenderer: (params: any) => {
      return moment(params.data?.externalCreatedAt).format(dateTimeFormat);
    },
  },
  {
    field: 'assigneeUserName',
    headerName: 'Assignee',
    hide: true,
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <AvatarChip
          avatarUrl={params.data?.assigneeProfilePicture}
          label={params.data?.assigneeUserName}
        />
      );
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    hide: true,
    width: 120,
    cellRenderer: (params: any) => {
      return <CommonSeverityChip severityNumber={params.data?.severity} />;
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    hide: true,
    width: 100,
    cellRenderer: (params: any) => {
      return <SlaChip isUtc slaDate={params.data?.slaBreachDate} />;
    },
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    width: 230,
    cellRenderer: (params: any) => {
      return params?.data?.cloudWorkspace ? (
        <ItemWithIcon
          maxWidth={220}
          icon={
            <img
              src={params?.data?.cloudWorkspace?.cloudProviderLogoUrl}
              height={16}
            />
          }
          description={params?.data?.cloudWorkspace?.externalId}
          title={params?.data?.cloudWorkspace?.workspaceName}
          missingTitleKey={''}
          displayIconOutline={false}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    hide: true,
    width: 250,
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    hide: true,
    width: 200,
    cellRenderer: (params: any) => {
      return moment(params.data?.updatedDate).format(dateTimeFormat);
    },
  },
  lastTimelineEventColumn,
  labelGridColumn,
];

export const noneRiskTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },
  {
    field: 'title',
    headerName: 'Risk Name',
    sortable: true,
    width: 300,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="cell-text-renderer"
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    sortable: true,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
    width: 120,
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
    width: 150,
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
    width: 230,
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    sortable: true,
    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
    width: 150,
  },
  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    sortable: true,
    cellRenderer: (params: any) => {
      return params?.data?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={240}
          icon={
            <img src={params?.data?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.data?.rootCauseMetadata?.repoLink}
          title={params?.data?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
    width: 230,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
    width: 180,
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <div className="cell-text-renderer">
          {moment(params.data?.externalCreatedAt).format(dateTimeFormat)}
        </div>
      );
    },
    width: 200,
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    sortable: true,
    cellRenderer: (params: any) => {
      return params?.data?.cloudWorkspace ? (
        <ItemWithIcon
          maxWidth={220}
          icon={
            <img
              src={params?.data?.cloudWorkspace?.cloudProviderLogoUrl}
              height={16}
            />
          }
          description={params?.data?.cloudWorkspace?.externalId}
          title={params?.data?.cloudWorkspace?.workspaceName}
          missingTitleKey={''}
          displayIconOutline={false}
        />
      ) : (
        <div>-</div>
      );
    },
    width: 230,
  },
  {
    field: 'category',
    headerName: 'Risk Category',
    hide: true,
    sortable: true,
    width: 200,
    cellRenderer: (params: any) => {
      return <CommonIconCell label={params?.data?.category} maxWidth={140} />;
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    hide: true,
    width: 110,
    cellRenderer: (params: any) => {
      return <CommonSeverityChip severityNumber={params.data?.severity} />;
    },
  },
  {
    field: 'itemRelationMetadataItemId',
    headerName: 'Ticket',
    hide: true,
    width: 150,
    cellRenderer: (params: any) => {
      return params?.data.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.data.itemRelationMetadata?.appLogo}
          label={params?.data?.itemRelationMetadata?.itemId}
          linkUrl={params?.data?.itemRelationMetadata?.link}
          maxWidth={130}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    hide: true,
    width: 250,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={250}
          className="body-2"
        >
          {params?.data?.ownerUserId || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'assigneeUserId',
    headerName: 'Assignee',
    hide: true,
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <AvatarChip
          avatarUrl={params.data?.assigneeProfilePicture}
          label={params.data?.assigneeUserName}
        />
      );
    },
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    hide: true,
    width: 100,
    cellRenderer: (params: any) => {
      return <SlaChip isUtc slaDate={params.data?.slaBreachDate} />;
    },
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    hide: true,
    width: 200,
    cellRenderer: (params: any) => {
      return (
        <div className="cell-text-renderer">
          {moment(params.data?.updatedDate).format(dateTimeFormat)}
        </div>
      );
    },
  },
  {
    field: 'operatingSystem',
    headerName: 'Operating System',
    width: 200,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="cell-text-renderer"
        >
          {params?.data?.resourceOperatingSystem}
        </TextOverflow>
      );
    },
  },
  lastTimelineEventColumn,
  labelGridColumn,
];

export const rootCauseGroupingGridColumns: Array<GridColDef> = [
  {
    field: 'riskName',
    headerName: 'Risk Name',
    width: 500,
    renderCell: (params) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {params?.row?.riskName}
        </TextOverflow>
      );
    },
  },
  {
    field: 'resourceCount',
    headerName: 'Resources',
    width: 110,
  },
  {
    field: 'riskScore',
    headerName: 'Highest Score',
    renderCell: (params) => {
      return (
        <div>
          <RiskChip riskScore={Number(params.row?.riskScore)} />
        </div>
      );
    },
    width: 130,
  },
  {
    field: 'lifecycle',
    headerName: 'SDLC',
    width: 180,
    renderCell: (params) => {
      return (
        <RiskLifecycleCell
          artifactCount={params?.row?.artifactLayerCount}
          codeCount={params?.row?.codeLayerCount}
          runtimeCount={params?.row?.runtimeLayerCount}
        />
      );
    },
    sortable: false,
  },
  {
    field: 'businessUnitCount',
    headerName: 'Service',
    width: 130,
  },
  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    width: 230,
    renderCell: (params) => {
      return params?.row?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={250}
          icon={
            <img src={params?.row?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.row?.rootCauseMetadata?.repoLink}
          title={params?.row?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    renderCell: (params) => {
      return <Status label={params.row?.status} readonly />;
    },
  },
];

export const rootCauseGroupingHiddenGridColumns: Array<GridColDef> = [
  {
    field: 'ticketId',
    headerName: 'Ticket',
    width: 150,
    renderCell: (params) => {
      return params?.row.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.row.itemRelationMetadata?.appLogo}
          label={params?.row?.itemRelationMetadata?.itemId || '-'}
          linkUrl={params?.row?.itemRelationMetadata?.link}
          maxWidth={120}
        />
      ) : (
        <div>-</div>
      );
    },
  },
];

export const rootCauseGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },
  {
    field: 'riskName',
    headerName: 'Risk Name',
    width: 500,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {params?.data?.riskName}
        </TextOverflow>
      );
    },
  },
  {
    field: 'impactScore',
    headerName: 'Impact Score',
    cellRenderer: (params: any) => {
      return (
        <div>
          <ImpactScoreChip
            value={+Number(params.data?.impactScore).toFixed(2)}
          />
        </div>
      );
    },
    width: 140,
  },
  {
    field: 'resourceCount',
    headerName: 'Resources',
    width: 130,
    cellRenderer: (params: any) => {
      return (
        <Tooltip
          title={
            <React.Fragment>
              {params.data?.resourceNames?.map((item: string) => (
                <Typography color="inherit" variant="inherit">
                  {item}
                </Typography>
              ))}
            </React.Fragment>
          }
        >
          <div>
            <CommonIconCell label={params.data?.resourceCount} maxWidth={120} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: 'lifecycle',
    headerName: 'SDLC',
    width: 180,
    cellRenderer: (params: any) => {
      return (
        <RiskLifecycleCell
          artifactCount={params?.data?.artifactLayerCount}
          codeCount={params?.data?.codeLayerCount}
          runtimeCount={params?.data?.runtimeLayerCount}
        />
      );
    },
    sortable: false,
  },
  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    width: 230,
    cellRenderer: (params: any) => {
      return params?.data?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={250}
          icon={
            <img src={params?.data?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.data?.rootCauseMetadata?.repoLink}
          title={params?.data?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'findingCount',
    headerName: '# Findings',
    width: 210,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-cloud-resources-cell">
          {params?.data?.findingCount}
        </div>
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Highest Score',
    cellRenderer: (params: any) => {
      return (
        <div>
          <RiskChip riskScore={Number(params.data?.riskScore)} />
        </div>
      );
    },
    width: 140,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
  },
  {
    field: 'businessUnitCount',
    headerName: 'Service',
    width: 140,
    cellRenderer: (params: any) => {
      return (
        <Tooltip
          title={
            <React.Fragment>
              {params.data?.businessUnitNames?.map((item: string) => (
                <Typography color="inherit" variant="inherit">
                  {item}
                </Typography>
              ))}
            </React.Fragment>
          }
        >
          <div>
            <CommonIconCell
              label={params.data?.businessUnitCount}
              maxWidth={120}
            />
          </div>
        </Tooltip>
      );
    },
  },

  {
    field: 'resourceOwner',
    headerName: 'Resource Owner',
    width: 220,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={200}
          className="body-2"
        >
          {params?.data?.resourceOwner || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    width: 200,
    hide: true,
    cellRenderer: (params: any) => {
      return moment(params.data?.updatedDate).format(dateTimeFormat);
    },
  },
  {
    field: 'category',
    headerName: 'Risk Category',
    width: 200,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        params?.data?.category && (
          <CommonIconCell label={params.data?.category} maxWidth={180} />
        )
      );
    },
  },

  {
    field: 'ticketId',
    headerName: 'Ticket',
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return params?.data.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.data.itemRelationMetadata?.appLogo}
          label={params?.data?.itemRelationMetadata?.itemId || '-'}
          linkUrl={params?.data?.itemRelationMetadata?.link}
          maxWidth={120}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  labelGridColumn,
];

export const defaultHiddenRiskGridColumns: Array<VisibilityControlColumn> = [
  {
    value: 'category',
    label: 'Risk Category',
  },
  {
    value: 'severity',
    label: 'Severity',
  },
  {
    value: 'itemRelationMetadataItemId',
    label: 'Ticket',
  },
  {
    value: 'ownerUserId',
    label: 'Resource Owner',
  },
  {
    value: 'assigneeUserId',
    label: 'Assignee',
  },
  {
    value: 'slaBreachDate',
    label: 'SLA',
  },
  {
    value: 'updatedDate',
    label: 'Last Update',
  },
  {
    value: 'operatingSystem',
    label: 'Operating System',
  },
  lastTimelineEventVisibilityConfiguration,
];

export const defaultHiddenResourceGroupNestedColumns: Array<VisibilityControlColumn> =
  [
    {
      value: 'assigneeUserName',
      label: 'Assignee',
    },
    {
      value: 'findingSource',
      label: 'Event Source',
    },
    {
      value: 'severity',
      label: 'Severity',
    },
    {
      value: 'slaBreachDate',
      label: 'SLA',
    },
    {
      value: 'ownerUserId',
      label: 'Resource Owner',
    },
    {
      value: 'updatedDate',
      label: 'Last Update',
    },
    lastTimelineEventVisibilityConfiguration,
  ];

export const defaultHiddenCodeOriginGroupNestedColumns: Array<VisibilityControlColumn> =
  [
    {
      value: 'rootCauseFilePath',
      label: 'Root Cause',
    },
    {
      value: 'assigneeUserName',
      label: 'Assignee',
    },
    {
      value: 'severity',
      label: 'Severity',
    },
    {
      value: 'slaBreachDate',
      label: 'SLA',
    },
    {
      value: 'ownerUserId',
      label: 'Resource Owner',
    },
    {
      value: 'updatedDate',
      label: 'Last Update',
    },
    lastTimelineEventVisibilityConfiguration,
  ];

export const defaultHiddenRiskGroupNestedColumns: Array<VisibilityControlColumn> =
  [
    {
      value: 'assigneeUserName',
      label: 'Assignee',
    },
    {
      value: 'severity',
      label: 'Severity',
    },
    {
      value: 'slaBreachDate',
      label: 'SLA',
    },
    {
      value: 'ownerUserId',
      label: 'Resource Owner',
    },
    {
      value: 'updatedDate',
      label: 'Last Update',
    },
    lastTimelineEventVisibilityConfiguration,
  ];

export const defaultRootCauseGridHiddenColumns: Array<VisibilityControlColumn> =
  [
    {
      value: 'ticketId',
      label: 'Ticket',
    },
    {
      value: 'resourceOwner',
      label: 'Resource Owner',
    },
    {
      value: 'updatedDate',
      label: 'Last Update',
    },
    {
      value: 'category',
      label: 'Risk Category',
    },
  ];

export const rootCauseDefaultOrder = {
  field: 'impactScore',
  type: OrderType.DESC,
};

export const packageGroupDefaultOrder = {
  field: 'impactScore',
  type: OrderType.DESC,
};

export const nestedPackageGroupDefaultOrder = {
  field: 'riskScore',
  type: OrderType.DESC,
};

export const nestedCodeOriginDefaultOrder = {
  field: 'riskScore',
  type: OrderType.DESC,
};

export const riskViewFilterCategories: Array<ExtendedFilterCategory> = [
  {
    id: 'risk',
    label: 'Risk',
    categories: [
      {
        id: 'title',
        label: 'Risk Name',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/title',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'findingType',
        label: 'Domain',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'category',
        label: 'Risk Category',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/category',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'riskScore',
        label: 'Risk Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 10,
        },
      },
      {
        id: 'severity',
        label: 'Severity',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/severity',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options) => options,
        },
      },
      {
        id: 'findingSource',
        label: 'Event Source',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingSource',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'externalId',
        label: 'External ID',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/externalId',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'packageName',
        label: 'Package Name',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/packageName',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'packageNameAndInstalledVersion',
        label: 'Installed Version',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/packageNameAndInstalledVersion',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
    ],
  },
  {
    id: 'organization',
    label: 'Organization',
    categories: [
      {
        id: 'scopeId',
        label: 'Scope',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'scope',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'groupId',
        label: 'Group',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'logical',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'businessUnitId',
        label: 'Service',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/businessUnitId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: 'environmentType',
        label: 'Environment',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/businessUnitEnvironmentType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: `workspace`,
        label: 'Workspace',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/workspace',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
    ],
  },
  {
    id: 'resource',
    label: 'Resource',
    categories: [
      {
        id: 'loweredResourceId',
        label: 'Resource ID',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/loweredResourceId',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'resourceName',
        label: 'Resource Name',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'config/filters/search/resourceName',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'tags',
        label: 'Tags',
        type: FilterType.KEY_VALUE,
        state: {
          selections: [
            {
              key: {
                value: '',
                label: '',
              },
              values: [],
            },
          ],
          keySource: {
            type: OptionSourceType.API_STATIC,
            payload: {
              method: 'GET',
              url: 'tags/filters/search?fieldName=key',
              optionsTransformer:
                dataFilterHandler.transformApiFilterOptionsPayload,
            },
          },
          valueSource: {
            type: OptionSourceType.API_STATIC,
            payload: {
              method: 'GET',
              url: 'tags/:key/values/filters/search',
              optionsTransformer:
                dataFilterHandler.transformApiFilterOptionsPayload,
            },
          },
        },
        entitlement: SystemFeatureFlag.TAGS_FILTER,
      },
      {
        id: 'resourceType',
        label: 'Resource Type',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/resourceType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'operatingSystem',
        label: 'Operating System',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/operatingSystem',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'ownerUserId',
        label: 'Resource Owner',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/ownerUserId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, [
              'None',
              'Unassigned',
            ]),
        },
      },
      {
        id: 'isExternalFacing',
        label: 'External Facing Resource',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasSensitiveData',
        label: 'Resource with Sensitive Data',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
    ],
  },
  {
    id: 'intelligence',
    label: 'Intelligence',
    entitlement: SystemFeatureFlag.FINDING_VIEW_INTELLIGENCE_TAB,
    categories: [
      {
        id: 'isDiscussed',
        label: 'Is Discussed',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPOC',
        label: 'POC',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasExploit',
        label: 'Has Exploit',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isExploitedInTheWild',
        label: 'In the Wild',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPenTesterFramework',
        label: 'Pentest Framework',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'cisaKev',
        label: 'Cisa Kev',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasRansomware',
        label: 'Ransomware',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasThreatActor',
        label: 'Threat Actors',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isTrending',
        label: 'Trending',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasMalware',
        label: 'Malware',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'hasPatch',
        label: 'Has Patch',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'isPrioritized',
        label: 'Prioritized',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'intelligenceScore',
        label: 'Intelligence Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 10,
        },
      },
      {
        id: 'epssScore',
        label: 'EPSS Score',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 1,
        },
      },
      {
        id: 'epssPercentile',
        label: 'EPSS Percentile',
        type: FilterType.NUMBER_RANGE,
        state: {
          minValue: 0,
          maxValue: 1,
        },
      },
      {
        id: 'attackComplexity',
        label: 'Attack Complexity',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: [
              {
                value: 'HIGH',
                label: 'High',
              },
              {
                value: 'LOW',
                label: 'Low',
              },
            ],
          },
        },
      },
      {
        id: 'ssvcDecision',
        label: 'SSVC Decision',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: [
              {
                value: SsvcDecision.TRACK,
                label: SsvcDecision.TRACK,
              },
              {
                value: SsvcDecision.ACT,
                label: SsvcDecision.ACT,
              },
              {
                value: SsvcDecision.ATTEND,
                label: SsvcDecision.ATTEND,
              },
              {
                value: SsvcDecision.TRACK_ASTRIX,
                label: SsvcDecision.TRACK_ASTRIX,
              },
            ],
          },
        },
      },
      {
        id: 'ssvcWellBeingImpact',
        label: 'Risk Level',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: [
              {
                value: SsvcPublicWellbeingImpact.MINIMAL,
                label: 'Minimal',
              },
              {
                value: SsvcPublicWellbeingImpact.IRREVERSIBLE,
                label: 'Irreversible',
              },
              {
                value: SsvcPublicWellbeingImpact.MATERIAL,
                label: 'Material',
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 'operational',
    label: 'Operational',
    categories: [
      {
        id: 'status',
        label: 'Status',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/status',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'externalCreatedAt',
        label: 'First Seen',
        type: FilterType.DATE_RANGE,
      },
      {
        id: 'itemRelationMetadata',
        label: 'Has Ticket',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'ticketId',
        label: 'Ticket ID',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/ticketId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'ticketTitle',
        label: 'Ticket Title',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/ticketTitle',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'reopened',
        label: 'Reopened',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: booleanRiskFilterOptions,
          },
        },
      },
      {
        id: 'assigneeUserId',
        label: 'Assignee',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/assigneeUserId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['Unassigned']),
        },
      },
      {
        id: 'sensorResolutionReason',
        label: 'Dismiss Reason',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/sensorResolutionReason',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'slaStatus',
        label: 'SLA Status',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/slaStatus',
            optionsTransformer: (optionPayload: any) => {
              return optionPayload.data?.map((option: any) => ({
                value: option.value,
                label: capitalize(option.value.replace('-', ' ')),
              }));
            },
          },
        },
      },
      {
        id: 'state',
        label: 'State',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: [
              {
                value: FindingState.ACTIVE,
                label: FindingState.ACTIVE,
              },
              {
                value: FindingState.IN_ACTIVE,
                label: FindingState.IN_ACTIVE,
              },
            ],
          },
        },
      },
      {
        id: 'duplicationState',
        label: 'Duplication State',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.STATIC,
          source: {
            options: [
              {
                value: DuplicationState.PRIMARY,
                label: 'Duplicated',
              },
              {
                value: DuplicationState.SINGLE,
                label: 'Single',
              },
            ],
          },
        },
      },
      labelFilterCategory,
    ],
  },
];

export const packageGroupGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
    cellRenderer: CommonAdvancedDataGridRowCheckbox,
  },
  {
    field: CommonDataGridUtilityCell.DATA_GRID_EXPAND_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    width: 10,
    cellRenderer: (params: any) => {
      const isExpanded = params.api.getRowNode(
        `${params.data.originalId}:nested`
      )?.expanded;

      return (
        <div className="advanced-data-grid-sub-table-toggle">
          <OpusSvgIcon
            type={
              isExpanded
                ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
            }
          />
        </div>
      );
    },
  },
  {
    field: 'aggregatedValue',
    headerName: 'Name',
    sortable: true,
    width: 450,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          <>
            {params.data?.aggregatedValue.replace(':parent', '')}
            <span className="risk-group-grid-risk-name-cell-counter">{`(${params.data?.totalCountForAggregatedGroup})`}</span>
          </>
        </TextOverflow>
      );
    },
  },
  {
    field: 'impactScore',
    headerName: 'Impact Score',
    cellRenderer: (params: any) => {
      return (
        <div>
          <ImpactScoreChip
            value={+Number(params.data?.impactScore).toFixed(2)}
          />
        </div>
      );
    },
    width: 140,
  },
  {
    field: 'severityBreakdown',
    headerName: 'Severity Breakdown',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 250,
    cellRenderer: (params: any) => {
      return (
        <SeverityBreakdown
          severityData={params?.data?.severityBreakdown}
          severityToDisplay={[
            FindingSeverity.HIGH,
            FindingSeverity.CRITICAL,
            FindingSeverity.MEDIUM,
          ]}
        />
      );
    },
  },
  {
    field: 'totalCountForAggregatedGroup',
    headerName: '# Findings',
    sortable: true,
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-cloud-resources-cell">
          {params.data?.totalCountForAggregatedGroup}
        </div>
      );
    },
  },
  {
    field: 'resourceCount',
    headerName: '# Resources',
    sortable: true,
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-root-causes-cell">
          {params.data?.resourceCount}
        </div>
      );
    },
  },
  {
    field: 'eventSources',
    headerName: 'Event Sources',
    width: 150,
    sortable: false,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.eventSources?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.eventSources,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
  },
  {
    field: 'serviceCount',
    headerName: '# Services',
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <div className="risk-group-grid-cloud-resources-cell">
          {params.data?.serviceCount}
        </div>
      );
    },
  },
];

export const packageGroupNestedColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },

  {
    field: 'title',
    headerName: 'Risk Name',
    width: 300,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    width: 120,
    cellRenderer: (params: any) => {
      return (
        <div>
          <RiskChip riskScore={params.data?.riskScore} />
        </div>
      );
    },
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    width: 280,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          maxWidth={220}
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
  },

  {
    field: 'rootCauseFilePath',
    headerName: 'Root Cause',
    width: 230,
    cellRenderer: (params: any) => {
      return params?.data?.rootCauseMetadata ? (
        <ItemWithIcon
          maxWidth={250}
          icon={
            <img src={params?.data?.rootCauseMetadata?.appLogo} height={16} />
          }
          description={params?.data?.rootCauseMetadata?.repoLink}
          title={params?.data?.rootCauseMetadata?.filePath}
          missingTitleKey={''}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    width: 200,
    cellRenderer: (params: any) => {
      return moment(params.data?.externalCreatedAt).format(dateTimeFormat);
    },
  },
];
