import { Stack, Button, Grid } from '@mui/material';
import SearchField from 'Common/components/SearchField/SearchField';
import React, { Dispatch, ReactNode } from 'react';
import AddIcon from '@mui/icons-material/Add';
import OpusSimpleMenu from 'shared/components/OpusSimpleMenu';
import { OpusSimpleMenuItem } from 'shared/components/OpusSimpleMenu/OpusSimpleMenu';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import { AuthorizedContent } from '@frontegg/react';

export interface AdministrationHeaderProps<T> {
  setTableItems: (items: T[]) => void;
  items: T[];
  filterPropertyName: string;
  onButtonClick?: (item: any) => void;
  buttonLabel?: React.ReactElement;
  buttonType?: 'menu' | 'button';
  buttonMenuItems?: any;
  buttonRequiredPermissions?: ApplicationPermission[];
  disableButton?: boolean;
  setPage?: Dispatch<number>;
  ButtonComponent?: ReactNode;
}

export const AdministrationHeader = <T extends {}>({
  setTableItems,
  items,
  filterPropertyName,
  onButtonClick,
  buttonLabel,
  buttonType,
  buttonMenuItems,
  buttonRequiredPermissions,
  disableButton = false,
  setPage,
  ButtonComponent,
}: AdministrationHeaderProps<T>) => {
  const handleButtonClick = (item: any) => {
    if (onButtonClick) {
      onButtonClick(item);
    }
  };

  const renderButton = () => {
    if (ButtonComponent) {
      return (
        <AuthorizedContent
          requiredPermissions={buttonRequiredPermissions}
          render={(isAuthorized) => (isAuthorized ? ButtonComponent : <></>)}
        />
      );
    }

    return buttonType !== undefined ? (
      <AuthorizedContent
        requiredPermissions={buttonRequiredPermissions}
        render={(isAuthorized) => (
          <>
            {buttonType !== 'menu' ? (
              <Button
                startIcon={<AddIcon fontSize="small" />}
                onClick={() => handleButtonClick(items[0])}
                className="opus-primary-button settings-header-button"
                disabled={!isAuthorized || disableButton}
              >
                {buttonLabel}
              </Button>
            ) : (
              <OpusSimpleMenu
                buttonClassName=" opus-primary-button settings-header-button"
                buttonLabel={buttonLabel as React.ReactElement}
                items={buttonMenuItems as OpusSimpleMenuItem[]}
                onSelect={(item: OpusSimpleMenuItem) =>
                  handleButtonClick(item as OpusSimpleMenuItem)
                }
                disabled={!isAuthorized}
              />
            )}
          </>
        )}
      />
    ) : (
      <></>
    );
  };

  return (
    <Grid
      direction="row"
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <SearchField<T>
          setTableItems={setTableItems}
          items={items}
          setPage={setPage}
          filterPropertyName={filterPropertyName}
        ></SearchField>
      </Grid>
      <Grid item>{renderButton()}</Grid>
    </Grid>
  );
};
