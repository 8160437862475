import { AuthorizedContent } from '@frontegg/react';
import { Typography } from '@mui/material';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import MoreActionsMenu from 'shared/components/MoreActionsMenu';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { reportRuleRecipientOptions } from 'shared/models/data/report-rule.model';
import StakeholdersGroup from '../StakeholdersGroup/StakeholdersGroup';
import { useFetchUserQuery } from 'Auth/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';

export const ReportRuleRecipientsCellRenderer: FunctionComponent<any> = ({
  params,
  t,
}) => {
  const { data: userList } = useFetchUserQuery('');

  const selectedRecipients = useMemo(() => {
    if (!userList)
      return (
        params.data.recipients.map((recipient: any) => {
          return {
            id: recipient.value,
            name: recipient.value,
          };
        }) || []
      );

    return params.data?.recipients?.map(
      (recipient: AutocompleteOption) =>
        userList.find((user) => user.email === recipient.value) || {
          id: recipient.value,
          name: recipient.value,
        }
    );
  }, [userList, params.data?.recipients]);

  return params.data.reportMode === 'multi' ? (
    <Typography className="body-1" variant="inherit">
      {
        reportRuleRecipientOptions.find(
          (recipientOption) =>
            recipientOption?.value === params.data.recipients[0]?.value
        )?.label
      }
    </Typography>
  ) : (
    <StakeholdersGroup
      maxStakeholders={3}
      stakeholders={selectedRecipients.map((selectedRecipient: any) => ({
        id: selectedRecipient.id,
        fullName: selectedRecipient.name,
        avatarUrl: selectedRecipient.profilePictureUrl,
      }))}
    />
  );
};
