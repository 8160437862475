import { Box, Typography } from '@mui/material';
import CommonIconMui from '../../IconMui';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useEffect, useState } from 'react';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import CommonDialog from '../CommonDialog';
import { useTranslation } from 'react-i18next';

interface DeleteWithConfirmProps {
  id: string;
  useMutation: UseMutation<any>;
  children: ReactJSXElement;
  callback: (decision: boolean, response?: any) => void;
  title?: string;
  confimButtonLabel?: string;
  loadingConfimButtonLabel?: string;
  message?: string;
  disabled?: boolean;
  setShouldIgnoreRowClick?: any;
  customDelete?: (e?: React.MouseEvent<HTMLElement>) => void;
  modalIcon?: React.ReactElement;
  open?: boolean;
}

export default function DeleteWithConfirm({
  id,
  title,
  message,
  useMutation,
  children,
  callback,
  disabled = false,
  setShouldIgnoreRowClick,
  confimButtonLabel,
  loadingConfimButtonLabel,
  customDelete,
  modalIcon,
  open,
}: DeleteWithConfirmProps) {
  const { t: translation } = useTranslation();
  const [deleteMethod, { data: deleteResponse, isLoading, isSuccess, reset }] =
    useMutation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const ignoreRowClick = (value: boolean) => {
    if (setShouldIgnoreRowClick) setShouldIgnoreRowClick(value);
  };

  function handleDialogOpen(event: React.MouseEvent<HTMLElement>) {
    ignoreRowClick(true);

    event.preventDefault();
    event.stopPropagation();
    (event as any).ignore = true;
    setDialogOpen(true);
  }

  function handleDialogClose(decision = false) {
    callback(decision, deleteResponse);
    reset();
    ignoreRowClick(false);
    setDialogOpen(false);
  }
  function onDelete(event?: React.MouseEvent<HTMLElement>) {
    if (customDelete) {
      customDelete(event);
      handleDialogClose();
    } else {
      deleteMethod(id);
    }
  }

  useEffect(() => {
    if (isSuccess) handleDialogClose(true);
  }, [isSuccess]);

  return (
    <>
      <Box
        display="initial"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          if (disabled) return;
          handleDialogOpen(event);
        }}
      >
        {children}
      </Box>
      <CommonDialog
        open={typeof open === 'boolean' ? open : dialogOpen}
        onClose={() => handleDialogClose()}
        title={title}
        loadingButtonText={
          loadingConfimButtonLabel || translation('common.deleting')
        }
        closeButtonText={translation('common.cancel')}
        saveButtonText={confimButtonLabel || translation('common.delete')}
        isLoading={isLoading}
        onSave={onDelete}
        icon={modalIcon || <CommonIconMui icon="delete" size={20} isOutlined />}
      >
        <Box minWidth={'450px'} mb={3}>
          {message && (
            <Typography variant="inherit" className="body-1" color={'#3d3d3d'}>
              {message}
            </Typography>
          )}
        </Box>
      </CommonDialog>
    </>
  );
}
