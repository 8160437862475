import { FunctionComponent } from 'react';
import {
  ReportRuleContentWidgetId,
  ReportRuleProperty,
  SecurityReportSelectedWidget,
  operationalOverviewWidgetIds,
} from 'shared/models/data/report-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import DashboardReportsRiskTrendMetricsWidget from '../DashboardReportsRiskTrendMetricsWidget';
import DashboardReportsRiskTrendsWidget from '../DashboardReportsRiskTrendsWidget';
import { DashboardReportsRiskActiveCampaigns } from '../DashboardReportsRiskActiveCampaigns';
import DashboardReportsRiskOperationalOverviewWidget from '../DashboardReportsRiskOperationalOverviewWidget';
import { DashboardReportsRiskTotalMetricsWidget } from '../DashboardReportsRiskTotalMetricsWidget';
import DashboardReportsRiskFindingBySeverityWidget from '../DashboardReportsRiskFindingBySeverityWidget';
import DashboardReportsRiskFindingByCategoryWidget from '../DashboardReportsRiskFindingByCategoryWidget';

export interface DashboardReportsRiskWidgetProps {
  businessUnitIds: Array<string>;
  findingTypes: Array<string>;
}

interface DashboardReportsRiskProps extends BaseComponentProps {
  widgets: Array<SecurityReportSelectedWidget>;
  searchParams?: Array<ReportRuleProperty>;
  businessUnitIds: Array<string>;
  findingTypes: Array<string>;
}

export const DashboardReportsRisk: FunctionComponent<
  DashboardReportsRiskProps
> = ({ widgets, searchParams, findingTypes, businessUnitIds }) => {
  const renderRiskSection = (
    sectionType: ReportRuleContentWidgetId,
    SectionComponent: FunctionComponent<DashboardReportsRiskWidgetProps>
  ) => {
    const shouldRender = Boolean(
      widgets.find((widget) => widget.name === sectionType)
    );

    if (shouldRender)
      return (
        <SectionComponent
          businessUnitIds={businessUnitIds}
          findingTypes={findingTypes}
        />
      );

    return <></>;
  };

  const renderTrendMetrics = () => {
    const trendMetricsConfiguration = widgets.find(
      (widget) => widget.name === ReportRuleContentWidgetId.TRENDS_METRICS
    )?.configuration;

    if (trendMetricsConfiguration) {
      return (
        <DashboardReportsRiskTrendMetricsWidget
          businessUnitIds={businessUnitIds}
          timeFrame={trendMetricsConfiguration.timeFrame}
          findingTypes={findingTypes}
          metricTypes={trendMetricsConfiguration.metrics}
        />
      );
    }

    return <></>;
  };

  const renderTrendGraph = () => {
    const trendGraphConfiguration = widgets.find(
      (widget) => widget.name === ReportRuleContentWidgetId.TRENDS_GRAPH
    )?.configuration;

    if (trendGraphConfiguration) {
      return (
        <DashboardReportsRiskTrendsWidget
          businessUnitIds={businessUnitIds}
          timeFrame={trendGraphConfiguration.timeFrame}
          trendMetricsTypes={trendGraphConfiguration.trendingChartMetrics}
          findingTypes={findingTypes}
        />
      );
    }

    return <></>;
  };

  const renderOperationalOverviewWidget = () => {
    const hasOperationalOverviewWidget = widgets.some((widget) =>
      operationalOverviewWidgetIds.includes(
        widget.name as ReportRuleContentWidgetId
      )
    );

    if (hasOperationalOverviewWidget)
      return (
        <DashboardReportsRiskOperationalOverviewWidget
          businessUnitIds={businessUnitIds}
          findingTypes={findingTypes}
          widgets={widgets}
        />
      );

    return <></>;
  };

  return (
    <div className="dashboard-reports-risk-container">
      {renderTrendMetrics()}
      {renderTrendGraph()}

      {renderRiskSection(
        ReportRuleContentWidgetId.TOTAL_METRICS,
        DashboardReportsRiskTotalMetricsWidget
      )}
      {renderRiskSection(
        ReportRuleContentWidgetId.FINDING_BY_SEVERITY,
        DashboardReportsRiskFindingBySeverityWidget
      )}

      {renderRiskSection(
        ReportRuleContentWidgetId.FINDING_BY_CATEGORY,
        DashboardReportsRiskFindingByCategoryWidget
      )}

      {renderOperationalOverviewWidget()}

      {renderRiskSection(
        ReportRuleContentWidgetId.ACTIVE_CAMPAIGNS,
        DashboardReportsRiskActiveCampaigns
      )}
    </div>
  );
};
