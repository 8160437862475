import { ColDef, ColGroupDef } from 'ag-grid-community';
import ResourceAttributes from 'Assets/components/ResourceAttributes/ResourceAttributes';
import ItemWithIcon from 'Common/components/ItemWithIcon';
import { MenuItems } from 'DashboardFilter/store';
import { capitalize } from 'lodash';
import moment from 'moment';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import RiskChip from 'Risk/components/RiskChip';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import CommonChip from 'shared/components/CommonChip';
import CommonIconCell from 'shared/components/CommonIconCell';
import { VisibilityControlColumn } from 'shared/components/CommonSimpleDataGrid/CommonSimpleDataGrid';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import ImpactScoreChip from 'shared/components/ImpactScoreChip';
import { ResourceCell } from 'shared/components/ResourceCell/ResourceCell';
import SeverityBreakdown from 'shared/components/SeverityBreakdown/SeverityBreakdown';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import { AdvanceFilterGetter } from 'shared/hooks/useGetAdvanceFilterGetter';
import {
  ExtendedFilterCategory,
  FilterOperator,
  FilterType,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { DataFilterOption } from 'shared/models/data/data-filters.model';
import {
  riskScoreItems,
  riskGroupNoneOption,
  FindingState,
  unassignedFilterOption,
  booleanRiskFilterOptions,
  labelFilterCategory,
} from './risk-grid.data';
import { RiskFilterItem } from 'Risk/store';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import { dateTimeFormat } from 'shared/services/date.service';
import SlaChip from 'shared/components/SlaChip';

const dataFilterHandler = new DataFitlerHandler();

const advanceFilterDataHandler = new AdvanceFilterHandler();

const defaultCloudProviderLogoUrl =
  'https://res.cloudinary.com/opus-security/image/upload/d_cloud-provider-logos-svg:cloud-provider-default-logo.svg/cloud-provider-logos-svg/default.svg';

export const excludedApiAssetsFilters = ['tags'];

export const getAssetSearchParamsRequestDependencies = (
  searchParams: any,
  categories: Array<ExtendedFilterCategory>
): Array<string> => {
  const allCategories =
    advanceFilterDataHandler.extractAllCategories(categories);

  return Object.keys(searchParams)?.length
    ? Object.keys(searchParams).map((searchParamKey) => {
        const category = allCategories.find(
          (categoryItem: ExtendedFilterCategory) =>
            categoryItem.id === searchParamKey
        );
        if (category?.operator === FilterOperator.OVERLAPS) {
          const categoryState = searchParams[searchParamKey] as any;

          if (categoryState && Object.keys(categoryState).length) {
            return categoryState.value.join('');
          }
          return '';
        }

        if (category?.type === FilterType.KEY_VALUE) {
          const categoryState = searchParams[searchParamKey] as any;

          if (categoryState && Object.keys(categoryState).length) {
            return categoryState.value
              ?.map(
                (selection: any) =>
                  `${selection.key}${selection.values?.join('')}`
              )
              .join('');
          }
          return '';
        }

        if (Array.isArray(searchParams[searchParamKey])) {
          if (category?.type === FilterType.DATE_RANGE) {
            const searchParamList = searchParams[
              searchParamKey
            ] as any as Array<Date>;

            return (
              searchParamList[0]?.toString() + searchParamList[1]?.toString()
            );
          }
          const searchParamList = searchParams[
            searchParamKey
          ] as Array<RiskFilterItem>;

          return (
            searchParamList[searchParamList.length - 1].value +
            searchParamList.length
          );
        }

        const searchParamItem = searchParams[searchParamKey] as RiskFilterItem;

        return searchParamItem?.value;
      })
    : [];
};

export const AssetsViewFilterCategories: Array<ExtendedFilterCategory> = [];
export const assetsDefaultGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'name',
    headerName: 'Resource Name',
    width: 330,
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.type}
          name={params?.data?.name}
          iconUrl={params?.data?.typeLogoUrl}
        />
      );
    },
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    headerClass: 'column-disable-sort',
    width: 150,
    sortable: false,

    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'impactScore',
    headerName: 'Impact Score',
    sortable: true,

    cellRenderer: (params: any) => {
      return (
        <div>
          <ImpactScoreChip
            value={+Number(params.data?.impactScore).toFixed(2)}
          />
        </div>
      );
    },
    width: 140,
  },
  {
    field: 'findingSource',
    headerName: 'Sources',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          maxWidth={140}
          data={params?.data?.relatedApplicationIds.map((item: any) => ({
            ...item,
            label: item.id,
          }))}
          label={''}
        />
      );
    },
  },
  {
    field: 'severityBreakdown',
    headerName: 'Severity Breakdown',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 350,
    cellRenderer: (params: any) => {
      return (
        <SeverityBreakdown
          severityData={params?.data?.severityBreakdown}
          severityToDisplay={[
            FindingSeverity.HIGH,
            FindingSeverity.CRITICAL,
            FindingSeverity.MEDIUM,
          ]}
        />
      );
    },
  },
  {
    field: 'attributes',
    headerName: 'Attributes',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 150,
    cellRenderer: (params: any) => {
      return <ResourceAttributes attributes={params?.data?.riskAttributes} />;
    },
  },
  {
    field: 'cloudWorkspaceName',
    headerName: 'Workspace',
    initialFlex: 1,
    minWidth: 250,
    headerClass: 'column-disable-sort',
    sortable: false,
    cellRenderer: (params: any) => {
      return params?.data?.workspaceData?.id ? (
        <ItemWithIcon
          maxWidth={220}
          icon={
            <img
              src={
                params?.data?.workspaceData?.providerLogoUrl ||
                defaultCloudProviderLogoUrl
              }
              height={16}
            />
          }
          description={params?.data?.workspaceData?.externalId}
          title={params?.data?.workspaceData?.provider}
          missingTitleKey={''}
          displayIconOutline={false}
        />
      ) : (
        <></>
      );
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    sortable: true,
    width: 220,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={200}
          className="body-2"
        >
          {params?.data?.ownerUserId || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'firstSeenAt',
    headerName: 'First Seen',
    sortable: true,
    hide: true,
    width: 200,
    cellRenderer: (params: any) => {
      return moment(params.data?.firstSeenAt).format(dateTimeFormat);
    },
  },
  {
    field: 'lastSeenAt',
    headerName: 'Last Seen',
    sortable: true,
    hide: true,
    width: 200,
    cellRenderer: (params: any) => {
      return moment(params.data?.lastSeenAt).format(dateTimeFormat);
    },
  },
  {
    field: 'minSlaBreachDate',
    headerName: 'SLA',
    sortable: true,
    hide: true,
    width: 200,
    cellRenderer: (params: any) => {
      return <SlaChip slaDate={params.data?.minSlaBreachDate} isUtc />;
    },
  },
];

export const assetsDefaultHiddenGridColumns: Array<VisibilityControlColumn> = [
  {
    value: 'lastSeenAt',
    label: 'Last Seen',
  },
  {
    value: 'firstSeenAt',
    label: 'First seen',
  },
  {
    value: 'ownerUserId',
    label: 'Resource Owner',
  },
  {
    value: 'minSlaBreachDate',
    label: 'SLA',
  },
];

export const assetsCodeHiddenGridColumns: Array<VisibilityControlColumn> = [
  ...assetsDefaultHiddenGridColumns,
];

export const assetsDomainHiddenGridColumns: Array<VisibilityControlColumn> = [
  ...assetsDefaultHiddenGridColumns,
];
export const assetsArtifactGridColumns: Array<ColDef | ColGroupDef> = [
  ...assetsDefaultGridColumns,
  {
    field: 'os',
    headerName: 'OS',
    width: 150,
    headerClass: 'column-disable-sort',
    sortable: false,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.OS || '-'}
        </TextOverflow>
      );
    },
  },
];

export const assetsCloudGridColumns: Array<ColDef | ColGroupDef> = [
  ...assetsDefaultGridColumns,
  {
    field: 'ip',
    headerName: 'IP',
    width: 130,
    hide: true,
    headerClass: 'column-disable-sort',
    sortable: false,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.IPV4 || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'hostname',
    headerName: 'Hostname',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.Hostname || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'os',
    headerName: 'OS',
    width: 150,
    hide: true,
    headerClass: 'column-disable-sort',
    sortable: false,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.OS || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'fqdn',
    headerName: 'FQDN',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.FQDN || '-'}
        </TextOverflow>
      );
    },
  },
];

export const assetsCloudHiddenGridColumns: Array<VisibilityControlColumn> = [
  ...assetsDefaultHiddenGridColumns,

  {
    value: 'ip',
    label: 'IP',
  },
  {
    value: 'hostname',
    label: 'Hostname',
  },
  {
    value: 'os',
    label: 'OS',
  },
];

export const assetsArtifactHiddenGridColumns: Array<VisibilityControlColumn> = [
  ...assetsDefaultHiddenGridColumns,

  {
    value: 'origin',
    label: 'Origin',
  },
  {
    value: 'os',
    label: 'OS',
  },
];

export const assetsFilterItems: MenuItems = [
  {
    id: 'organization',
    name: 'Organization',
    nested: true,
    items: [],
    filterItems: [
      {
        id: 'scopeId',
        name: 'Scope',
        items: [],
        type: TypeFilter.SINGLE_SELECT,
        bodyParameters: {
          nodesType: 'scope',
        },
        optionGetterId: AdvanceFilterGetter.SCOPE,
        resetFields: ['groupId'],
      },
      {
        id: 'groupId',
        name: 'Group',
        items: [],
        type: TypeFilter.MULTI_SELECT,
        bodyParameters: {
          nodesType: 'logical',
        },
        optionGetterId: AdvanceFilterGetter.GROUP,
        getAdditionalBodyParameters: (selection: any) => ({
          startNodeId: selection.id,
        }),
      },
    ],
  },
  {
    id: 'findingType',
    name: 'Domain',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'resourceType',
    name: 'Resource Type',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'operatingSystem',
    name: 'Operating System',
    items: [],
    type: TypeFilter.MULTI_SELECT,
  },
  {
    id: 'riskScore',
    name: 'Risk Score',
    type: TypeFilter.MULTI_SELECT,
    items: riskScoreItems,
    loaded: true,
  },
  {
    id: 'category',
    name: 'Risk Category',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'businessUnitId',
    name: 'Service',
    type: TypeFilter.MULTI_SELECT,
    items: [riskGroupNoneOption],
    keepExistingOptions: true,
  },
  {
    id: `workspace`,
    name: 'Workspace',
    type: TypeFilter.MULTI_SELECT,
    items: [riskGroupNoneOption],
    keepExistingOptions: true,
  },
  {
    id: 'findingSource',
    name: 'Event Source',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'severity',
    name: 'Severity',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'status',
    name: 'Status',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'externalCreatedAt',
    name: 'First Seen',
    type: TypeFilter.DATE_PICKER_RANGE,
    items: [],
  },
  {
    id: 'itemRelationMetadata',
    name: 'Has Ticket',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'isExternalFacing',
    name: 'External Facing Resource',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'hasSensitiveData',
    name: 'Resource with Sensitive Data',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'reopened',
    name: 'Reopened',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: true,
        name: 'Yes',
      },
      {
        id: false,
        name: 'No',
      },
    ],
    loaded: true,
  },
  {
    id: 'ownerUserId',
    name: 'Resource Owner',
    type: TypeFilter.MULTI_SELECT,
    items: [riskGroupNoneOption],
    keepExistingOptions: true,
  },
  {
    id: 'assigneeUserId',
    type: TypeFilter.MULTI_SELECT,
    name: 'Assignee',
    items: [unassignedFilterOption],
    keepExistingOptions: true,
  },
  {
    id: 'slaStatus',
    type: TypeFilter.SINGLE_SELECT,
    name: 'SLA Status',
    items: [],
  },
  {
    id: 'state',
    type: TypeFilter.SINGLE_SELECT,
    name: 'State',
    loaded: true,
    items: [
      {
        id: FindingState.ACTIVE,
        name: FindingState.ACTIVE,
      },
      {
        id: FindingState.IN_ACTIVE,
        name: FindingState.IN_ACTIVE,
      },
    ],
  },
  {
    id: 'duplicationState',
    name: 'Duplication State',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: DuplicationState.PRIMARY,
        name: 'Duplicated',
      },
      {
        id: DuplicationState.SINGLE,
        name: 'Single',
      },
    ],
    loaded: true,
  },
];

export const assetsViewFilterCategories: Array<ExtendedFilterCategory> = [
  {
    id: 'resource',
    label: 'Resource',
    categories: [
      {
        id: 'externalResourceIdLabel',
        label: 'Resource ID',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'assets/filters/search/externalResourceIdLabel',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'name',
        label: 'Resource Name',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_DYNAMIC,
          source: {
            url: 'assets/filters/search/name',
            method: 'POST',
            searchProperty: 'searchText',
            body: {
              take: 100,
              skip: 0,
            },
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'type',
        label: 'Type',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/type',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
          sortMethod: (options) => options,
        },
      },
      {
        id: 'ownerUserId',
        label: 'Resource Owner',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/ownerUserId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['Unassigned']),
        },
      },
      {
        id: 'firstSeenAt',
        label: 'First Seen',
        type: FilterType.DATE_RANGE,
      },
      {
        id: 'lastSeenAt',
        label: 'Last Seen',
        type: FilterType.DATE_RANGE,
      },
      {
        id: 'operatingSystem',
        label: 'OS',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/operatingSystem',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
        },
      },
      {
        id: 'fqdn',
        label: 'FQDN',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/fqdn',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
        },
      },
      {
        id: 'ipv4',
        label: 'IPV4',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/ipv4',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
        },
      },
      {
        id: 'hostname',
        label: 'Hostname',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/hostname',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
        },
      },
      {
        id: 'riskAttributes',
        label: 'Risk Attributes',
        categories: [
          {
            id: 'externalFacing',
            label: 'External Facing',
            type: FilterType.SINGLE_SELECT,
            state: {
              sourceType: OptionSourceType.STATIC,
              source: {
                options: booleanRiskFilterOptions,
              },
            },
          },
          {
            id: 'crownJewels',
            label: 'Crown Jewels',
            type: FilterType.SINGLE_SELECT,
            state: {
              sourceType: OptionSourceType.STATIC,
              source: {
                options: booleanRiskFilterOptions,
              },
            },
          },
          {
            id: 'sensitiveData',
            label: 'Sensitive Data',
            type: FilterType.SINGLE_SELECT,
            state: {
              sourceType: OptionSourceType.STATIC,
              source: {
                options: booleanRiskFilterOptions,
              },
            },
          },
        ],
      },
      {
        id: 'tags',
        label: 'Tags',
        type: FilterType.KEY_VALUE,
        state: {
          selections: [
            {
              key: {
                value: '',
                label: '',
              },
              values: [],
            },
          ],
          keySource: {
            type: OptionSourceType.API_STATIC,
            payload: {
              method: 'GET',
              url: 'tags/filters/search?fieldName=key',
              optionsTransformer:
                dataFilterHandler.transformApiFilterOptionsPayload,
            },
          },
          valueSource: {
            type: OptionSourceType.API_STATIC,
            payload: {
              method: 'GET',
              url: 'tags/:key/values/filters/search',
              optionsTransformer:
                dataFilterHandler.transformApiFilterOptionsPayload,
            },
          },
        },
        entitlement: SystemFeatureFlag.TAGS_FILTER,
      },
    ],
  },
  {
    id: 'risk',
    label: 'Risk',
    categories: [
      {
        id: 'findingSource',
        label: 'Event Source',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/findingSource',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
        },
      },
      {
        id: 'severity',
        label: 'Severity',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/severity',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options) => options,
        },
      },
    ],
  },
  {
    id: 'organization',
    label: 'Organization',
    categories: [
      {
        id: 'scopeId',
        label: 'Scope',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'scope',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'groupId',
        label: 'Group',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'organization-structures/getUserAvailableNodesOfType',
            body: {
              nodesType: 'logical',
            },
            optionsTransformer: (options: Array<OrganizationNode>) => {
              return options.map((option: OrganizationNode) => ({
                value: option.id,
                label: option.name,
              }));
            },
          },
        },
      },
      {
        id: 'businessUnitId',
        label: 'Service',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/businessUnitId',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: `workspace`,
        label: 'Workspace',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/workspace',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
            body: {
              itemsCount: true,
            },
          },
          extraOptions: [
            {
              value: null,
              label: StaticFilterOptions.NONE,
            },
          ],
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
      {
        id: 'environmentType',
        label: 'Environment',
        type: FilterType.MULTI_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'assets/filters/search/environmentType',
            optionsTransformer:
              dataFilterHandler.transformApiFilterOptionsPayload,
          },
          sortMethod: (options: Array<DataFilterOption>) =>
            dataFilterHandler.sortFilterOptions(options, ['None']),
        },
      },
    ],
  },
  {
    id: 'operational',
    label: 'Operational',
    categories: [
      labelFilterCategory,
      {
        id: 'slaStatus',
        label: 'SLA Status',
        type: FilterType.SINGLE_SELECT,
        state: {
          sourceType: OptionSourceType.API_STATIC,
          source: {
            url: 'config/filters/search/slaStatus',
            optionsTransformer: (optionPayload: any) => {
              return optionPayload.data?.map((option: any) => ({
                value: option.value,
                label: capitalize(option.value.replace('-', ' ')),
              }));
            },
          },
        },
      },
    ],
  },
];

export const assetsonPremGridColumns: Array<ColDef | ColGroupDef> = [
  ...assetsDefaultGridColumns,
  {
    field: 'ip',
    headerName: 'IP',
    width: 130,
    headerClass: 'column-disable-sort',
    sortable: false,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.IPV4 || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'hostname',
    headerName: 'Hostname',
    width: 150,
    headerClass: 'column-disable-sort',
    sortable: false,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.Hostname || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'os',
    headerName: 'OS',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.OS || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'fqdn',
    headerName: 'FQDN',
    headerClass: 'column-disable-sort',
    sortable: false,
    width: 150,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="body-2"
        >
          {params?.data?.WorkStationDetails?.FQDN || '-'}
        </TextOverflow>
      );
    },
  },
];
export const assetsOnPremHiddenGridColumns: Array<VisibilityControlColumn> = [
  ...assetsDefaultHiddenGridColumns,

  {
    value: 'ip',
    label: 'IP',
  },
  {
    value: 'hostname',
    label: 'Hostname',
  },
  {
    value: 'os',
    label: 'OS',
  },
  {
    value: 'fqdn',
    label: 'FQDN',
  },
];
