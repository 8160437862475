import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import { allOption } from 'Common/components/Autocomplete/Autocomplete';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import DatePicker from 'Common/components/DatePicker';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { t } from 'i18next';
import { values } from 'lodash';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useCreateReportRuleMutation,
  useFetchReportRuleQuery,
  useGenerateReportRulePreviewUrlMutation,
  useUpdateReportRuleMutation,
} from 'Settings/store/api';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { ReportRuleDataHandler } from 'shared/handlers/report-rule-data.handler';
import {
  NotificationSettingsOptionList,
  useFetchReportRulesOptionItems,
} from 'shared/hooks/useFetchReportRuleOptionItems';
import { useGetOrganizationOptions } from 'shared/hooks/useGetOrganizationData';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  comparativeReportRuleSchema,
  reportRuleCompareByOptions,
  ReportRuleComparisonObject,
  reportRuleConfigurationTypeOptions,
  reportRuleFrequencyOptions,
  ReportRuleMode,
  ReportRuleModel,
  reportRuleModeOptions,
  reportRuleRecipientOptions,
  ReportRuleRoleRecipient,
} from 'shared/models/data/report-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import * as yup from 'yup';

interface ComparativeReportRuleDrawerProps extends BaseComponentProps {
  onCancel?: () => void;
  options: {
    scopeOptions?: Array<AutocompleteOption>;
    groupOptions?: Array<AutocompleteOption>;
    emailOptions?: Array<AutocompleteOption>;
  };
}

const reportRuleDataHandler = new ReportRuleDataHandler();

export const ComparativeReportRuleDrawerContent: FunctionComponent<
  ComparativeReportRuleDrawerProps
> = ({ onCancel, options }) => {
  const { scopeOptions, emailOptions } = options;

  const [loading, setLoading] = useState(true);

  const [areOptionsReady, setAreOptionsReady] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  const [searchParams] = useQueryParams();

  const isEditMode = Boolean(searchParams?.ruleId);

  const { data: reportRule, isFetching: reportRuleLoading } =
    useFetchReportRuleQuery(searchParams?.ruleId as string, {
      skip: !isEditMode,
      refetchOnMountOrArgChange: true,
    });

  const {
    findingTypeOptionList,
    emailOptionList,
    scopesOptionList,
    groupsOptionList,
    findingTypeOptionListLoading,
    scopesOptionsLoading,
    groupsOptionsLoading,
    getGroupsOptionList,
  } = useFetchReportRulesOptionItems(reportRule);

  const [
    updateReportRule,
    { isLoading: updateReportRuleLoading, isSuccess: updateReportRuleSuccess },
  ] = useUpdateReportRuleMutation();

  const [
    createReportRule,
    { isLoading: createReportRuleLoading, isSuccess: createReportRuleSuccess },
  ] = useCreateReportRuleMutation();

  const [
    generateReportRulePreviewUrl,
    {
      isLoading: generatingReportRulePreviewUrl,
      data: reportRulePreviewUrlPayload,
    },
  ] = useGenerateReportRulePreviewUrlMutation();

  const [
    mutatedComparativeReportRuleSchema,
    setMutatedComparativeReportRuleSchema,
  ] = useState(comparativeReportRuleSchema);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    reset: resetForm,
    watch,
    handleSubmit,
    getValues,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape(mutatedComparativeReportRuleSchema)
    ),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const formValues = getValues();

  const reportMode = watch('mode');
  const type = watch('type');

  const scopes = watch('scopes');
  const groups = watch('groups');

  const recipients = watch('recipients');

  const onDrawerClose = () => {
    resetForm();
    onCancel && onCancel();
  };

  useEffect(() => {
    if (emailOptions && scopeOptions && groupsOptionList) {
      setAreOptionsReady(true);
    }
  }, [emailOptions, scopeOptions, groupsOptionList]);

  useEffect(() => {
    if (reportRulePreviewUrlPayload) {
      window.open(reportRulePreviewUrlPayload, '_blank');
    }
  }, [reportRulePreviewUrlPayload]);

  useEffect(() => {
    if (isEditMode && reportRule && areOptionsReady) {
      const defaultValues =
        reportRuleDataHandler.transformReportRuleToComparativeReportRuleFormData(
          reportRule,
          emailOptions,
          {
            scopeOptions: scopeOptions,
            groupOptions: groupsOptionList,
          }
        );

      resetForm(defaultValues);
      setLoading(false);
    } else if (!isEditMode) {
      setLoading(false);
    }
  }, [isEditMode, reportRule, resetForm, areOptionsReady]);

  useEffect(() => {
    if (createReportRuleSuccess || updateReportRuleSuccess) {
      onDrawerClose();
    }
  }, [createReportRuleSuccess, updateReportRuleSuccess]);

  const setMultiSelectFormValue = (
    model: string,
    selection: AutocompleteOption | Array<AutocompleteOption> | Array<string>
  ) => {
    setFormValue(model as any, selection);
  };

  const filteredReportRuleRecipientOptions = useMemo<
    Array<AutocompleteOption>
  >(() => {
    switch (type?.value) {
      case ReportRuleComparisonObject.COMPARISON_PER_SCOPE:
        return [
          reportRuleRecipientOptions.find(
            (reportRuleRecipientOption) =>
              reportRuleRecipientOption.value ===
              ReportRuleRoleRecipient.SCOPE_OWNERS
          ),
        ] as Array<AutocompleteOption>;
      case ReportRuleComparisonObject.COMPARISON_PER_GROUP:
      case ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP:
        return [
          ...reportRuleRecipientOptions.filter(
            (reportRuleRecipientOption) =>
              reportRuleRecipientOption.value !==
              ReportRuleRoleRecipient.SERVICE_OWNERS
          ),
        ];
      case ReportRuleComparisonObject.COMPARISON_PER_SERVICE:
        return reportRuleRecipientOptions;
      default:
        return [];
    }
  }, [type?.value]);

  const recipientsOptions = useMemo<Array<AutocompleteOption>>(() => {
    return reportMode?.value === ReportRuleMode.STATIC_REPORT
      ? emailOptionList
      : filteredReportRuleRecipientOptions;
  }, [reportMode?.value, emailOptionList, filteredReportRuleRecipientOptions]);

  const isRecipientsSingleSelect = useMemo<boolean>(() => {
    return Boolean(reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT);
  }, [reportMode?.value]);

  const isScopeInputDisabled = useMemo(() => {
    switch (type?.value) {
      case ReportRuleComparisonObject.COMPARISON_PER_SCOPE:
        return true;
      case ReportRuleComparisonObject.COMPARISON_PER_GROUP:
      case ReportRuleComparisonObject.COMPARISON_PER_SERVICE:
      case ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP:
        return false;
    }

    return true;
  }, [type]);

  const isGroupInputDisabled = useMemo(() => {
    switch (type?.value) {
      case ReportRuleComparisonObject.COMPARISON_PER_SCOPE:
      case ReportRuleComparisonObject.COMPARISON_PER_GROUP:
        return true;
      case ReportRuleComparisonObject.COMPARISON_PER_SERVICE:
      case ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP:
        return false;
    }

    return true;
  }, [type]);

  useEffect(() => {
    if (isGroupInputDisabled) {
      setMutatedComparativeReportRuleSchema((prevSchema: any) => ({
        ...prevSchema,
        groups: yup.array(),
      }));
    } else {
      setMutatedComparativeReportRuleSchema((prevSchema: any) => ({
        ...prevSchema,
        groups: yup.array().required().min(1),
      }));
    }
  }, [isGroupInputDisabled]);

  useEffect(() => {
    if (isScopeInputDisabled) {
      setMutatedComparativeReportRuleSchema((prevSchema: any) => ({
        ...prevSchema,
        scopes: yup.object(),
      }));
    } else {
      setMutatedComparativeReportRuleSchema((prevSchema: any) => ({
        ...prevSchema,
        scopes: yup.object().required(),
      }));
    }
  }, [isScopeInputDisabled]);

  const onPreviewReportRule = () => {
    handleSubmit(
      (values: any) => {
        const reportRule: ReportRuleModel =
          reportRuleDataHandler.transformComparativeReportRuleFormDataToPostData(
            {
              ...values,
              groups:
                values.groups?.length ===
                groupsOptionList?.filter(
                  (groupOption) => groupOption.value !== allOption.value
                )?.length
                  ? [allOption]
                  : values.groups,
            }
          );

        generateReportRulePreviewUrl(reportRule);
      },
      (errors) => {
        console.log('Errors are', errors);
      }
    )();
  };

  const onSaveReportRule = () => {
    handleSubmit(
      (values: any) => {
        const postData =
          reportRuleDataHandler.transformComparativeReportRuleFormDataToPostData(
            {
              ...values,
              groups:
                values.groups?.length ===
                groupsOptionList?.filter(
                  (groupOption) => groupOption.value !== allOption.value
                )?.length
                  ? [allOption]
                  : values.groups,
            }
          );

        if (isEditMode) {
          updateReportRule({
            id: reportRule.id,
            ...postData,
          });
        } else {
          createReportRule(postData);
        }
      },
      (errors) => {
        console.log('Errors are', errors);
      }
    )();
  };

  const renderRecipientsAutocomplete = () => {
    if (isRecipientsSingleSelect) {
      return (
        <Autocomplete
          optionList={recipientsOptions}
          model="recipients"
          onChangeCallBack={(
            model: string,
            selection: Array<AutocompleteOption> | AutocompleteOption
          ) => {
            const selections = Array.isArray(selection)
              ? selection
              : [selection];

            setMultiSelectFormValue(model, selections);
          }}
          classes={{
            root: 'multi-select-field-1 ',
            paper: 'multi-select-field-paper-1',
            inputRoot: errors.recipients ? 'input-validation-error-1' : '',
          }}
          enableCheckbox
          areOptionsLoaded
          single
          disabled={!Boolean(reportMode)}
          values={
            formValues?.recipients?.length
              ? formValues?.recipients[0]
              : undefined
          }
          initialSelectedValues={
            formValues?.recipients?.length
              ? formValues?.recipients[0]
              : undefined
          }
        />
      );
    }

    return (
      <Autocomplete
        optionList={recipientsOptions}
        model="recipients"
        onChangeCallBack={(
          model: string,
          selection: Array<AutocompleteOption> | AutocompleteOption
        ) => {
          const selections = Array.isArray(selection) ? selection : [selection];

          setMultiSelectFormValue(model, selections);
        }}
        classes={{
          root: 'multi-select-field-1 ',
          paper: 'multi-select-field-paper-1',
          inputRoot: errors.recipients ? 'input-validation-error-1' : '',
        }}
        enableCheckbox
        areOptionsLoaded
        freeSolo
        disabled={!Boolean(reportMode)}
        values={formValues.recipients}
        initialSelectedValues={formValues.recipients}
      />
    );
  };

  const getReportCountForReportRuleConfiguration = () => {
    if (reportMode?.value === ReportRuleMode.STATIC_REPORT) {
      switch (type?.value) {
        case ReportRuleComparisonObject.COMPARISON_PER_GROUP:
          return 1;
        case ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP:
        case ReportRuleComparisonObject.COMPARISON_PER_SERVICE:
          return groups?.length || 0;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const getInfoMessageForReportRuleConfiguration = () => {
    if (
      reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SERVICE &&
      recipients &&
      recipients[0]?.value === ReportRuleRoleRecipient.SERVICE_OWNERS
    ) {
      return translation('reportRule.comparative.roleBasedComparisonService');
    }

    if (
      reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SERVICE &&
      recipients &&
      recipients[0]?.value === ReportRuleRoleRecipient.GROUP_OWNERS
    ) {
      return translation(
        'reportRule.comparative.roleBasedComparisonGroupOwners'
      );
    }

    if (
      reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SERVICE &&
      recipients &&
      recipients[0]?.value === ReportRuleRoleRecipient.SCOPE_OWNERS
    ) {
      return translation(
        'reportRule.comparative.roleBasedComparisonScopeOwners'
      );
    }

    if (
      reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP &&
      recipients &&
      recipients[0]?.value === ReportRuleRoleRecipient.GROUP_OWNERS
    ) {
      return translation(
        'reportRule.comparative.roleBasedComparisonSubGroupGroupOwners'
      );
    }

    if (
      reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP &&
      recipients &&
      recipients[0]?.value === ReportRuleRoleRecipient.SCOPE_OWNERS
    ) {
      return translation(
        'reportRule.comparative.roleBasedComparisonSubGroupScopeOwners'
      );
    }

    if (
      reportMode?.value === ReportRuleMode.ROLE_BASED_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_GROUP &&
      recipients &&
      recipients[0]?.value === ReportRuleRoleRecipient.SCOPE_OWNERS
    ) {
      return translation(
        'reportRule.comparative.roleBasedComparisonGroupScopeOwners'
      );
    }

    if (
      reportMode?.value === ReportRuleMode.STATIC_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SERVICE
    ) {
      return translation('reportRule.comparative.staticComparisonService', {
        count: getReportCountForReportRuleConfiguration(),
      });
    }

    if (
      reportMode?.value === ReportRuleMode.STATIC_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SUB_GROUP
    ) {
      return translation('reportRule.comparative.staticComparisonSubGroup', {
        count: getReportCountForReportRuleConfiguration(),
      });
    }

    if (
      reportMode?.value === ReportRuleMode.STATIC_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_GROUP
    ) {
      return translation('reportRule.comparative.staticComparisonGroup', {
        count: getReportCountForReportRuleConfiguration(),
      });
    }

    if (
      reportMode?.value === ReportRuleMode.STATIC_REPORT &&
      type?.value === ReportRuleComparisonObject.COMPARISON_PER_SCOPE
    ) {
      return translation('reportRule.comparative.staticComparisonScope');
    }

    return '';
  };

  const renderInfoMessage = () => {
    const infoMessage = getInfoMessageForReportRuleConfiguration();

    if (infoMessage.length)
      return (
        <div className="report-rule-info-message">
          <div className="report-rule-info-message-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
          </div>
          <div className="report-rule-info-message-text">{infoMessage}</div>
        </div>
      );

    return <></>;
  };

  return reportRuleLoading || loading ? (
    <div className="notification-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="report-rule-drawer-content">
      <div className="report-rule-drawer-content-header">
        <div className="report-rule-drawer-content-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_ICON} />
        </div>
        <div className="report-rule-drawer-content-header-title-area">
          <div className="report-rule-drawer-content-header-title">
            {translation('reportRule.comparative.headerTitle')}
          </div>
          <div className="report-rule-drawer-content-header-sub-title">
            {translation('reportRule.comparative.headerSubtitle')}
          </div>
        </div>
      </div>
      <div className="report-rule-drawer-content-body">
        <div className="report-rule-drawer-content-body-section">
          <InputLabelWrapper
            label={translation('reportRule.comparative.nameLabel')}
          >
            <input
              type="text"
              className={`text-field-1 ${
                errors.name ? 'input-validation-error-1' : ''
              }`}
              {...register('name')}
            ></input>
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.comparative.descriptionLabel')}
            isOptional
          >
            <input
              type="text"
              className={`text-field-1`}
              {...register('description')}
            ></input>
          </InputLabelWrapper>
        </div>

        <ContentSection
          title={translation('reportRule.comparative.reportConfiguration')}
        >
          <InputLabelWrapper
            label={translation('reportRule.comparative.typeLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.comparative.typeTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleConfigurationTypeOptions}
              single
              model="type"
              onChangeCallBack={(
                model: string,
                selection: Array<AutocompleteOption> | AutocompleteOption
              ) => {
                setFormValue('scopes', undefined);
                setFormValue('groups', []);
                setFormValue('recipients', []);

                setMultiSelectFormValue(model, selection);
              }}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.type ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.type}
              initialSelectedValues={formValues.type}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.comparative.compareByLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.comparative.compareByTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleCompareByOptions}
              model="compareBy"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.compareBy ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.compareBy}
              initialSelectedValues={formValues.compareBy}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.comparative.domainLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.comparative.domainTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
            isOptional
          >
            <Autocomplete
              optionList={findingTypeOptionList}
              loading={findingTypeOptionListLoading}
              model="domains"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.domains ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.domains}
              initialSelectedValues={formValues.domains}
            />
          </InputLabelWrapper>
        </ContentSection>

        <ContentSection
          title={translation('reportRule.comparative.reportScope')}
        >
          <InputLabelWrapper
            label={translation('reportRule.comparative.scopeLabel')}
          >
            <Autocomplete
              optionList={scopesOptionList}
              loading={scopesOptionsLoading}
              model="scopes"
              onChangeCallBack={(
                model: string,
                selection: Array<AutocompleteOption> | AutocompleteOption
              ) => {
                const singleSelection = selection as AutocompleteOption;

                setFormValue('groups', []);

                getGroupsOptionList(
                  singleSelection?.value === 'All'
                    ? undefined
                    : singleSelection?.value
                );

                setMultiSelectFormValue(model, selection);
              }}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.scopes ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              single
              areOptionsLoaded
              disabled={isScopeInputDisabled}
              values={scopes}
              initialSelectedValues={formValues.scopes}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.comparative.groupLabel')}
          >
            <Autocomplete
              optionList={groupsOptionList?.filter(
                (groupOption) => groupOption.value !== 'All'
              )}
              loading={groupsOptionsLoading}
              model="groups"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.groups ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              disabled={isGroupInputDisabled}
              values={groups}
              initialSelectedValues={formValues.groups}
              enableAllSelection
              enableVirtualization
            />
          </InputLabelWrapper>
        </ContentSection>

        <ContentSection
          title={translation('reportRule.comparative.recipients')}
        >
          <InputLabelWrapper
            label={translation('reportRule.comparative.reportModeLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.comparative.reportModeTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleModeOptions}
              single
              model="mode"
              onChangeCallBack={(
                model: string,
                selection: Array<AutocompleteOption> | AutocompleteOption
              ) => {
                setFormValue('recipients', []);
                setMultiSelectFormValue(model, selection);
              }}
              classes={{
                root: 'multi-select-field-1',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.mode ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.mode}
              initialSelectedValues={formValues.mode}
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.comparative.recipientsLabel')}
          >
            {renderRecipientsAutocomplete()}
          </InputLabelWrapper>

          {renderInfoMessage()}
        </ContentSection>

        <ContentSection
          title={translation('reportRule.comparative.schedulingFrequency')}
        >
          <InputLabelWrapper label="">
            <DatePicker
              label={translation('reportRule.comparative.startingOnLabel')}
              model="startingOn"
              minDate={new Date()}
              onChangeCallBack={(model: string, values: Array<string>) => {
                setMultiSelectFormValue(model, {
                  value: values[0],
                });
              }}
              range={false}
              classes={{
                root: 'report-rule-date-picker',
              }}
              placeholder=""
              initialSelectedValues={
                formValues.startingOn?.value
                  ? [formValues.startingOn?.value]
                  : undefined
              }
              TitleAdnornment={
                <>
                  <CommonTooltip
                    title={
                      <span className="campaign-live-tooltip-text">
                        {translation(
                          'reportRule.comparative.startingOnTooltip'
                        )}
                      </span>
                    }
                    placement="top"
                  >
                    <span>
                      <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                    </span>
                  </CommonTooltip>

                  <div className="input-label-optional">{`(Optional)`}</div>
                </>
              }
            />
          </InputLabelWrapper>
          <InputLabelWrapper
            label={translation('reportRule.comparative.frequencyLabel')}
            components={{
              EndIcon: (
                <CommonTooltip
                  title={
                    <span className="campaign-live-tooltip-text">
                      {translation('reportRule.comparative.frequencyTooltip')}
                    </span>
                  }
                  placement="top"
                >
                  <span>
                    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
                  </span>
                </CommonTooltip>
              ),
            }}
          >
            <Autocomplete
              optionList={reportRuleFrequencyOptions}
              single
              model="frequency"
              onChangeCallBack={setMultiSelectFormValue}
              classes={{
                root: 'multi-select-field-1 ',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.frequency ? 'input-validation-error-1' : '',
              }}
              enableCheckbox
              areOptionsLoaded
              values={formValues.frequency}
              initialSelectedValues={formValues.frequency}
            />
          </InputLabelWrapper>
        </ContentSection>
      </div>

      <div className="report-rule-drawer-content-footer">
        <div className="report-rule-drawer-content-preview-button">
          <Button
            className="button-secondary"
            onClick={() => {
              onPreviewReportRule();
            }}
            disabled={generatingReportRulePreviewUrl}
          >
            {generatingReportRulePreviewUrl ? (
              <CircularProgress size={14} />
            ) : (
              <></>
            )}
            <span>{translation('reportRule.comparative.previewButton')}</span>
          </Button>
        </div>
        <div className="report-rule-drawer-content-submit-buttons">
          <Button
            className="button-secondary"
            onClick={() => {
              onDrawerClose();
            }}
          >
            {translation('reportRule.comparative.cancelButton')}
          </Button>
          <Button
            className="button-primary"
            onClick={() => {
              onSaveReportRule();
            }}
          >
            {translation(
              `reportRule.comparative.${
                createReportRuleLoading || updateReportRuleLoading
                  ? 'savingButton'
                  : 'saveButton'
              }`
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
