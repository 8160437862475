export enum BusinesUnitCriticality {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Critical = 'Critical',
}

export enum BusinessUnitsCriticalityEnum {
  LBI = 3,
  MBI = 6,
  HBI = 9,
}
