import OperationalWidgetCard from 'Dashboard/components/OperationalWidgetCard';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWidgetHandler } from 'shared/handlers/dashboard-widget.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import DashboardReportsRiskMetricCard from './components/DashboardReportsRiskMetricCard';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import {
  OperationalMetricType,
  OperationalWidgetAnalyticsType,
} from 'shared/models/data/operational-widget-data.model';

interface DashboardReportsRiskTrendMetricsWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {
  timeFrame: TimelineFilter;
  metricTypes: Array<OperationalMetricType>;
}

const dashboardWidgetHandler = new DashboardWidgetHandler();

export const DashboardReportsRiskTrendMetricsWidget: FunctionComponent<
  DashboardReportsRiskTrendMetricsWidgetProps
> = ({ businessUnitIds, findingTypes, timeFrame, metricTypes }) => {
  const { t: translation } = useTranslation();

  const renderWidgetCards = () => {
    const widgets = dashboardWidgetHandler.getOperationalWidgets(translation);

    return widgets.map((widget) => {
      if (metricTypes.includes(widget.type)) {
        return (
          <DashboardReportsRiskMetricCard
            {...widget}
            businessUnitIds={businessUnitIds}
            timeFrame={timeFrame}
            findingTypes={findingTypes}
          />
        );
      }
      return <></>;
    });
  };

  return (
    <div className="dashboard-reports-risk-metrics-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.RADAR_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Trends Metrics ({timeFrame || TimelineFilter['60_DAYS']})
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-metrics-widget-body">
        {renderWidgetCards()}
      </div>
    </div>
  );
};
